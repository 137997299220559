import React, { useState, useEffect } from "react";
import { getUserListOutput, vUserState } from "../utils/GeneBoxAPI";

const UserDesWord = (props: getUserListOutput) => {
    const { state, center_comment, doctor_comment } = props;
    const [sShowDes, setShowDes] = useState("");
    const [bShowDes, setbShowDes] = useState(true);

    useEffect(() => {
        if (state === vUserState.disagree_doctor) {
            setShowDes(doctor_comment);
            setbShowDes(false);
        } else {
            setShowDes(center_comment);
            setbShowDes(true);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const changeShowDws = () => {
        const sNewDes = !bShowDes ? center_comment : doctor_comment;
        setShowDes(sNewDes);
        setbShowDes(!bShowDes);
    };
    return (
        <>
            <div className="disagree_btn">
                {state === vUserState.disagree_doctor ? (
                    <button className="button_style button_deep_blue">
                        醫師建議
                    </button>
                ) : state === vUserState.disagree_center ? (
                    <button className="button_style button_deep_blue">
                        中心建議
                    </button>
                ) : (
                    <>
                        <button
                            className={`button_style ${
                                bShowDes ? "button_deep_blue" : "button_blue"
                            }`}
                            onClick={changeShowDws}
                        >
                            中心建議
                        </button>
                        <button
                            className={`button_style ${
                                !bShowDes ? "button_deep_blue" : "button_blue"
                            }`}
                            onClick={changeShowDws}
                        >
                            醫師建議
                        </button>
                    </>
                )}
            </div>
            <div className="modal_content_text small_dialog">{sShowDes}</div>
        </>
    );
};

export default UserDesWord;
