import React, { useState, useContext, useEffect } from "react";
import { useHistory, useLocation, useParams } from "react-router-dom";
import ReportTab from "./ReportTab";
import ReportList from "./ReportList";
import ReportInfo from "./ReportInfo";
import ReportContext from "../Context/ReportContext";
import LoginInfoContext from "../Context/LoginInfoContext";
import {
    risk_list,
    getUserReportListAPI,
    vSystemType,
} from "../utils/GeneBoxAPI";

interface url {
    uid: string;
    type: string;
}

const Report = () => {
    const history = useHistory();
    const { uid }: url = useParams();
    const user_id = uid.toUpperCase();
    const location = useLocation();
    const pageFrom = location.pathname.split("/")[2];

    //context
    const { Provider } = ReportContext;
    const vUserListContext = useContext(ReportContext);
    const { vUserListContextData } = vUserListContext;
    const {
        sCurrentToken,
        sSystem,
        setCurrentToken,
        setbLoading,
        errorProcess,
    } = useContext(LoginInfoContext);

    //state
    const [vUserListData, setUserListData] = useState(vUserListContextData);
    const [vUserRiskItem, setvUserRiskItem] = useState(
        vUserListContextData.item_list
    );
    const [nowSortType, setNowSortType] = useState<string>("");
    const [iSortNow, setSortNow] = useState<number>(1);
    const [tabPage, setTabPage] = useState("high_risk");
    const [bQuery, setbQuery] = useState(false);

    // get userList data
    const getUserListData = (user_id: string) => {
        if (sCurrentToken !== "") {
            setbLoading(true);
            setbQuery(true);
            getUserReportListAPI(user_id, sCurrentToken)
                .then((res) => {
                    if (res.respCode === 200) {
                        setCurrentToken(res.token);
                        setUserListData(res);
                        setbLoading(false);
                        setbQuery(false);
                    }
                })
                .catch((error) => {
                    setbLoading(false);
                    setbQuery(false);
                    // setuid("");
                    console.error(error);
                    let error_status: number = 0;

                    if (error.response !== undefined) {
                        error_status = error.response.status;
                        if (error_status !== 401) {
                            errorProcess(error_status, error);
                        }
                    } else {
                        error_status = 0;
                    }
                    let url = "";
                    if (pageFrom === "report_list") {
                        //特約醫生
                        let supplier = uid.split("").slice(0, 2).join("");
                        url = `/${pageFrom}/${supplier}/${
                            vSystemType[sSystem.current]
                        }`;
                    } else {
                        url = `/${pageFrom}/${vSystemType[sSystem.current]}`;
                    }
                    history.push(url);
                });
        }
    };
    useEffect(() => {
        getUserListData(user_id);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    //filter user data
    useEffect(() => {
        //報告顯示 relation = 1 (高風險、中風險、一般風險、低風險) relation = 2 體質分析
        let item_type = tabPage !== "physique" ? 1 : 2;
        let risk_class =
            tabPage === "high_risk"
                ? 4
                : tabPage === "medium_risk"
                ? 3
                : tabPage === "general_risk"
                ? 2
                : tabPage === "low_risk"
                ? 1
                : 0;
        if (vUserListData.item_list !== undefined) {
            setvUserRiskItem(
                vUserListData.item_list.filter((item: risk_list) =>
                    item.relation !== 2
                        ? item.relation === item_type &&
                          item.risk_class === risk_class
                        : item.relation === item_type
                )
            );
        }
    }, [vUserListData, tabPage]);

    const vTabLi = [
        {
            text: "高風險項目",
            tabPageName: "high_risk",
            maincolor: "coralred",
        },
        {
            text: "中風險項目",
            tabPageName: "medium_risk",
            maincolor: "orange",
        },
        {
            text: "一般風險項目",
            tabPageName: "general_risk",
            maincolor: "green",
        },
        {
            text: "低風險項目",
            tabPageName: "low_risk",
            maincolor: "skyblue_1",
        },
        {
            text: "體質分析",
            tabPageName: "physique",
            maincolor: "grey",
        },
    ];

    const changeTapPage = (pageName: string) => {
        setTabPage(pageName);
        setNowSortType("");
    };

    const showComponent = () => {
        for (const vTab of vTabLi) {
            const { tabPageName, maincolor } = vTab;
            if (tabPage === tabPageName) {
                return (
                    <ReportList
                        tabPagename={tabPageName}
                        maincolor={maincolor}
                    />
                );
            }
        }
    };

    // sort data
    const sortReportListData = (sortType: string): void => {
        let iSort = iSortNow;
        if (nowSortType !== sortType) {
            iSort = 1;
            setNowSortType(sortType);
        } else {
            iSort = -1 * iSort;
        }
        setvUserRiskItem(
            vUserRiskItem
                .sort((a: risk_list, b: risk_list) => {
                    if (sortType === "risk_value") {
                        let pre =
                            a[sortType] === "無變異"
                                ? -Infinity
                                : parseFloat(a[sortType]);
                        let next =
                            b[sortType] === "無變異"
                                ? -Infinity
                                : parseFloat(b[sortType]);
                        return (pre > next ? 1 : -1) * iSort;
                    } else {
                        return (a[sortType] > b[sortType] ? 1 : -1) * iSort;
                    }
                })
                .slice(0)
        );
        setSortNow(iSort);
    };

    // new context
    const vNewUserListContext = {
        ...vUserListContext,
        bQuery,
        vUserListContextData: vUserListData,
        iSortNow,
        nowSortType,
        vUserRiskItem,
        sortReportListData,
    };

    return (
        <div className="container tab_container">
            <Provider value={vNewUserListContext}>
                <ReportInfo />
                <ReportTab
                    tabPage={tabPage}
                    changePage={changeTapPage}
                    vTabLi={vTabLi}
                />
                <div className="tab_content_1">{showComponent()}</div>
            </Provider>
        </div>
    );
};

export default Report;
