import React, { useRef, useState } from "react";

interface userAddUploadInput {
    getExcelData: (data: any) => void;
    ADDsetFile: (file: Blob | null) => void;
    file: Blob | null;
    sWaringText: string;
    checkFileFormate: (name: string) => boolean;
    setWarningText: (warning: string) => void;
    readExcel: () => void;
}

const UserAddUpload = (props: userAddUploadInput) => {
    const {
        ADDsetFile,
        sWaringText,
        checkFileFormate,
        setWarningText,
        readExcel,
    } = props;
    const [fileName, setFileName] = useState("");
    const [dragging, setDrag] = useState(false);

    const fileEl = useRef<HTMLInputElement | null>(null);

    const clickFileEl = () => {
        if (fileEl !== null && fileEl.current !== null) {
            fileEl.current.click();
        }
    };

    // 取消預設事件
    const overrideEventDefaults = (
        event: Event | React.DragEvent<HTMLDivElement>
    ): void => {
        event.preventDefault();
        event.stopPropagation();
    };

    // 拖曳資料
    const dragenterListener = (
        event: React.DragEvent<HTMLInputElement>
    ): void => {
        overrideEventDefaults(event);
        if (event.dataTransfer.items && event.dataTransfer.items[0]) {
            setDrag(true);
        }
    };

    // 拖放資料
    const dropFile = (event: any): void => {
        overrideEventDefaults(event);
        const file = event.dataTransfer.files;
        setDrag(false);
        const bCheckResult = checkFileFormate(file[0].name);
        if (bCheckResult && file) {
            setFileName(file[0].name);
            ADDsetFile(file[0]);
            setWarningText("");
            readExcel();
        }
    };

    // 選擇檔案
    const choseFile = (event: any) => {
        const file = event.target.files;
        const bCheckResult =
            file && file[0] ? checkFileFormate(file[0].name) : false;
        if (bCheckResult && file) {
            setFileName(file[0].name);
            ADDsetFile(file[0]);
            setWarningText("");
            readExcel();
        }
    };

    return (
        <div
            id="dropdiv"
            className={dragging ? "drag_over" : ""}
            onDragEnter={dragenterListener}
            onDrop={dropFile}
            onDrag={overrideEventDefaults}
            onDragStart={overrideEventDefaults}
            onDragEnd={overrideEventDefaults}
            onDragOver={overrideEventDefaults}
            onDragLeave={overrideEventDefaults}
            onClick={clickFileEl}
        >
            <img src="/img/upload_gray.png" alt="upload" />
            {sWaringText !== "" ? (
                <>
                    <p className="text_red font_bold">{sWaringText}</p>
                    <div className="upload_hint font_bold">
                        請先確認檔案內容
                    </div>
                </>
            ) : !fileName ? (
                <>
                    <p className="font_bold">點擊或拖曳要上傳的檔案</p>
                    <div className="upload_hint">檔案格式 .xlsx</div>
                </>
            ) : (
                <>
                    <p className="file_name">{fileName}</p>
                </>
            )}
            <input
                id="up_progress"
                className="noShow"
                type="file"
                ref={fileEl}
                onChange={choseFile}
            ></input>
        </div>
    );
};

export default UserAddUpload;
