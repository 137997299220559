import React from "react";

const Loading = () => {
    const el = document.getElementById("message");
    return !el ? null : (
        <div className="message_page">
            <div className="dialog_loading">
                <img
                    className="loading_img"
                    src="/img/loading.png"
                    alt="Loading..."
                />
                <div className="text_title">Loading...</div>
                <div>處理中，請耐心等候</div>
            </div>
        </div>
    );
};

export default Loading;
