import React, { useContext, useEffect } from "react";
import LoginInfoContext from "./Context/LoginInfoContext";
import { GoogleLogin } from "react-google-login";
import { useHistory } from "react-router-dom";

const Login = () => {
    const history = useHistory();
    const { roleData, loginCallback, sSystem, isHidden, getUrlDefault } =
        useContext(LoginInfoContext);

    const responseGoogle = (response: any) => {
        loginCallback(response.tokenId);
    };

    const failureCallback = (error: string) => {
        console.error(error);
    };

    useEffect(() => {
        history.push("/");

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        let url = getUrlDefault(roleData);
        history.push(url);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [sSystem.current, isHidden]);

    return (
        <div className="container login_container">
            <div className="login_block">
                <div className="login_top">
                    <img src="/img/big_logo.svg" alt="" />
                </div>
                <GoogleLogin
                    clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}
                    render={(renderProps) => (
                        <div
                            className="login_btn"
                            onClick={renderProps.onClick}
                        >
                            <img src="/img/google_logo.png" alt="" />
                            <span>Login in with Google</span>
                        </div>
                    )}
                    onSuccess={responseGoogle}
                    onFailure={failureCallback}
                    cookiePolicy="single_host_origin"
                    isSignedIn={false}
                />
            </div>
        </div>
    );
};

export default Login;
