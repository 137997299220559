import React, { useContext, useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import ReportContext from "../Context/ReportContext";
import Alert from "../component/Message";
import MessageContext, { vMsgProps } from "../Context/MessageContext";
import ModalAlert from "../component/Modal";
import { vModalgProps } from "../Context/ModalContext";
import LoginInfoContext from "../Context/LoginInfoContext";
import {
    IUserCommentInput,
    DoctorSaveCommentAPI,
    vSystemType,
} from "../utils/GeneBoxAPI";

interface url {
    type: string;
}

const ReportFilter = () => {
    const { sCurrentToken, sSystem, setCurrentToken, errorProcess } =
        useContext(LoginInfoContext);

    const history = useHistory();
    const location = useLocation();
    //doctor_sign and pmc_sign
    const pageFrom = location.pathname.split("/")[2];

    //message
    const { bShowAlert, showAlert } = useContext(MessageContext);
    const [alertProps, setAlertContent] = useState<vMsgProps>({
        title: "",
        sub_title: "",
        content: <></>,
        iMsg: 0,
        bBtnType: false,
        submitFun: () => {},
    });
    const { title, sub_title, content, iMsg, bBtnType } = alertProps;

    const [UserCommentInput, setUserCommentInput] =
        useState<IUserCommentInput[]>();

    //modal
    const [MdalertProps, setMdAlertContent] = useState<vModalgProps>({
        Md_Modal_title: <></>,
        Mdtitle: "",
        Mdcontent: <></>,
        Mdtitle_bgcolor: "",
        MdBtnType: 0,
        MdsubmitFun: () => {},
        MdcloseFun: () => {},
    });
    const { Md_Modal_title, Mdtitle, Mdcontent, MdBtnType, Mdtitle_bgcolor } =
        MdalertProps;
    const [bMdShowAlert_input, setMdshowAlert_input] = useState(false);
    const [input_content, setinput_content] = useState("");

    //get person information
    let { vUserListContextData } = useContext(ReportContext);

    const doublecheck = () => {
        setinput_content("");
        showAlert(true);
        setAlertContent({
            ...alertProps,
            title: "是否確定同意覆核",
            sub_title: "",
            content: <></>,
            iMsg: 2,
        });
        return false;
    };
    //axios upload function start
    const sign_api = () => {
        // upload sign
        const data = [
            {
                uid: vUserListContextData.uid,
                comment: input_content,
            },
        ];
        setUserCommentInput(data);
    };
    useEffect(() => {
        if (UserCommentInput !== undefined) {
            DoctorSaveCommentAPI(
                vSystemType[sSystem.current],
                sCurrentToken,
                UserCommentInput
            )
                .then((res) => {
                    if (res.respCode === 201 || res.respCode === 200) {
                        setCurrentToken(res.token);
                        setinput_content("");
                        showAlert(false);
                        setMdshowAlert_input(false);
                        history.push(
                            `/${pageFrom}/${vSystemType[sSystem.current]}`
                        );
                        // setuid("");
                    }
                })
                .catch((error) => {
                    console.error(error);
                    let error_status: number;
                    if (error.response !== undefined) {
                        error_status = error.response.status;
                        errorProcess(error_status, error);
                    } else {
                        error_status = 0;
                    }
                    errorProcess(error_status, error);
                });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [UserCommentInput]);
    //axios upload function end

    const input_comment = () => {
        setMdshowAlert_input(true);
        setMdAlertContent({
            ...MdalertProps,
            Mdtitle: "意見輸入",
            Mdcontent: (
                <>
                    <textarea
                        className="textarea_style"
                        placeholder="請輸入意見說明"
                        defaultValue={input_content}
                        onChange={(event) => changecontent(event.target.value)}
                    ></textarea>
                </>
            ),
            Mdtitle_bgcolor: "bg_blue_green",
            MdBtnType: 1,
        });
        return false;
        // upload sign
    };
    const changecontent = (e: string) => {
        setinput_content(e);
    };
    const input_comment_api = () => {
        // upload comment
        //axios send comment

        //check input content
        input_content === ""
            ? setMdAlertContent({
                  ...MdalertProps,
                  Mdcontent: (
                      <>
                          <textarea
                              className="textarea_error"
                              placeholder="請輸入意見說明"
                              defaultValue={input_content}
                              onChange={(event) =>
                                  changecontent(event.target.value)
                              }
                          ></textarea>
                      </>
                  ),
              })
            : sign_api();
    };

    const close_modal = () => {
        setMdshowAlert_input(false);
    };

    const returnPage = () => {
        let url = "";
        if (pageFrom === "report_list") {
            //特約醫生
            let supplier = vUserListContextData.uid
                .split("")
                .slice(0, 2)
                .join("")
                .toLowerCase();
            url = `/${pageFrom}/${supplier}/${vSystemType[sSystem.current]}`;
        } else {
            url = `/${pageFrom}/${vSystemType[sSystem.current]}`;
        }
        history.push(url);
    };

    return (
        <>
            <div className="tab_content_title">基因分析結果 基因變異列表</div>
            <div className="risk_up_content">
                <div className="content_user">
                    <div className="content_user_info">
                        <div>編號</div>
                        <div>{vUserListContextData.uid}</div>
                    </div>
                    <div className="content_user_info">
                        <div>日期</div>
                        <div>{vUserListContextData.date.split(" ")[0]}</div>
                    </div>
                    <div className="content_user_info">
                        <div>性別</div>
                        <div>{vUserListContextData.gender}</div>
                    </div>
                </div>
                <div className="content_user_button">
                    {pageFrom !== "report_list" && (
                        <div className="button_comment">
                            <button
                                className="button_style button_yellow btn_marginLeft"
                                onClick={doublecheck}
                            >
                                同意覆核
                            </button>
                            <button
                                className="button_style button_gray btn_marginLeft"
                                onClick={input_comment}
                            >
                                不同意覆核
                            </button>
                        </div>
                    )}

                    <div className="return_list">
                        <button
                            className="button_style button_green "
                            onClick={() => returnPage()}
                        >
                            回到列表
                        </button>
                    </div>
                </div>
            </div>
            {bShowAlert && (
                <Alert
                    title={title}
                    sub_title={sub_title}
                    content={content}
                    iMsg={iMsg}
                    bBtnType={bBtnType}
                    submitFun={sign_api}
                />
            )}

            {bMdShowAlert_input && (
                <ModalAlert
                    Md_Modal_title={Md_Modal_title}
                    Mdtitle={Mdtitle}
                    Mdcontent={Mdcontent}
                    Mdtitle_bgcolor={Mdtitle_bgcolor}
                    MdBtnType={MdBtnType}
                    MdsubmitFun={input_comment_api}
                    MdcloseFun={close_modal}
                />
            )}
        </>
    );
};

export default ReportFilter;
