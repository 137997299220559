import { createContext } from "react";
import { vSignListOutput } from "../utils/GeneBoxAPI";

const ReportListContext = createContext({
    sStartDate: "" as string,
    sEndDate: "" as string,
    setsStartDate: (x: string) => {},
    setsEndDate: (x: string) => {},
    vReportList: [] as vSignListOutput[],
    setvReportList: (x: vSignListOutput[]) => {},
    vReportIndex: [] as number[],
    iStartItem: 0 as number,
    iEndItem: 0 as number,
    bQueryNow: false as boolean,
    bQueryFirst: false as boolean,
    iSortNow: 1 as number,
    nowSortType: "" as string,
    sortSignData: (
        sortType: string,
        event: React.MouseEvent<HTMLTableHeaderCellElement> | null
    ): void => {},
    filterData: (filter_type: string, filter_text: string) => {},
});

export const { Provider } = ReportListContext;

export default ReportListContext;
