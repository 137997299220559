import React from "react";

const year = new Date().getFullYear();
const copyright_year = year === 2020 ? "2020" : "2018-" + year;

function FootBar() {
    return (
        <footer>
            <span>{`© ${copyright_year} Ever Fortune.AI Co., Ltd. All rights reserved`}</span>
        </footer>
    );
}

export default FootBar;
