import React, { useContext, useEffect, useState } from "react";
import Modal from "../component/Modal";
import { vModalgProps } from "../Context/ModalContext";
import UserListContext from "../Context/UserListContext";
import { vUserState } from "../utils/GeneBoxAPI";
import UserDesWord from "./UserDesWord";

interface UserDesModalInput {
    iUserIdx: number;
    showDesModal: (idx: number) => void;
}

const UserDesModal = (props: UserDesModalInput) => {
    const { iUserIdx, showDesModal } = props;
    let { vUserListContextShowData } = useContext(UserListContext);
    const [MdalertProps, setMdAlertContent] = useState<vModalgProps>({
        Md_Modal_title: <></>,
        Mdtitle: "",
        Mdcontent: <></>,
        Mdtitle_bgcolor: "",
        MdBtnType: 0,
        MdsubmitFun: () => {},
        MdcloseFun: () => {},
    });
    const getModalInfo = () => {
        let Md_Modal_title = <></>;
        let Mdtitle = "";
        let Mdcontent = <></>;
        let Mdtitle_bgcolor = "";
        let MdBtnType = 0;
        let MdsubmitFun = () => {};
        let MdcloseFun = () => {};

        switch (vUserListContextShowData[iUserIdx].state) {
            // 異常說明 : 其他
            case vUserState.abnormal_other:
                Md_Modal_title = <div className="text_white">異常說明</div>;
                Mdcontent = (
                    <div className="modal_content_text small_dialog">
                        {vUserListContextShowData[iUserIdx].error_message}
                    </div>
                );
                MdBtnType = 0;
                Mdtitle_bgcolor = "bg_red";
                MdcloseFun = () => showDesModal(iUserIdx);
                break;
            // 不同意覆核
            case vUserState.disagree_center:
            case vUserState.disagree_doctor:
            case vUserState.disagree_both:
                Md_Modal_title = <div className="text_white">意見說明</div>;
                Mdcontent = (
                    <UserDesWord {...vUserListContextShowData[iUserIdx]} />
                );
                MdBtnType = 0;
                Mdtitle_bgcolor = "bg_blue_green";
                MdcloseFun = () => showDesModal(iUserIdx);
                break;
            default:
                break;
        }
        setMdAlertContent({
            Md_Modal_title,
            Mdtitle,
            Mdcontent,
            Mdtitle_bgcolor,
            MdBtnType,
            MdsubmitFun,
            MdcloseFun,
        });
    };

    useEffect(getModalInfo, []);
    return (
        <>
            <Modal {...MdalertProps} />
        </>
    );
};

export default UserDesModal;
