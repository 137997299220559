import React, { useContext, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";

import LoginInfoContext from "../Context/LoginInfoContext";

const Redirect = () => {
    const { logoutCallback } = useContext(LoginInfoContext);
    const history = useHistory();
    const [iTimer, setiTimer] = useState(5);

    const redirectUrl = () => {
        logoutCallback();
        history.push("/");
    };

    const backToHomePage = () => {
        return setTimeout(() => {
            if (iTimer === 0) {
                redirectUrl();
            } else if (iTimer > 0) {
                setiTimer((previTimer) => previTimer - 1);
            }
        }, 1000);
    };

    useEffect(() => {
        let timeoutID = backToHomePage();
        return () => {
            window.clearTimeout(timeoutID);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [iTimer]);

    return (
        <div id="redirect" className="container">
            <h4>404 NOT FOUND</h4>
            <p>page not found, {iTimer} seconds back to Login.</p>
        </div>
    );
};

export default Redirect;
