import React from "react";

interface ReportTabProps {
    tabPage: string;
    changePage: (pageName: string) => void;
    vTabLi: { text: string; tabPageName: string; maincolor: string }[];
}

const ReportTab = (props: ReportTabProps) => {
    const { tabPage, changePage, vTabLi } = props;

    const handleChange = (e: string) => {
        changePage(e);
    };

    return (
        <>
            <ul className="word_black tab_design report_tab">
                {vTabLi.map((vTab) => {
                    const { tabPageName, text } = vTab;
                    return (
                        <li
                            className={
                                tabPage === tabPageName
                                    ? "checked_tab text_" + vTab.maincolor
                                    : ""
                            }
                            onClick={() => changePage(tabPageName)}
                            key={text}
                        >
                            {text}
                        </li>
                    );
                })}
            </ul>
            <select
                className="word_black tab_design_phone report_tab_phone"
                onChange={(event) => handleChange(event.target.value)}
            >
                {vTabLi.map((vTab) => {
                    const { tabPageName, text } = vTab;
                    return (
                        <option key={text} value={tabPageName}>
                            {text}
                        </option>
                    );
                })}
            </select>
        </>
    );
};

export default ReportTab;
