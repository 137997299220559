import { createContext } from "react";

// Date: Int to string
const changeIntToString = (int: number, type: boolean = false) => {
    int = type ? int + 1 : int;
    let sInt = int.toString().padStart(2, "0");
    return sInt;
};

// 得到 iPastDate 天前的日期格式
const getPastDate = (iPast: number, type: string) => {
    let sEndTime = new Date();

    switch (type) {
        case "date":
            sEndTime.setDate(sEndTime.getDate() - iPast);
            break;
        case "month":
            sEndTime.setMonth(sEndTime.getMonth() - iPast);
            break;
        default:
            break;
    }
    const [sEndYear, sEndMonth, sEndDate] = [
        sEndTime.getFullYear(),
        sEndTime.getMonth(),
        sEndTime.getDate(),
    ];
    const sResultDate: string =
        changeIntToString(sEndYear) +
        "-" +
        changeIntToString(sEndMonth, true) +
        "-" +
        changeIntToString(sEndDate);
    return sResultDate;
};

//GeneBox Start Date
const sGeneBoxStart: string = "2020-09-01";

//Year ago
const sYearBefore: string = getPastDate(12, "month");

// 2 months ago
const sStartDefault: string = getPastDate(2, "month");

// Today
const sEndDefault: string = getPastDate(0, "date");

const DateContext = createContext({
    sStartDefault,
    sEndDefault,
    sYearBefore,
    sGeneBoxStart,
    changeIntToString,
    getPastDate,
});

export default DateContext;
