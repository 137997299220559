import React, { useState, useContext, useEffect } from "react";
import DateContext from "../Context/DateContext";
import ExceptContext from "../Context/ExceptContext";
import LoginInfoContext from "../Context/LoginInfoContext";
// import { vOrgType } from "../utils/GeneBoxAPI";

interface props {
    id: string;
    org: string;
    name: string;
    sStartDate: string;
    setId: React.Dispatch<React.SetStateAction<string>>;
    setOrg: React.Dispatch<React.SetStateAction<string>>;
    setName: React.Dispatch<React.SetStateAction<string>>;
    setStartDate: React.Dispatch<React.SetStateAction<string>>;
}

const CheckExceptFilter = (props: props) => {
    const { id, org, name, sStartDate, setId, setOrg, setName, setStartDate } =
        props;

    const { sEndDefault, sGeneBoxStart } = useContext(DateContext);
    const {
        filterData,
        getExceptData,
        postUserComment,
        bQueryNow,
        bQueryFirst,
    } = useContext(ExceptContext);
    const { roleData, setbLoading } = useContext(LoginInfoContext);

    const [sEndDate, setsEndDate] = useState(sEndDefault);
    const [timeoutID, setTimeoutID] = useState(0);

    const getId = (event: React.FormEvent<HTMLInputElement>) => {
        const id = event.currentTarget.value;
        setId(id);
    };

    const getOrg = (event: React.ChangeEvent<HTMLSelectElement>) => {
        const org = event.currentTarget.value;
        setOrg(org);
    };

    const getName = (event: React.FormEvent<HTMLInputElement>) => {
        const name = event.currentTarget.value;
        setName(name);
    };

    const getStartDate = (event: React.FormEvent<HTMLInputElement>) => {
        const date = event.currentTarget.value;
        setStartDate(date);
    };

    const getEndDate = (event: React.FormEvent<HTMLInputElement>) => {
        const date = event.currentTarget.value;
        setsEndDate(date);
    };

    // filter data by frontend
    useEffect(() => {
        filterData(id, org, name);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [id, org, name]);

    useEffect(() => {
        if (!bQueryNow && bQueryFirst) {
            setTimeoutID(
                window.setTimeout(
                    () => getExceptData(sStartDate, sEndDate),
                    2000
                )
            );
            return () => {
                window.clearTimeout(timeoutID);
            };
        } else {
            getExceptData(sStartDate, sEndDate);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [sStartDate, sEndDate]);

    useEffect(() => {
        setbLoading(bQueryNow);
    }, [bQueryNow, setbLoading]);

    return (
        <div className="search_list">
            <p className="search_list_bottom">篩選名單 /</p>
            <div className="search_list_content">
                <div className="search_input">
                    <label>
                        <span>受檢編號</span>
                        <input type="text" value={id} onChange={getId} />
                    </label>
                    <label>
                        <span>送檢單位</span>
                        <select value={org} onChange={getOrg}>
                            <option value="">全部</option>
                            {Object.keys(roleData.projects.genebox.vendor).map(
                                (key, idx) => (
                                    <option value={key} key={idx}>
                                        {key}
                                    </option>
                                )
                            )}
                        </select>
                    </label>
                    <label>
                        <span>姓名</span>
                        <input type="text" value={name} onChange={getName} />
                    </label>
                    <label>
                        <span>送檢日期</span>
                        <input
                            type="date"
                            value={sStartDate}
                            onChange={getStartDate}
                            min={sGeneBoxStart}
                            max={sEndDefault}
                        />
                        <span className="date_dash">-</span>
                        <input
                            type="date"
                            value={sEndDate}
                            onChange={getEndDate}
                            min={sStartDate}
                            max={sEndDefault}
                        />
                    </label>
                </div>
                <button
                    className="button_style button_red"
                    onClick={postUserComment}
                >
                    登錄異常
                </button>
            </div>
        </div>
    );
};

export default CheckExceptFilter;
