import React, { useContext, useState, useEffect } from "react";
import UserListContext from "../Context/UserListContext";
import { vModalgProps } from "../Context/ModalContext";
import Modal from "../component/Modal";
import { EditInput } from "../utils/GeneBoxAPI";

interface UserEditInput {
    sUidEddit: string;
    editUser: (uid: string) => void;
}

const UserEditModal = (props: UserEditInput) => {
    const { sUidEddit, editUser } = props;
    const { vUserListContextData, editUserInfo } = useContext(UserListContext);
    const [MdalertProps, setMdAlertContent] = useState<vModalgProps>({
        Md_Modal_title: <></>,
        Mdtitle: "",
        Mdcontent: <></>,
        Mdtitle_bgcolor: "",
        MdBtnType: 1,
        MdsubmitFun: () => {},
        MdcloseFun: () => {},
    });

    const [sUname, setUname] = useState("");
    const [sGender, setGender] = useState("");
    const [sdate, setDate] = useState("");
    const [sDes, setDes] = useState("");
    const [sUserState, setUserState] = useState<number>(-1);

    const showUserInfo = () => {
        const vUserUid = vUserListContextData.map((vUser) => vUser.uid);
        const vUserList = vUserListContextData[vUserUid.indexOf(sUidEddit)];
        setUname(vUserList.uname);
        setGender(vUserList.gender);
        setDate(vUserList.date);
        setDes(vUserList.error_message);
        setUserState(vUserList.state);
    };

    // const changeName = (event: React.FormEvent<HTMLInputElement>) => {
    //     setUname(event.currentTarget.value);
    // };

    // const changeGender = (event: React.FormEvent<HTMLSelectElement>) => {
    //     setGender(event.currentTarget.value);
    // };

    // const changeDate = (event: React.FormEvent<HTMLInputElement>) => {
    //     setDate(event.currentTarget.value);
    // };

    const changeDes = (event: React.FormEvent<HTMLInputElement>) => {
        setDes(event.currentTarget.value);
    };

    const getModalProps = () => {
        const vEditInput: EditInput = {
            uid: sUidEddit,
            state: sUserState,
            uname: sUname,
            gender: sGender,
            date: sdate,
            description: sDes,
        };
        const Md_Modal_title = <>修改受檢者資料</>;
        const Mdtitle = `${sUidEddit}`;
        const Mdcontent = (
            <div className="dialog_edit_content">
                <label>
                    <span className="edit_short_span">姓名</span>
                    <label>{vEditInput.uname}</label>
                    {/* <input
                        type="text"
                        value={sUname}
                        onChange={(event) => changeName(event)}
                    /> */}
                </label>
                <label>
                    <span className="edit_short_span">性別</span>
                    <label>{vEditInput.gender}</label>
                    {/* <select
                        value={sGender}
                        onChange={(event) => changeGender(event)}
                    >
                        <option value="男">男</option>
                        <option value="女">女</option>
                    </select> */}
                </label>
                <label>
                    <span>受檢日期</span>
                    <label>{vEditInput.date}</label>
                    {/* <input
                        type="date"
                        value={sdate}
                        onChange={(event) => changeDate(event)}
                    /> */}
                </label>
                <label>
                    <span className="edit_short_span">說明</span>
                    <input
                        type="textarea"
                        value={sDes}
                        onChange={(event) => changeDes(event)}
                    />
                </label>
            </div>
        );
        const Mdtitle_bgcolor = "bg_blue_green";
        const MdcloseFun = () => {
            editUser("");
        };

        const MdsubmitFun = () => {
            editUserInfo(vEditInput, editUser);
        };

        setMdAlertContent({
            ...MdalertProps,
            Md_Modal_title,
            Mdtitle,
            Mdcontent,
            Mdtitle_bgcolor,
            MdcloseFun,
            MdsubmitFun,
        });
    };

    useEffect(showUserInfo, []);

    useEffect(getModalProps, [sUname, sGender, sdate, sDes]);

    return (
        <>
            <Modal {...MdalertProps} />
        </>
    );
};

export default UserEditModal;
