import React, { RefObject, useContext } from "react";
import LoginInfoContext from "../Context/LoginInfoContext";
import { GoogleLogout } from "react-google-login";

interface LogoutInput {
    click_logout: RefObject<HTMLButtonElement>;
}

const Logout = (props: LogoutInput) => {
    const { isHidden, logout } = useContext(LoginInfoContext);

    const { click_logout } = props;

    return (
        <>
            {isHidden && (
                <div className="user_status">
                    <GoogleLogout
                        clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}
                        buttonText="Logout"
                        onLogoutSuccess={logout}
                        render={(renderProps) => (
                            <button
                                ref={click_logout}
                                className="logout"
                                onClick={renderProps.onClick}
                            >
                                Logout
                            </button>
                        )}
                    />
                </div>
            )}
        </>
    );
};
export default Logout;
