import React from "react";
import { createPortal } from "react-dom";
import { vModalgProps } from "../Context/ModalContext";

const ModalAlert = (props: vModalgProps) => {
    const {
        Md_Modal_title,
        Mdtitle,
        Mdcontent,
        MdBtnType,
        Mdtitle_bgcolor,
        MdsubmitFun,
        MdcloseFun,
    } = props;
    // const { MdshowAlert } = useContext( ModalContext );
    // const MdshowAlert = (bMdAlert: boolean) => {

    // }

    const el = document.getElementById("modal");

    return !el
        ? null
        : createPortal(
              <div className="message_page">
                  <div className="dialog">
                      <div id="dialog_title" className={Mdtitle_bgcolor}>
                          {Md_Modal_title}
                      </div>
                      <div className="dialog_content">
                          <div className="text_title">{Mdtitle}</div>
                          <div className="Check_width">{Mdcontent}</div>
                          {MdBtnType === 0 ? (
                              <input
                                  type="button"
                                  value="確認"
                                  className="button_style button_yellow"
                                  onClick={() => MdcloseFun()}
                              />
                          ) : MdBtnType === 1 ? (
                              <div className="btn_group">
                                  <input
                                      type="button"
                                      value="確認"
                                      className="button_style button_yellow"
                                      onClick={() => MdsubmitFun()}
                                  />
                                  <input
                                      type="button"
                                      value="取消"
                                      className="button_style button_gray"
                                      onClick={() => MdcloseFun()}
                                  />
                              </div>
                          ) : (
                              ""
                          )}
                      </div>
                  </div>
              </div>,
              el
          );
};

export default ModalAlert;
