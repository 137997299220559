import React, { useContext } from "react";
import { createPortal } from "react-dom";
import MessageContext, { vMsgProps } from "../Context/MessageContext";

interface vMsg {
    img: string;
    title_bgcolor: string;
}

const Alert = (props: vMsgProps) => {
    const { title, sub_title, content, iMsg, bBtnType, submitFun } = props;
    const { showAlert } = useContext(MessageContext);
    const el = document.getElementById("message");
    const vMsgTYpe: vMsg[] = [
        {
            img: "check_blue.png",
            title_bgcolor: "bg_blue",
        },
        {
            img: "cross_red.png",
            title_bgcolor: "bg_red",
        },
        {
            img: "warning_yellow.png",
            title_bgcolor: "bg_yellow",
        },
    ];

    return !el
        ? null
        : createPortal(
              <div className="message_page">
                  <div className="dialog">
                      <div
                          id="dialog_title"
                          className={vMsgTYpe[iMsg].title_bgcolor}
                      ></div>
                      <div className="dialog_content">
                          <img src={`/img/${vMsgTYpe[iMsg].img}`} alt="" />
                          <div className="text_title">{title}</div>
                          <div className="text_subtitle">{sub_title}</div>
                          <div>{content}</div>
                          {bBtnType ? (
                              <input
                                  type="button"
                                  value="確認"
                                  className="button_style button_yellow"
                                  onClick={() => showAlert(false)}
                              />
                          ) : (
                              <div className="btn_group">
                                  <input
                                      type="button"
                                      value="確認"
                                      className="button_style button_yellow"
                                      onClick={() => submitFun()}
                                  />
                                  <input
                                      type="button"
                                      value="取消"
                                      className="button_style button_gray"
                                      onClick={() => showAlert(false)}
                                  />
                              </div>
                          )}
                      </div>
                  </div>
              </div>,
              el
          );
};

export default Alert;
