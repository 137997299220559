import React, { useContext, useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";

import LoginInfoContext from "../Context/LoginInfoContext";
import { RouteType } from "../router";
import { vSystemType /*, vOrgType*/ } from "../utils/GeneBoxAPI";

interface url {
    type: string;
}

interface props {
    allRouteInfo: RouteType[];
}

const Tab = (props: props) => {
    const { allRouteInfo } = props;
    //context
    const { sSystem } = useContext(LoginInfoContext);
    const history = useHistory();
    const pathName = history.location.pathname;
    const { type: SystemType }: url = useParams();
    const [vTabLi, setvTabLi] = useState<RouteType[]>([]);

    const getTabList = (routeInfo: RouteType[]) => {
        const vTabLi = routeInfo.filter((route) => {
            return (
                route.bTab &&
                route.authorize.includes(vSystemType[sSystem.current])
            );
        });
        return vTabLi;
    };

    const changeLink = (breadcrumbName: string) => {
        history.push(`/${breadcrumbName}/${vSystemType[sSystem.current]}`);
    };

    const getSelectVal = () => {
        const vPath = pathName.split("/");
        vPath.pop();
        vPath.shift();
        const sPath = "/" + vPath.join("/") + "/";
        return sPath;
    };

    useEffect(() => {
        setvTabLi(getTabList(allRouteInfo));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [SystemType]);

    return (
        <>
            <ul className="word_black tab_design">
                {vTabLi.map((vTab) => {
                    const { text, bTab, breadcrumbName } = vTab;
                    if (!bTab) return false;
                    return (
                        <li
                            className={
                                pathName.includes(breadcrumbName)
                                    ? "checked_tab"
                                    : ""
                            }
                            onClick={() => changeLink(breadcrumbName)}
                            key={text}
                        >
                            {text}
                        </li>
                    );
                })}
            </ul>
            <select
                className="word_black tab_design_phone"
                onChange={(e) => changeLink(e.currentTarget.value)}
                value={getSelectVal()}
            >
                {vTabLi.map((vTab) => {
                    const { text, bTab, breadcrumbName } = vTab;
                    if (!bTab) return false;

                    return (
                        <option key={text} value={breadcrumbName}>
                            {text}
                        </option>
                    );
                })}
            </select>
        </>
    );
};

export default Tab;
