import React, { useContext, useEffect, useState, useRef } from "react";
import UserListContext from "../Context/UserListContext";
import { exceptStatus, vExceptOption } from "../Context/ExceptContext";
import LoginInfoContext from "../Context/LoginInfoContext";

import {
    AllPdf,
    getUserListOutput,
    pdfMode,
    UserPdfMode,
    vSystemType,
    vUserState,
} from "../utils/GeneBoxAPI";
import UserDesModal from "./UserDesModal";
import UserEditModal from "./UserEditModal";
import PageNumber from "../component/PageNumber";
import MultipleSelectCheckMarks from "./MultipleSelectCheckMarks";
import { Autocomplete, TextField } from "@mui/material";
import { makeStyles } from "@mui/styles";

interface specialTex {
    [x: string]: string;
}

// 2021/06/30 要求客製化註記 VT1100049
// 2021/09/15 要求客製化註記 ESGAZ11000122 TMGAM1100000002
// 2021/09/17 要求客製化註記 EHGZZ11000001 ~ EHGZZ11000006
//2021/11/22 要求客製化註記 ESGAY1100042
//2021/11/26 要求客製化註記 ESGAY11000035
//2021/12/2  要求客製化註記 VTGAO11000009 ESGA11010386 ESGA11010176
//2021/12/5 要求客製化註記 ESGA11010177
//2022/1/13 要求客製化註記 ESGA11010190

const SPECIAL_USER: specialTex = {
    VT1100049:
        "第三次檢測，故採2管送檢，與中附醫相同，只收取1管(VT1100050)費用，本檢體不收費。",
    ESGAZ11000122:
        "與ESGAZ11000036同一案件，實驗室誤將檢體重複上機，此件不收費。",
    TMGAM1100000002:
        "與TMGAM11000001同一案件，實驗室誤將檢體重複上機，此件不收費。",
    EHGZZ11000001: "DEMO",
    EHGZZ11000002: "DEMO",
    EHGZZ11000003: "DEMO",
    EHGZZ11000004: "DEMO",
    EHGZZ11000005: "DEMO",
    EHGZZ11000006: "DEMO",
    EHGZZ11000007: "DEMO",
    EHGZZ11000008: "DEMO",
    EHGZZ11000009: "DEMO",
    EHGZZ11000010: "DEMO",
    ESGAY1100042: "此為重複訂單，已取消訂單",
    ESGAY11000035: "原名錯誤，由許祐川改成許怙川。",
    VTGAO11000009: "API回傳錯誤，此訂單取消",
    ESGA11010386: "API回傳錯誤，此訂單取消",
    ESGA11010176: "API回傳錯誤，此訂單取消",
    ESGA11010177: "編號錯誤，此訂單取消",
    ESGA11010190: "編號錯誤，此訂單取消",
};

interface UserPage {
    SystemType: string;
    List: getUserListOutput[];
    vUserPDFselect: UserPdfMode[];
    vAllPDFMode: AllPdf[];
    vChoosePDFAll: AllPdf;
    setUserPDFselect: React.Dispatch<React.SetStateAction<UserPdfMode[]>>;
    setChoosePDFAll: React.Dispatch<React.SetStateAction<AllPdf>>;
}
// PDF MOD SELECT CSS
const useStyles = makeStyles((theme) => ({
    inputRoot: {
        // This matches the specificity of the default styles at https://github.com/mui-org/material-ui/blob/v4.11.3/packages/material-ui-lab/src/Autocomplete/Autocomplete.js#L90
        "& label": {
            color: "white",
            fontWeight: "bold",
        },
        "& label.Mui-focused": {
            color: "white",
            fontWeight: "bold",
        },
        "& .MuiInput-underline::before": {
            borderBottomColor: "white",
        },
        "& .MuiInput-underline-focus": {
            borderBottomColor: "white",
        },
        "& .MuiInput-underline": {
            color: "white",
            fontWeight: "bold",
            fontSize: 18,
        },
    },
}));

const UserList = (props: UserPage) => {
    const classes = useStyles();
    const {
        SystemType,
        List,
        vUserPDFselect,
        vAllPDFMode,
        vChoosePDFAll,
        setUserPDFselect,
        setChoosePDFAll,
    } = props;

    // context
    const {
        vTableColumn,
        iSortNow,
        iNowPage,
        nowSortType,
        vStatusContextCheckBox,
        vUserId,
        bQueryNow,
        org,
        bCheckAll,
        setCheckAll,
        changeSortUserListData,
        changePage,
        choseUser,
        choseAllUser,
        //redoPDF,
        updateAcceptanceTimeHandler,
    } = useContext(UserListContext);
    const { bAdmin, roleData } = useContext(LoginInfoContext);

    //ref
    const listTable = useRef<HTMLTableElement>(null);

    const iItemInPage = 10;
    const iMaxPage = Math.ceil(List.length / iItemInPage);
    const iStartItem = (iNowPage - 1) * iItemInPage;
    const iEndItem = iNowPage * iItemInPage - 1;
    const vUserNumber = List.slice(iStartItem, iEndItem + 1)
        .filter(
            (vData) =>
                vData.state === 9 || vData.state === 10 || vData.state === 16
        )
        .map((vData) => vData.uid);

    // state
    const [bDesShow, setDesShow] = useState(false);
    const [iUserIdx, setUserIdx] = useState(-1);
    const [bEditShow, setEditShow] = useState(false);
    const [sUidEddit, setUidEddit] = useState("");
    const [iColNumber, setiColNumber] = useState(0);

    const [bshowFail, setbshowFail] = useState(false);

    // change sort class
    const getSortClass = (key_name: string) => {
        const sSortClass = iSortNow === 1 ? " order" : " order_reverse";
        return nowSortType === key_name ? sSortClass : "";
    };

    const getvStatus = (state: number) => {
        for (const vStatusContext of vStatusContextCheckBox[SystemType]) {
            if (vStatusContext.state.indexOf(state) > -1) {
                return vStatusContext;
            }
        }
    };

    const getIndexOfStatus = (statusArray: exceptStatus[], status: number) => {
        return statusArray.findIndex((object) => object.status === status);
    };

    // button
    const showDescription = (
        vUserListContext: getUserListOutput,
        idx: number
    ) => {
        if (Object.keys(SPECIAL_USER).includes(vUserListContext.uid)) {
            return <span>{SPECIAL_USER[vUserListContext.uid]}</span>;
        }
        switch (SystemType) {
            case "nips":
                break;
            case "gutflora":
                let findGutStatusIndex = getIndexOfStatus(
                    vExceptOption[SystemType],
                    vUserListContext.state
                );
                if (vUserListContext.state === vUserState.abnormal_pick_again) {
                    //重新檢驗
                    return (
                        <span className="text_red">
                            {vExceptOption[SystemType][findGutStatusIndex].text}
                        </span>
                    );
                }
                break;
            case "genebox":
                let findGeneStatusIndex = getIndexOfStatus(
                    vExceptOption[SystemType],
                    vUserListContext.state
                );
                if (
                    vStatusContextCheckBox[SystemType][1].state.indexOf(
                        vUserListContext.state
                    ) > -1
                ) {
                    // 異常
                    if (
                        vUserListContext.state ===
                        vUserState.abnormal_pick_again
                    ) {
                        return (
                            <span className="text_red">
                                {
                                    vExceptOption[SystemType][
                                        findGeneStatusIndex
                                    ].text
                                }
                            </span>
                        );
                    }
                    if (vUserListContext.error_message !== "") {
                        return (
                            // 把其他異常輸入按鈕拔除
                            // <span
                            //     className="button_style button_red_bgwhite button_shadow"
                            //     onClick={() => showDesModal(idx)}
                            // >
                            //     其他
                            // </span>
                            <span className="text_red">
                                {vUserListContext.error_message}
                            </span>
                        );
                    }
                } else if (
                    vUserListContext.state === vUserState.abnormal_detect_again
                ) {
                    //重新檢驗
                    return (
                        <span className="text_brown">
                            {
                                vExceptOption[SystemType][findGeneStatusIndex]
                                    .text
                            }
                        </span>
                    );
                } else if (
                    vStatusContextCheckBox[SystemType][7].state.indexOf(
                        vUserListContext.state
                    ) > -1
                ) {
                    // 不同意
                    let text = "";
                    switch (vUserListContext.state) {
                        case vUserState.disagree_center:
                            text = "中心建議";
                            break;
                        case vUserState.disagree_doctor:
                            text = "醫生建議";
                            break;
                        case vUserState.disagree_both:
                            text = "建議";
                            break;
                        default:
                            break;
                    }
                    return (
                        <span
                            className="button_style button_gray button_shadow"
                            onClick={() => showDesModal(idx)}
                        >
                            {text}
                        </span>
                    );
                } else if (
                    vStatusContextCheckBox[SystemType][3].state.indexOf(
                        vUserListContext.state
                    ) > -1
                ) {
                    // 待簽名
                    let text = "";
                    switch (vUserListContext.state) {
                        case vUserState.unsigned_center:
                            text = "精準醫學中心";
                            break;
                        case vUserState.unsigned_doctor:
                            text = "醫生";
                            break;
                        case vUserState.unsigned_both:
                            text = "精準醫學中心、醫生";
                            break;
                        default:
                            break;
                    }
                    return (
                        <span>
                            待<span className="text_skyblue">{text}</span>簽核
                        </span>
                    );
                } else if (vUserListContext.error_message !== "") {
                    return <span>{vUserListContext.error_message}</span>;
                }
                break;
            default:
                break;
        }
    };

    //換頁(VT->EH)清空所有已選
    useEffect(() => {
        setCheckAll(false);
        choseAllUser(vUserNumber, false);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [org]);

    // show des modal
    const showDesModal = (iUserIdx: number) => {
        setDesShow(!bDesShow);
        setUserIdx(iUserIdx);
    };

    // check all
    const checkAll = (bCheck: boolean) => {
        setCheckAll(bCheck);
        choseAllUser(vUserNumber, bCheck);
    };

    // checkAll need reset when change page
    const changePageUserList = (page_number: number) => {
        changePage(page_number);
        setCheckAll(false);
    };

    // check checkAll when click file_download input
    const clcikFileDownload = (user_number: string) => {
        if (vUserId.indexOf(user_number) > -1) {
            setCheckAll(false);
        } else if (vUserNumber.length === vUserId.length + 1) {
            setCheckAll(true);
        }
        choseUser(user_number);
    };

    // edit user
    const editUser = (uid: string) => {
        setEditShow(!bEditShow);
        setUidEddit(uid);
    };

    // count table column
    const countCol = () => {
        if (
            typeof listTable.current?.tHead?.childNodes[0].childNodes.length ===
            "number"
        ) {
            setiColNumber(
                listTable.current?.tHead?.childNodes[0].childNodes.length + 1
            );
        }
    };

    const initPDFselect = (vUserList: getUserListOutput[]) => {
        if (vUserList.length === 0) return;
        let tempDownload: UserPdfMode[] = [];

        vUserList
            .slice(iStartItem, iEndItem + 1)
            .filter(
                (vData) =>
                    vData.state === 9 ||
                    vData.state === 10 ||
                    vData.state === 16
            )
            .map((vData) => vData.uid)
            .forEach((uid: string) => {
                //要多判斷是否已經有選過pdf mod
                let findUserMode: UserPdfMode | undefined = vUserPDFselect.find(
                    (item) => item.uid === uid
                );
                let tempMode: pdfMode[] = [];
                if (findUserMode === undefined) {
                    tempMode = [];
                } else {
                    tempMode = findUserMode.mod_list;
                }
                tempDownload.push({
                    uid: uid,
                    mod_list: tempMode,
                });
            });
        setUserPDFselect(tempDownload);
    };

    const checkAllGenePDF = () => {
        if (vChoosePDFAll.id === "") {
            // show alert
            setbshowFail(true);
            return;
        }
        let tempUserPDFselect = JSON.parse(JSON.stringify(vUserPDFselect));
        if (!bCheckAll) {
            let tempChooseData = JSON.parse(JSON.stringify(vChoosePDFAll));
            tempChooseData["status"] = true;

            tempUserPDFselect.forEach((User: UserPdfMode, idx: number) => {
                //檢查是否已存在mod_list
                if (
                    vUserPDFselect[idx].mod_list.find(
                        (item) => item.id === vChoosePDFAll.id
                    ) === undefined
                ) {
                    //不存在則確認是否status為true
                    if (checkUser(User.uid)) {
                        User.mod_list.push(tempChooseData);
                    }
                }
            });
            setUserPDFselect(tempUserPDFselect);
        } else {
            tempUserPDFselect.forEach((User: UserPdfMode, idx: number) => {
                //檢查是否已存在mod_list
                User.mod_list = User.mod_list.filter(
                    (item) => item.id !== vChoosePDFAll.id
                );
            });
            setUserPDFselect(tempUserPDFselect);
        }
        setCheckAll(!bCheckAll);
    };

    const checkUser = (UserUID: string) => {
        let UserIndex = List.findIndex((item) => item.uid === UserUID);
        if (UserIndex === -1) return false;
        let findItem = List[UserIndex].mod_generation_status.find(
            (item) => item.id === vChoosePDFAll.id
        );
        //有配對到項目，且status為true
        if (findItem !== undefined && findItem.status === true) return true;
        return false;
    };

    const onSelectPDFModeChange = (event: any, values: any) => {
        event.stopPropagation();
        if (values === null) {
            setChoosePDFAll({ id: "", name: "" });
            return;
        }
        setChoosePDFAll(values);
    };

    const FailMes = () => {
        return (
            <>
                <div className="message_page">
                    <div className="dialog">
                        <div id="dialog_title" className="bg_yellow"></div>
                        <div className="dialog_content">
                            <img src={`/img/warning_yellow.png`} alt="" />
                            <div className="text_subtitle">請選擇PDF模組</div>
                            <input
                                type="button"
                                value="確認"
                                className="button_style button_yellow"
                                onClick={() => setbshowFail(false)}
                            />
                        </div>
                    </div>
                </div>
            </>
        );
    };
    //取得可使用的PDF Mod id 集合
    const getValidID = () => {
        let combinationID: string[] = [];

        vUserPDFselect.forEach((User) => {
            let UserIndex = List.findIndex((item) => item.uid === User.uid);
            if (UserIndex !== -1)
                if (List[UserIndex].mod_generation_status !== null)
                    List[UserIndex].mod_generation_status.forEach((item) => {
                        if (!combinationID.includes(item.id) && item.status) {
                            combinationID.push(item.id);
                        }
                    });
        });
        return combinationID;
    };

    const getValidOption = () => {
        let combinationID: string[] = getValidID();
        return vAllPDFMode.filter((item) => combinationID.includes(item.id));
    };

    const getOptionDisabled = (option: AllPdf) => {
        //上方選取全選pdf mod 必須要有套組在待下載人的 mod_generation_status === true 才可以選擇
        let combinationID: string[] = getValidID();
        return !combinationID.includes(option.id);
    };

    const setDefaultPDFSelect = () => {
        //身分不能下載則return
        if (!roleData.projects[SystemType].download) return;
        const validPDF: AllPdf[] = [];
        //找到可以選擇的所有套組，透過使用者mod_generation_status 去尋找
        List.forEach((User) => {
            User.mod_generation_status.forEach((pdf) => {
                const index = validPDF.findIndex((item) => item.id === pdf.id);
                if (index === -1 && pdf.status) {
                    validPDF.push(pdf);
                }
            });
        });
        if (validPDF.length !== 0) {
            setChoosePDFAll(validPDF[0]);
        } else {
            setChoosePDFAll({
                id: "",
                name: "",
            });
        }
    };

    useEffect(() => {
        setCheckAll(false);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [vChoosePDFAll]);

    useEffect(() => {
        if (List.length === 0) return;
        setDefaultPDFSelect();
        if (SystemType === vSystemType[1]) initPDFselect(List);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [List]);

    useEffect(() => {
        if (List.length === 0) return;
        setDefaultPDFSelect();
        if (SystemType === vSystemType[1]) initPDFselect(List);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [iNowPage]);

    useEffect(() => {
        if (vChoosePDFAll.id === "") return;
        //身分不能下載則return
        if (!roleData.projects[SystemType].download) return;
        //判斷all check true or false 還要加上人的 pdf mod status 數量，並非直接判斷"待下載"人數
        let countMode = 0; //計算全選欄位所選取的套組，被勾選數量
        vUserPDFselect.forEach((User) => {
            User.mod_list.forEach((item) => {
                if (item.id === vChoosePDFAll.id) {
                    countMode++;
                }
            });
        });
        let pdfModLength: number = 0; //計算在待下載，此套組status true 的數量
        vUserPDFselect.forEach((User) => {
            let UserIndex = List.findIndex((item) => item.uid === User.uid);
            if (UserIndex !== -1)
                if (List[UserIndex].mod_generation_status !== null)
                    List[UserIndex].mod_generation_status.forEach((item) => {
                        if (item.status && vChoosePDFAll.id === item.id) {
                            pdfModLength++;
                        }
                    });
        });
        //兩變數相同，則將全選勾起
        if (countMode === pdfModLength) {
            setCheckAll(true);
        } else {
            setCheckAll(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [vUserPDFselect, vChoosePDFAll]);

    useEffect(() => {
        countCol();
    }, [SystemType]);

    useEffect(() => {
        countCol();
    }, []);

    return (
        <>
            <div className="table_container">
                <table className="list_table user_list" ref={listTable}>
                    <thead>
                        <tr className="bg_blue_green">
                            {vTableColumn.map((vThContent) => {
                                // 修改資料(表頭) : 僅經銷商可修改
                                if (
                                    /*!roleData.Functions.enable_comment*/ false &&
                                    vThContent.key_name === "editUSer"
                                ) {
                                    return (
                                        <th
                                            className="noShow"
                                            key={vThContent.key_name}
                                        ></th>
                                    );
                                }
                                if (
                                    vThContent.key_name === "redoPDF" &&
                                    !bAdmin
                                ) {
                                    return (
                                        <th
                                            className="noShow"
                                            key={vThContent.key_name}
                                        ></th>
                                    );
                                }
                                return vThContent.sortable ? (
                                    <th
                                        key={vThContent.key_name}
                                        colSpan={vThContent.col_span}
                                        onClick={() =>
                                            changeSortUserListData(
                                                vThContent.key_name
                                            )
                                        }
                                        className={
                                            `sort ${vThContent.class_name}` +
                                            getSortClass(vThContent.key_name)
                                        }
                                    >
                                        {vThContent.title}
                                        <span></span>
                                    </th>
                                ) : vThContent.key_name !==
                                  "checkBox" /*說明*/ ? (
                                    <th
                                        key={vThContent.key_name}
                                        colSpan={vThContent.col_span}
                                        className={vThContent.class_name}
                                    >
                                        <span>{vThContent.title}</span>
                                    </th>
                                ) : roleData.projects[SystemType].download &&
                                  vThContent.key_name === "checkBox" ? (
                                    <th
                                        key={vThContent.key_name}
                                        colSpan={vThContent.col_span}
                                        className={`${vThContent.class_name} ${
                                            SystemType === vSystemType[1]
                                                ? ""
                                                : "check_all"
                                        }`}
                                        onClick={() =>
                                            vUserNumber.length === 0
                                                ? ""
                                                : SystemType === vSystemType[1]
                                                ? () => {}
                                                : checkAll(!bCheckAll)
                                        }
                                    >
                                        <div className="pdf-select">
                                            <div
                                                className={`${
                                                    SystemType ===
                                                    vSystemType[1]
                                                        ? "cursor"
                                                        : ""
                                                }`}
                                                onClick={() =>
                                                    SystemType ===
                                                    vSystemType[1]
                                                        ? checkAllGenePDF()
                                                        : checkAll(!bCheckAll)
                                                }
                                            >
                                                {vThContent.key_name ===
                                                    "checkBox" &&
                                                    vUserNumber.length > 0 && (
                                                        <input
                                                            type="checkbox"
                                                            checked={bCheckAll}
                                                            onChange={() =>
                                                                SystemType ===
                                                                vSystemType[1]
                                                                    ? checkAllGenePDF()
                                                                    : checkAll(
                                                                          !bCheckAll
                                                                      )
                                                            }
                                                        />
                                                    )}
                                                <span>{vThContent.title}</span>
                                            </div>
                                            {vUserNumber.length > 0 &&
                                                SystemType === vSystemType[1] &&
                                                vAllPDFMode.length > 0 && (
                                                    <>
                                                        <Autocomplete
                                                            classes={classes}
                                                            value={
                                                                vChoosePDFAll
                                                            }
                                                            getOptionDisabled={(
                                                                option
                                                            ) =>
                                                                getOptionDisabled(
                                                                    option
                                                                )
                                                            }
                                                            options={getValidOption()}
                                                            isOptionEqualToValue={(
                                                                option,
                                                                value
                                                            ) =>
                                                                option.id ===
                                                                value.id
                                                            }
                                                            getOptionLabel={(
                                                                option
                                                            ) => option.name}
                                                            sx={{
                                                                display: "flex",
                                                                width: 250,
                                                                ml: 2,
                                                            }}
                                                            onChange={
                                                                onSelectPDFModeChange
                                                            }
                                                            renderInput={(
                                                                params
                                                            ) => (
                                                                <TextField
                                                                    {...params}
                                                                    className={
                                                                        classes.inputRoot
                                                                    }
                                                                    sx={{
                                                                        mb: 2,
                                                                        zIndex: 2,
                                                                    }}
                                                                    variant="standard"
                                                                    label="PDF模組"
                                                                />
                                                            )}
                                                        />
                                                    </>
                                                )}
                                        </div>
                                    </th>
                                ) : (
                                    false
                                );
                            })}
                        </tr>
                    </thead>
                    <tbody>
                        {List.length === 0 ? (
                            //經銷商多一格修改
                            !bQueryNow ? (
                                <tr>
                                    <td colSpan={iColNumber}>尚無受檢者名單</td>
                                </tr>
                            ) : (
                                <tr>
                                    <td colSpan={iColNumber}>Loading...</td>
                                </tr>
                            )
                        ) : (
                            List.map((vUserListContext, idx) => {
                                const vStatus = getvStatus(
                                    vUserListContext.state
                                );
                                return idx < iStartItem || idx > iEndItem ? (
                                    false
                                ) : (
                                    <tr key={vUserListContext.uid}>
                                        <td
                                            className={
                                                "table_color " +
                                                vStatus?.square_class
                                            }
                                        ></td>
                                        <td
                                            className={
                                                "font_bold width-130 " +
                                                vStatus?.class_name
                                            }
                                        >
                                            {vStatus?.text}
                                        </td>
                                        <td className="for_name ">
                                            {vUserListContext.uid}
                                        </td>
                                        <td>{vUserListContext.uname}</td>
                                        <td>{vUserListContext.gender}</td>
                                        <td>{vUserListContext.date}</td>
                                        {/* <td>
                                            {vUserListContext.zip_upload_time}
                                        </td> */}
                                        <td>{vUserListContext.sign_time}</td>
                                        <td>
                                            <input
                                                type="date"
                                                className="date"
                                                value={vUserListContext.acceptance_time}
                                                onChange={(e: React.FormEvent<HTMLInputElement>) =>
                                                    updateAcceptanceTimeHandler(e, vUserListContext.uid)
                                                }
                                                min={vUserListContext.sign_time}
                                            />
                                        </td>
                                        <td>
                                            {vUserListContext.latest_pdf_time}
                                        </td>
                                        <td>
                                            {vUserListContext.latest_gf_time}
                                        </td>
                                        <td
                                            className={
                                                Object.keys(
                                                    SPECIAL_USER
                                                ).includes(vUserListContext.uid)
                                                    ? "describe"
                                                    : ""
                                            }
                                        >
                                            {showDescription(
                                                vUserListContext,
                                                idx
                                            )}
                                        </td>
                                        {!roleData.projects[SystemType]
                                            .download ? (
                                            false
                                        ) : !(
                                              vUserListContext.state ===
                                                  vUserState.not_download ||
                                              vUserListContext.state ===
                                                  vUserState.already_download ||
                                              vUserListContext.state ===
                                                  vUserState.report_finish
                                          ) ? (
                                            <td></td>
                                        ) : (
                                            <>
                                                {SystemType ===
                                                vSystemType[1] ? (
                                                    /*基因寶modePDF下載*/ <td className="file_download">
                                                        <MultipleSelectCheckMarks
                                                            allPDFFile={[
                                                                {
                                                                    name: "全選",
                                                                    id: "-1",
                                                                    status: true,
                                                                },
                                                            ].concat(
                                                                vUserListContext.mod_generation_status
                                                            )}
                                                            UID={
                                                                vUserListContext.uid
                                                            }
                                                            setUserPDFselect={
                                                                setUserPDFselect
                                                            }
                                                        />
                                                    </td>
                                                ) : (
                                                    <td
                                                        className="file_download"
                                                        onClick={() =>
                                                            clcikFileDownload(
                                                                vUserListContext.uid
                                                            )
                                                        }
                                                    >
                                                        <input
                                                            type="checkbox"
                                                            checked={
                                                                vUserId.indexOf(
                                                                    vUserListContext.uid
                                                                ) > -1
                                                            }
                                                            onChange={() =>
                                                                clcikFileDownload(
                                                                    vUserListContext.uid
                                                                )
                                                            }
                                                        />
                                                        <img
                                                            src="/img/document_gray.png"
                                                            alt="報告"
                                                        />
                                                        <span>報告下載</span>
                                                    </td>
                                                )}
                                            </>
                                        )}
                                        {/* 修改資料(按鈕) */}
                                        {
                                            /* {roleData.Functions.enable_comment*/ true ? (
                                                // 狀態 !== 分析中 / PDF處理中
                                                [
                                                    vUserState.abnormal_other,
                                                    vUserState.analysis,
                                                    vUserState.detect,
                                                    vUserState.not_download,
                                                    vUserState.already_download,
                                                    vUserState.pdf_create,
                                                    vUserState.sign_finish,
                                                    vUserState.report_finish,
                                                    vUserState.questionnaire_analysis,
                                                    vUserState.questionnaire_finish,
                                                ].indexOf(
                                                    vUserListContext.state
                                                ) !== -1 ? (
                                                    <td
                                                        className="edit_user_input"
                                                        onClick={() =>
                                                            editUser(
                                                                vUserListContext.uid
                                                            )
                                                        }
                                                    >
                                                        <img
                                                            src="/img/edit-regular.svg"
                                                            alt="edit-regular"
                                                        />
                                                    </td>
                                                ) : (
                                                    <td></td>
                                                )
                                            ) : (
                                                <></>
                                            )
                                        }
                                        {/* admin :重新產報告 PDF */}
                                        {/* {bAdmin ? (
                                                [
                                                    vUserState.not_download,
                                                    vUserState.already_download,
                                                ].indexOf(
                                                    vUserListContext.state
                                                ) > -1 ? (
                                                    <td
                                                        className="redo_pdf_input"
                                                        onClick={() =>
                                                            redoPDF([
                                                                vUserListContext.uid,
                                                            ])
                                                        }
                                                    >
                                                        <img
                                                            src="/img/redo-solid.svg"
                                                            alt="redo-solid"
                                                        />
                                                    </td>
                                                ) : (
                                                    <td></td>
                                                )
                                            ) : (
                                                <></>
                                            )} */}
                                    </tr>
                                );
                            })
                        )}
                    </tbody>
                </table>
            </div>
            <PageNumber
                iMaxPage={iMaxPage}
                iNowPage={iNowPage}
                changePage={changePageUserList}
            />
            {bDesShow && (
                <UserDesModal iUserIdx={iUserIdx} showDesModal={showDesModal} />
            )}
            {bEditShow && sUidEddit !== "" && (
                <UserEditModal sUidEddit={sUidEddit} editUser={editUser} />
            )}
            {/* show pdf select failed */}
            {bshowFail && FailMes()}
        </>
    );
};

export default UserList;
