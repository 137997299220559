import React, { useState, useContext, useEffect } from "react";
import { useParams, useLocation } from "react-router-dom";
import {
    getUserListAPI,
    getUserListOutput,
    EditInput,
    // editUserAPI,
    vUploadFile,
    ResponseCode,
    downloadReportFromUrlApi,
    redoPDFAPI,
    DataType,
    // vOrgType,
    vSystemType,
    getUserListExcelAPI,
    UserPdfMode,
    PostPdfMode,
    downloadPDFModeReportFromUrlApi,
    getAllPdfMode,
    AllPdf,
    DoctorSaveCommentAPI,
    vUserState,
    updateAcceptanceTime,
} from "../utils/GeneBoxAPI";
import UserListContext from "../Context/UserListContext";
import LoginInfoContext from "../Context/LoginInfoContext";
import DateContext from "../Context/DateContext";
import MessageContext, { vMsgProps } from "../Context/MessageContext";
import UserListFilter from "./UserListFilter";
import UserList from "./UserList";
import Message from "../component/Message";
import { ExceptUser } from "../Context/ExceptContext";

interface url {
    type: string;
}

const UserListPage = () => {
    // context
    const { type: SystemType }: url = useParams();
    const location = useLocation();
    const userOrganization = location.pathname.split("/")[2];

    const { Provider } = UserListContext;
    const vUserListContext = useContext(UserListContext);
    const {
        vUserListContextData,
        vUserListContextShowData,
        vStatusContextCheckBox,
        vCheckContextStatus,
    } = vUserListContext;
    const { showAlert, bShowAlert } = useContext(MessageContext);
    const {
        sCurrentToken,
        setbLoading,
        setCurrentToken,
        errorProcess,
        sSystem,
    } = useContext(LoginInfoContext);
    const { sEndDefault, getPastDate } = useContext(DateContext);

    // props
    const org = userOrganization;

    // state
    const [vUserListData, setUserListData] = useState(vUserListContextData); // get api data & sort data
    const [vUserListShowData, setUserListShowData] = useState(
        vUserListContextShowData
    ); // filter data by status
    const [vStatusCheckBox, setStatusCheckBox] = useState(
        vStatusContextCheckBox
    );
    const [nowSortType, setNowSortType] = useState<string>("");
    const [iSortNow, setSortNow] = useState<number>(1);
    const [iNowPage, setNowPage] = useState(1);
    const [vUserId, setUserId] = useState<string[]>([]);
    const [bQueryFirst, setQueryFirst] = useState<boolean>(false);
    const [bQueryNow, setQueryNow] = useState<boolean>(false);
    const [vCheckStatus, setCheckStatus] =
        useState<number[]>(vCheckContextStatus);
    const [MsgalertProps, setMsgAlertContent] = useState<vMsgProps>({
        title: "",
        sub_title: "",
        content: <></>,
        iMsg: 0,
        bBtnType: true,
        submitFun: () => {},
    });
    const [sStartDate, setStartDate] = useState(getPastDate(2, "month"));
    const [sEndDate, setsEndDate] = useState(sEndDefault);
    const [iDateType, setiDateType] = useState(DataType.checkDate);
    const [bCheckAll, setCheckAll] = useState(false);
    const [vUserPDFselect, setUserPDFselect] = useState<UserPdfMode[]>([]);
    const [vAllPDFMode, setAllPDFMode] = useState<AllPdf[]>([]); //all pdf mode option
    const [vChoosePDFAll, setChoosePDFAll] = useState<AllPdf>({
        id: "",
        name: "",
    });
    const [uid, setId] = useState("");
    const [uname, setName] = useState("");

    // get userList data
    const getUserListData = (
        startDate: string,
        endDate: string,
        iDateType: number,
        systemType: string, //usr_org
        supplier: string
    ) => {
        if (systemType === vSystemType[0]) return;
        // axios
        setUserListData([]);
        setUserListShowData([]);
        setQueryNow(true);
        getUserListAPI(
            startDate,
            endDate,
            null,
            sCurrentToken,
            systemType,
            supplier,
            iDateType
        )
            .then((res) => {
                let user_list = res.user_list;
                setQueryFirst(true);
                setQueryNow(false);
                setUserListData(user_list);
                setCurrentToken(res.token);
            })
            .catch((error) => {
                setQueryNow(false);
                console.error(error);
                let error_status: number;
                if (error.response !== undefined) {
                    error_status = error.response.status;
                    errorProcess(error_status, error);
                } else {
                    error_status = 0;
                }
                errorProcess(error_status, error);
            });
    };

    // change page
    const changePage = (page: number) => {
        setNowPage(page);
        setUserId([]);
    };

    // add user report
    const choseUser = (user_number: string) => {
        let vNewUserId = vUserId.slice(0);
        let iPos = vNewUserId.indexOf(user_number);

        if (iPos > -1) {
            vNewUserId.splice(iPos, 1);
        } else {
            vNewUserId.push(user_number);
        }
        setUserId(vNewUserId);
    };

    const checkSelectPDF = () => {
        let check = false;
        vUserPDFselect.forEach((item) => {
            if (item.mod_list.length !== 0) {
                check = true;
            }
        });
        return check;
    };

    const processPDFmode = (data: UserPdfMode[]) => {
        let tempData: UserPdfMode[] = [];
        let postData: PostPdfMode[] = [];
        tempData = data.filter((item) => item.mod_list.length !== 0);
        tempData.forEach((Person: UserPdfMode, idx: number) => {
            let joinPdf: string[] = [];
            Person.mod_list.forEach((pdf) => {
                joinPdf.push(pdf.id);
            });
            postData.push({
                uid: Person.uid,
                mod_list: joinPdf,
            });
        });
        return postData;
    };

    // download report
    const downloadReport = (vUserId: string[]) => {
        //if download is genebox use modepdf object else use uid
        if (SystemType === vSystemType[1] && checkSelectPDF()) {
            setbLoading(true);
            const link = document.createElement("a");
            downloadPDFModeReportFromUrlApi(
                processPDFmode(vUserPDFselect),
                sCurrentToken
            )
                .then((res) => {
                    link.href = res;
                    // link.target = "_blank";
                    link.click();
                    setbLoading(false);
                    getUserListData(
                        sStartDate,
                        sEndDate,
                        iDateType,
                        SystemType,
                        userOrganization
                    );
                    setUserId([]);
                    setCheckAll(false);
                })
                .catch((error) => {
                    failDownload(error);
                });
        } else if (SystemType !== vSystemType[1] && vUserId.length !== 0) {
            setbLoading(true);
            // gcp
            const link = document.createElement("a");
            downloadReportFromUrlApi(vUserId.join(), sCurrentToken)
                .then((res) => {
                    link.href = res;
                    // link.target = "_blank";
                    link.click();
                    setbLoading(false);
                    getUserListData(
                        sStartDate,
                        sEndDate,
                        iDateType,
                        SystemType,
                        userOrganization
                    );
                    setUserId([]);
                    setCheckAll(false);
                })
                .catch((error) => {
                    failDownload(error);
                });
        } else {
            showAlert(true);
            setMsgAlertContent({
                ...MsgalertProps,
                title: "請選擇欲下載報告",
                sub_title: "",
                iMsg: 2,
                content: <></>,
            });
        }
    };

    // fail donwload
    const failDownload = (error: any) => {
        setbLoading(false);
        setUserId([]);
        let error_status: number;
        if (error.response !== undefined) {
            error_status = error.response.status;
            errorProcess(error_status, error);
        } else {
            error_status = 0;
        }
        errorProcess(error_status, error);
    };

    // add all User
    const choseAllUser = (vUserNumber: string[], bCheck: boolean) => {
        if (bCheck) {
            setUserId(vUserNumber);
        } else {
            setUserId([]);
        }
    };

    // click status check box
    const clickStatus = (idx: number, bStatus: boolean) => {
        // check box status
        if (SystemType === vSystemType[0]) return;
        let vNewCheckBoxes = vStatusCheckBox[SystemType].slice(0);
        if (vNewCheckBoxes[idx].state[0] === -1) {
            vNewCheckBoxes = vNewCheckBoxes.map((vNewCheckBox) => {
                vNewCheckBox.check = bStatus;
                return vNewCheckBox;
            });
        } else {
            vNewCheckBoxes[idx].check = bStatus;
            // 檢查是否要勾全選
            if (bStatus) {
                let bCheck = true;
                for (const vNewCheckBox of vNewCheckBoxes) {
                    if (!vNewCheckBox.check && vNewCheckBox.state[0] !== -1) {
                        bCheck = vNewCheckBox.check;
                        break;
                    }
                }
                vNewCheckBoxes[0].check = bCheck; // 全選
            } else {
                vNewCheckBoxes[0].check = false; // 全選
            }
        }
        vStatusCheckBox[SystemType] = vNewCheckBoxes;
        getvCheckStatus();
        setStatusCheckBox(vStatusCheckBox);
        setNowPage(1);
    };

    // get check state number
    const getvCheckStatus = () => {
        let vResult: number[] = [];
        let vFilterStatus = vStatusCheckBox[SystemType].filter(
            (vStatus) => vStatus.check
        );
        for (const vStatus of vFilterStatus) {
            vResult = vResult.concat(vStatus.state);
        }
        setCheckStatus(vResult);
    };

    // user list filter
    const filterStatus = (uid: string, uname: string) => {
        setNowPage(1);
        setUserListShowData(
            vUserListData.filter((vUserList) => {
                const bId = uid === "" || vUserList.uid.indexOf(uid) > -1;
                const bUname =
                    uname === "" || vUserList.uname.indexOf(uname) > -1;
                const bState = vCheckStatus.indexOf(vUserList.state) > -1;
                return bId && bUname && bState;
            })
        );
    };

    // change sort condition
    const changeSortUserListData = (sortType: string): void => {
        let iSort = iSortNow;
        if (nowSortType !== sortType) {
            iSort = 1;
        } else {
            iSort = -1 * iSort;
        }
        sortUserDataList(sortType, iSort);
        setNowPage(1);
        setNowSortType(sortType);
        setSortNow(iSort);
    };

    // sort data
    const sortUserDataList = (sortType: string, iSort: number) => {
        setUserListShowData(
            vUserListShowData
                .sort((a: getUserListOutput, b: getUserListOutput) => {
                    return (a[sortType] > b[sortType] ? 1 : -1) * iSort;
                })
                .slice(0)
        );
    };

    // edit user
    const editUserInfo = (
        vEditInput: EditInput,
        editUser: (x: string) => void
    ) => {
        // showLoading();
        /*因與異常登錄使用同一支api，所以"1" "2"為特殊用詞 不可使用*/
        const vUserAddComment: ExceptUser[] = [
            {
                uid: vEditInput.uid,
                /*若原本狀態為異常，則維持原狀態*/
                abnormal:
                    [
                        vUserState.abnormal_detect_again,
                        vUserState.abnormal_pick_again,
                        vUserState.abnormal_other,
                    ].indexOf(vEditInput.state) !== -1,
                comment:
                    vEditInput.description === "1" ||
                    vEditInput.description === "2"
                        ? ""
                        : vEditInput.description,
            },
        ];
        if (vEditInput.description === "1" || vEditInput.description === "2") {
            setMsgAlertContent({
                ...MsgalertProps,
                title: "無效輸入",
                sub_title: "",
                iMsg: 1,
                content: <></>,
            });
            return;
        }
        DoctorSaveCommentAPI(SystemType, sCurrentToken, vUserAddComment)
            .then((res) => {
                if (res.respCode === 201 || res.respCode === 200) {
                    showAlert(true);
                    setCurrentToken(res.token);
                    showEditMessage(res, vEditInput);
                    editUser("");
                    getUserListData(
                        sStartDate,
                        sEndDate,
                        iDateType,
                        SystemType,
                        userOrganization
                    );
                }
            })
            .catch((error) => {
                let error_status: number;
                if (error.response !== undefined) {
                    error_status = error.response.status;
                    errorProcess(error_status, error);
                } else {
                    error_status = 0;
                }
                errorProcess(error_status, error);
                showAlert(true);
                setMsgAlertContent({
                    ...MsgalertProps,
                    title: "修改失敗",
                    sub_title: "",
                    iMsg: 1,
                    content: <></>,
                });
            });
        // editUserAPI([vEditInput], sCurrentToken)
        //     .then((res) => {
        //         showAlert(true);
        //         setCurrentToken(res.token);
        //         showEditMessage(res, vEditInput);
        //         editUser("");
        //         getUserListData(
        //             sStartDate,
        //             sEndDate,
        //             iDateType,
        //             SystemType,
        //             userOrganization
        //         );
        //     })
        //     .catch((error) => {
        //         console.error(error);
        //         let error_status: number;
        //         if (error.response !== undefined) {
        //             error_status = error.response.status;
        //             errorProcess(error_status, error);
        //         } else {
        //             error_status = 0;
        //         }
        //         errorProcess(error_status, error);
        //         showAlert(true);
        //         setMsgAlertContent({
        //             ...MsgalertProps,
        //             title: "修改失敗",
        //             sub_title: "",
        //             iMsg: 1,
        //             content: <></>,
        //         });
        //     });
    };

    // redo pdf
    const redoPDF = (vUserId: string[]) => {
        setbLoading(true);
        redoPDFAPI(vUserId, sCurrentToken)
            .then((res) => {
                showAlert(true);
                setCurrentToken(res.token);
                redoPDFMessage(res, vUserId[0]);
                setbLoading(false);
            })
            .catch((error) => {
                let error_status: number;
                if (error.response !== undefined) {
                    error_status = error.response.status;
                    errorProcess(error_status, error);
                } else {
                    error_status = 0;
                }
                errorProcess(error_status, error);
                setbLoading(false);
            });
    };

    //show no file
    const showErrorfileMessageAlert = (sMsg: string) => {
        showAlert(true);
        setMsgAlertContent({
            ...MsgalertProps,
            title: sMsg,
            sub_title: ``,
            iMsg: 2,
            content: <></>,
        });
    };

    // upload new user -> alert
    const showMessageAlert = (res: vUploadFile) => {
        showAlert(true);
        const list = res.error_list;
        const count = res.data_count;
        if (list.length === 0) {
            setMsgAlertContent({
                ...MsgalertProps,
                title: "上傳成功",
                sub_title: `${count}筆資料上傳成功`,
                iMsg: 0,
                content: <></>,
            });
            getUserListData(
                sStartDate,
                sEndDate,
                iDateType,
                SystemType,
                userOrganization
            );
        } else {
            const content = list.map((uid) => <li key={uid}>{uid}</li>);
            setMsgAlertContent({
                ...MsgalertProps,
                title: "上傳失敗",
                sub_title: `${count}筆資料有誤，請確認資料後重新上傳`,
                iMsg: 1,
                content: <ul className="alert_list">{content}</ul>,
            });
        }
    };

    // edit user info -> alert
    const showEditMessage = (res: ResponseCode, vEditInput: EditInput) => {
        if (
            (res.respCode === 200 || res.respCode === 201) &&
            res.respDesc === "OK"
        ) {
            setMsgAlertContent({
                ...MsgalertProps,
                title: "修改成功",
                sub_title: `受檢編號: ${vEditInput.uid}`,
                iMsg: 0,
                content: <></>,
            });
        } else {
            setMsgAlertContent({
                ...MsgalertProps,
                title: "修改失敗",
                sub_title: `受檢編號: ${vEditInput.uid}`,
                iMsg: 1,
                content: <></>,
            });
        }
    };

    // edit user info -> alert
    const redoPDFMessage = (res: ResponseCode, sUid: string) => {
        if (res.respCode === 201 && res.respDesc === "OK") {
            setMsgAlertContent({
                ...MsgalertProps,
                title: "重跑PDF成功",
                sub_title: `受檢編號: ${sUid}`,
                iMsg: 0,
                content: <></>,
            });
            getUserListData(
                sStartDate,
                sEndDate,
                iDateType,
                SystemType,
                userOrganization
            );
        } else {
            setMsgAlertContent({
                ...MsgalertProps,
                title: "重跑PDF失敗",
                sub_title: `受檢編號: ${sUid}`,
                iMsg: 1,
                content: <></>,
            });
        }
    };

    const downloadUser = (
        start_date: string,
        end_date: string,
        state: string | null,
        date_type: number
    ) => {
        getUserListExcelAPI(
            start_date,
            end_date,
            state,
            sCurrentToken,
            SystemType,
            userOrganization,
            date_type
        )
            .then((res) => {
                const blob = new Blob([res]);
                const link = document.createElement("a");
                link.href = window.URL.createObjectURL(blob);
                link.download = `${start_date}-${end_date}-${userOrganization}.xlsx`;
                link.click();
            })
            .catch((error) => {
                console.error(error);
                let error_status: number;
                if (error.response !== undefined) {
                    error_status = error.response.status;
                    if (error_status === 428) {
                        showAlert(true);
                        setMsgAlertContent({
                            ...MsgalertProps,
                            title: "下載失敗",
                            sub_title: "Specimen List is empty.",
                            iMsg: 1,
                            content: <></>,
                        });
                    } else {
                        errorProcess(error_status, error);
                    }
                } else {
                    error_status = 0;
                    errorProcess(error_status, error);
                }
                setbLoading(false);
            });
    };

    const AllPdfMode = () => {
        getAllPdfMode(sCurrentToken)
            .then((res) => {
                setAllPDFMode(res.default);
                let defaultChoosePDF = res.default.filter(
                    (item) => item.id === "new"
                )[0];
                if (defaultChoosePDF !== undefined) {
                    setChoosePDFAll(defaultChoosePDF);
                } else {
                    setChoosePDFAll({
                        id: "",
                        name: "",
                    });
                }

                setCurrentToken(res.token);
            })
            .catch((error) => {
                console.error(error);
                let error_status: number;
                if (error.response !== undefined) {
                    error_status = error.response.status;
                    errorProcess(error_status, error);
                } else {
                    error_status = 0;
                }
                errorProcess(error_status, error);
                setbLoading(false);
            });
    };

    const updateAcceptanceTimeHandler = async (
        e: React.FormEvent<HTMLInputElement>,
        userId: string
    ) => {
        await updateAcceptanceTime({
            token: sCurrentToken,
            userId,
            acceptanceTime: e.currentTarget.value.replaceAll("-", ""),
        })
            .then(() => {
                setMsgAlertContent({
                    ...MsgalertProps,
                    title: "更新驗收完成日期成功",
                    sub_title: `使用者 ID: ${userId}`,
                    iMsg: 0,
                    content: <></>,
                });
                getUserListData(
                    sStartDate,
                    sEndDate,
                    iDateType,
                    SystemType,
                    userOrganization
                );
            })
            .catch(() => {
                setMsgAlertContent({
                    ...MsgalertProps,
                    title: "更新驗收完成日期失敗",
                    sub_title: `使用者 ID: ${userId}`,
                    iMsg: 1,
                    content: <></>,
                });
            });
    };

    useEffect(() => {
        AllPdfMode();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        //get data filter first
        filterStatus(uid, uname);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [vUserListData]);

    useEffect(() => {
        setQueryFirst(false);
        setUserPDFselect([]);
    }, [SystemType]);

    useEffect(() => {
        if (!bQueryNow && !bQueryFirst && SystemType !== vSystemType[0]) {
            getUserListData(
                sStartDate,
                sEndDate,
                iDateType,
                vSystemType[sSystem.current],
                userOrganization
            );
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [bQueryFirst]);

    // new context
    const vNewUserListContext = {
        ...vUserListContext,
        vUserListContextData: vUserListData,
        vUserListContextShowData: vUserListShowData,
        iSortNow,
        nowSortType,
        iNowPage,
        vStatusContextCheckBox: vStatusCheckBox,
        vUserId,
        vCheckContextStatus: vCheckStatus,
        org,
        bQueryFirst,
        bQueryNow,
        sStartDate,
        sEndDate,
        iDateType,
        bCheckAll,
        vUserPDFselect,
        setiDateType,
        setStartDate,
        setsEndDate,
        clickStatus,
        changeSortUserListData,
        changePage,
        getUserListData,
        choseUser,
        choseAllUser,
        filterStatus,
        editUserInfo,
        showMessageAlert,
        showErrorfileMessageAlert,
        downloadReport,
        downloadUser,
        redoPDF,
        setCheckAll,
        updateAcceptanceTimeHandler,
    };

    return (
        <Provider value={vNewUserListContext}>
            <UserListFilter
                uid={uid}
                setId={setId}
                uname={uname}
                setName={setName}
                SystemType={SystemType}
                organize={userOrganization}
            />
            <UserList
                SystemType={SystemType}
                List={vUserListShowData}
                vUserPDFselect={vUserPDFselect}
                vAllPDFMode={vAllPDFMode}
                vChoosePDFAll={vChoosePDFAll}
                setUserPDFselect={setUserPDFselect}
                setChoosePDFAll={setChoosePDFAll}
            />
            {bShowAlert && <Message {...MsgalertProps} />}
        </Provider>
    );
};

export default UserListPage;
