import React, { useState, useContext, useEffect } from "react";
import DateContext from "../Context/DateContext";
import { getReportListAPI, ReportListOutput } from "../utils/GeneBoxAPI";
import LoginInfoContext from "../Context/LoginInfoContext";
import ModalAlert from "../component/Modal";
import { vModalgProps } from "../Context/ModalContext";
import { useParams } from "react-router-dom";
interface url {
    type: string;
}
const UploadRecord = () => {
    const { type: SystemType }: url = useParams();
    const { sEndDefault, getPastDate, sYearBefore, sGeneBoxStart } =
        useContext(DateContext);
    // 預設的開始時間
    const sStart = getPastDate(3, "date");
    const sWeekStart = getPastDate(7, "date");
    const sMonthStart = getPastDate(1, "month");

    const [sStartDate, setStartDate] = useState(sStart);
    const [sEndDate, setEndDate] = useState(sEndDefault);
    const { sCurrentToken, setCurrentToken, setbLoading, errorProcess } =
        useContext(LoginInfoContext);
    const [ReportList, setReportList] = useState<ReportListOutput[]>();

    //Modal
    const [MdalertProps, setMdAlertContent] = useState<vModalgProps>({
        Md_Modal_title: <></>,
        Mdtitle: "",
        Mdcontent: <></>,
        Mdtitle_bgcolor: "",
        MdBtnType: 0,
        MdsubmitFun: () => {},
        MdcloseFun: () => {},
    });

    const [bMdShowAlert_Rplist, setMdshowAlert_Rplist] = useState(false);

    // start date
    const getStartDate = (event: React.FormEvent<HTMLInputElement>) => {
        const date = event.currentTarget.value;
        setStartDate(date);
    };

    // end date
    const getEndDate = (event: React.FormEvent<HTMLInputElement>) => {
        const date = event.currentTarget.value;
        setEndDate(date);
    };

    // sample date
    const setSampleDate = (sSampleStart: string) => {
        setEndDate(sEndDefault);
        setStartDate(sSampleStart);
    };

    const searchRecord = () => {
        // axios
        getReportList(
            sStartDate.replaceAll("-", "/"),
            sEndDate.replaceAll("-", "/")
        );
    };

    const getReportList = (sStartDate: string, sEndDate: string) => {
        if (sCurrentToken !== "") {
            setbLoading(true);
            getReportListAPI(sStartDate, sEndDate, sCurrentToken, SystemType)
                .then((res) => {
                    setReportList(res.file_list);
                    setCurrentToken(res.token);
                    setbLoading(false);
                })
                .catch((error) => {
                    setbLoading(false);
                    console.error(error);
                    let error_status: number;
                    if (error.response !== undefined) {
                        error_status = error.response.status;
                        errorProcess(error_status, error);
                    } else {
                        error_status = 0;
                    }
                    errorProcess(error_status, error);
                });
        }
    };

    // report dialog title
    const set_titile = () => {
        return (
            <>
                <div>上傳紀錄</div>
                <div>
                    {sStartDate.replaceAll("-", "/")}
                    <span>-</span>
                    {sEndDate.replaceAll("-", "/")}
                </div>
            </>
        );
    };

    const return_Modal_content = () => {
        return (
            <>
                <table
                    className="dialog_list_table report_list"
                    cellPadding="5"
                >
                    <thead className="bg_blue_green">
                        <tr className="text_white">
                            <th>檔名</th>
                            <th>日期</th>
                        </tr>
                    </thead>
                    <tbody>
                        {ReportList !== undefined ? (
                            ReportList.length !== 0 ? (
                                ReportList.map((geneItem, idx) => {
                                    return (
                                        <tr key={idx}>
                                            <td>{geneItem.file_name}</td>
                                            <td>{geneItem.date}</td>
                                        </tr>
                                    );
                                })
                            ) : (
                                <tr>
                                    <td colSpan={2}>無上傳紀錄</td>
                                </tr>
                            )
                        ) : null}
                    </tbody>
                </table>
            </>
        );
    };

    const close_modal = () => {
        setMdshowAlert_Rplist(false);
    };

    const showReportList = () => {
        if (ReportList !== undefined) {
            setMdshowAlert_Rplist(true);
            setMdAlertContent({
                ...MdalertProps,
                Md_Modal_title: set_titile(),
                Mdcontent: return_Modal_content(),
                Mdtitle_bgcolor: "bg_blue_green",
                MdBtnType: 0,
                MdcloseFun: close_modal,
            });
        }
    };

    useEffect(showReportList, [ReportList]);

    return (
        <>
            <div>
                <div className="tab_content_title">查詢上傳歷史紀錄</div>
                <div className="search_list search_record">
                    <p className="search_list_bottom">時間區隔 /</p>
                    <div className="search_record_filter search_list_bottom">
                        <input
                            type="date"
                            onChange={getStartDate}
                            min={sGeneBoxStart}
                            max={sEndDefault}
                            value={sStartDate}
                        />
                        <span>-</span>
                        <input
                            type="date"
                            onChange={getEndDate}
                            min={sGeneBoxStart}
                            max={sEndDefault}
                            value={sEndDate}
                        />
                        <button
                            className={
                                sStartDate > sEndDate
                                    ? "button_style button_disable_color"
                                    : "button_style button_yellow"
                            }
                            onClick={searchRecord}
                            id="search_button"
                            disabled={sStartDate > sEndDate ? true : false}
                        >
                            搜尋
                        </button>
                        {sStartDate > sEndDate ? (
                            <div className="hint text_red">
                                ***結束日期需大於開始日期***
                            </div>
                        ) : (
                            ""
                        )}
                    </div>
                    <div className="search_record_sample">
                        <button
                            className="button_style button_green"
                            onClick={() => {
                                setSampleDate(sWeekStart);
                            }}
                        >
                            近一個星期紀錄
                        </button>
                        <button
                            className="button_style button_green"
                            onClick={() => {
                                setSampleDate(sMonthStart);
                            }}
                        >
                            近一個月紀錄
                        </button>
                        <button
                            className="button_style button_green"
                            onClick={() => {
                                setSampleDate(sYearBefore);
                            }}
                        >
                            近一年記錄
                        </button>
                    </div>
                </div>
            </div>
            {bMdShowAlert_Rplist && <ModalAlert {...MdalertProps} />}
        </>
    );
};

export default UploadRecord;
