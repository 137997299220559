import { createContext } from "react";

const MessageContext = createContext({
    bShowAlert: false,
    showAlert: (bAlert: boolean): void => {},
});

export interface vMsgProps {
    title: string;
    sub_title: string;
    content: JSX.Element;
    iMsg: number;
    bBtnType: boolean;
    submitFun: () => void;
}

export const { Provider, Consumer } = MessageContext;

export default MessageContext;
