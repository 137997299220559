import React, { useState, useContext, useEffect } from "react";
import { useParams } from "react-router-dom";
import {
    getUserListAPI,
    getUserListOutput,
    vUserState,
} from "../utils/GeneBoxAPI";
import DateContext from "../Context/DateContext";
import LoginInfoContext from "../Context/LoginInfoContext";

import CheckExceptFilter from "../CheckExcept/CheckExceptFilter";
import CheckExcept from "../CheckExcept/CheckExcept";
import ExceptContext from "../Context/ExceptContext";
import Message from "../component/Message";
import MessageContext, { vMsgProps } from "../Context/MessageContext";
import { DoctorSaveCommentAPI } from "../utils/GeneBoxAPI";

interface url {
    type: string;
}
const CheckExceptPage = () => {
    const { type: SystemType }: url = useParams();

    // context
    const { Provider } = ExceptContext;
    const vExceptContext = useContext(ExceptContext);
    const { vExceptContextData, vTableColumn, vExceptContentUser } =
        vExceptContext;
    const { sEndDefault, getPastDate } = useContext(DateContext);
    const { sCurrentToken, setCurrentToken, errorProcess } =
        useContext(LoginInfoContext);

    const [bQueryNow, setQueryNow] = useState<boolean>(false);
    const [bQueryFirst, setQueryFirst] = useState<boolean>(false);

    // state
    const [vExceptData, setExceptData] =
        useState<getUserListOutput[]>(vExceptContextData);
    const [vExceptShowData, setExceptShowData] =
        useState<getUserListOutput[]>(vExceptContextData);
    const [nowSortType, setNowSortType] = useState(vTableColumn[5].key_name);
    const [iSortNow, setSortNow] = useState(1);
    const [iNowPage, setNowPage] = useState(1);
    const [vExceptUser, setExceptUser] = useState(vExceptContentUser); // 儲存準備登錄異常名單
    const [sStartDate, setStartDate] = useState(getPastDate(2, "month")); //start date

    //filter state
    const [id, setId] = useState("");
    const [org, setOrg] = useState("");
    const [name, setName] = useState("");

    //message
    const { showAlert, bShowAlert } = useContext(MessageContext);
    const [MsgalertProps, setMsgAlertContent] = useState<vMsgProps>({
        title: "",
        sub_title: "",
        content: <></>,
        iMsg: 0,
        bBtnType: true,
        submitFun: () => {},
    });

    // get User List
    const getExceptData = (startDate: string, endDate: string) => {
        // axios
        const sState = `${vUserState.detect},${vUserState.abnormal_detect_again}`;
        setQueryNow(true);
        getUserListAPI(
            startDate,
            endDate,
            sState,
            sCurrentToken,
            SystemType,
            "all_supplier" //不分單位
        )
            .then((res) => {
                setExceptData(res.user_list);
                setExceptShowData(res.user_list);
                setCurrentToken(res.token);
                setQueryNow(false);
                setQueryFirst(true);
            })
            .catch((error) => {
                setQueryNow(false);
                console.error(error);
                let error_status: number;
                if (error.response !== undefined) {
                    error_status = error.response.status;
                    errorProcess(error_status, error);
                } else {
                    error_status = 0;
                }
                errorProcess(error_status, error);
            });
    };

    // change page
    const changePage = (page: number) => {
        setNowPage(page);
    };

    // revise Except User List
    const addExceptUser = (
        uid: string,
        state: string,
        comment: string,
        abnormal?: boolean /*abnormal 用來判斷在登錄異常為其它時，是否有勾選要改為異常狀態*/
    ): void => {
        let vNewExceptUser = vExceptUser.slice(0);
        const vUidExcept = vExceptUser.map((vUser) => {
            return vUser.uid;
        });
        const iUidIdx = vUidExcept.indexOf(uid);
        const nstate = parseInt(state);
        if (nstate === -1) {
            // 換頁reset
            vNewExceptUser = [];
        } else if (nstate === 0 && iUidIdx === -1) {
            // 欲登錄無異常 & 不存在於名單中
            return;
        } else if (nstate === 0 && iUidIdx > -1) {
            // 欲登錄無異常 & 存在於名單中
            vNewExceptUser = vNewExceptUser.filter((vUser) => {
                return vUser.uid !== uid;
            });
        } else if (nstate > 0 && iUidIdx === -1) {
            // 欲登錄異常 & 不存在於名單中
            // if (comment === "請重新採檢") {
            //     comment = "1";
            // } else if (comment === "重新晶片檢測中") {
            //     comment = "2";
            // }
            if (abnormal !== undefined) {
                vNewExceptUser.push({ uid, comment, abnormal });
            } else {
                vNewExceptUser.push({ uid, comment });
            }
        } else if (nstate > 0 && iUidIdx > -1) {
            // 欲登錄異常 & 存在於名單中
            // if (comment === "請重新採檢") {
            //     comment = "1";
            // } else if (comment === "重新晶片檢測中") {
            //     comment = "2";
            // }
            if (abnormal !== undefined) {
                vNewExceptUser[iUidIdx] = { uid, comment, abnormal };
            } else {
                vNewExceptUser[iUidIdx] = { uid, comment };
            }
        }
        setExceptUser(vNewExceptUser);
    };

    //axios
    const postUserComment = () => {
        if (vExceptUser.length === 0) {
            showAlert(true);
            setMsgAlertContent({
                ...MsgalertProps,
                title: "尚未填寫異常",
                sub_title: "",
                content: <></>,
                iMsg: 2,
            });
        } else {
            DoctorSaveCommentAPI(SystemType, sCurrentToken, vExceptUser)
                .then((res) => {
                    if (res.respCode === 201 || res.respCode === 200) {
                        setCurrentToken(res.token);
                        showAlert(true);
                        setExceptUser([]);
                        setMsgAlertContent({
                            ...MsgalertProps,
                            title: "登錄異常成功",
                            sub_title: "",
                            content: <></>,
                            iMsg: 0,
                        });
                        getExceptData(sStartDate, sEndDefault);
                    } else {
                        showAlert(true);
                        setMsgAlertContent({
                            ...MsgalertProps,
                            title: "登錄異常失敗",
                            sub_title: "",
                            content: <></>,
                            iMsg: 1,
                        });
                        getExceptData(sStartDate, sEndDefault);
                    }
                })
                .catch((error) => {
                    console.error(error);
                    let error_status: number;
                    if (error.response !== undefined) {
                        error_status = error.response.status;
                        errorProcess(error_status, error);
                    } else {
                        error_status = 0;
                    }
                    errorProcess(error_status, error);
                });
        }
    };

    // sort ExceptData Table
    const sortExceptData = (
        sortType: string,
        event: React.MouseEvent<HTMLTableHeaderCellElement>
    ): void => {
        let iSort = iSortNow;
        if (nowSortType !== sortType) {
            iSort = 1;
            setNowSortType(sortType);
        } else {
            iSort = -1 * iSort;
        }
        setExceptShowData(
            vExceptShowData
                .sort((a: getUserListOutput, b: getUserListOutput) => {
                    return (a[sortType] > b[sortType] ? 1 : -1) * iSort;
                })
                .slice(0)
        );
        setSortNow(iSort);
    };

    // filter by frontend
    const filterData = (id: string, org_name: string, uname: string) => {
        setExceptShowData(
            vExceptData.filter((data) => {
                const bId = id === "" || data.uid.indexOf(id) > -1;
                //收尋uid前兩碼
                const bOrgName = data.uid.indexOf(org_name) > -1;
                const bUname = uname === "" || data.uname.indexOf(uname) > -1;
                return bId && bOrgName && bUname;
            })
        );
    };

    useEffect(() => {
        //get data filter first
        filterData(id, org, name);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [vExceptData]);

    // Context
    const vNewExceptContext = {
        ...vExceptContext,
        vExceptContextData: vExceptData,
        vExceptShowContextData: vExceptShowData,
        iSortNow,
        nowSortType,
        iNowPage,
        vExceptContentUser: vExceptUser,
        bQueryNow,
        bQueryFirst,
        addExceptUser,
        sortExceptData,
        changePage,
        filterData,
        getExceptData,
        postUserComment,
        setQueryFirst,
    };

    return (
        <Provider value={vNewExceptContext}>
            {
                <>
                    <div className="hint_design">
                        <div className="tab_content_title">檢測中名單</div>
                        <div className="hint">
                            <span className="text_grey">*僅</span>
                            <span className="text_orange">檢測中</span>
                            <span className="text_grey">
                                的受檢者可更改狀態為異常
                            </span>
                        </div>
                    </div>
                    <CheckExceptFilter
                        id={id}
                        org={org}
                        name={name}
                        setId={setId}
                        setOrg={setOrg}
                        setName={setName}
                        sStartDate={sStartDate}
                        setStartDate={setStartDate}
                    />
                    <CheckExcept SystemType={SystemType} />
                </>
            }
            {bShowAlert && <Message {...MsgalertProps} />}
        </Provider>
    );
};

export default CheckExceptPage;
