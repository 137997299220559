import React, { useState, useContext, useEffect } from "react";
import { /*vOrgType,*/ vSystemType } from "../utils/GeneBoxAPI";
import LoginInfoContext from "../Context/LoginInfoContext";
import UserListContext from "../Context/UserListContext";
import DateContext from "../Context/DateContext";
import { DataType } from "../utils/GeneBoxAPI";
import UserAddModal from "./UserAddModal";
import UserExportModal from "./UserExportModal";
// FIXME: useLocation is unused
// import { useLocation } from "react-router-dom";

interface UserPage {
    SystemType: string;
    organize: string;
    uid: string;
    uname: string;
    setId: React.Dispatch<React.SetStateAction<string>>;
    setName: React.Dispatch<React.SetStateAction<string>>;
}

const UserListFilter = (props: UserPage) => {
    const { SystemType, organize, uid, uname, setId, setName } = props;
    // const location = useLocation();

    // context
    const {
        org,
        bQueryFirst,
        bQueryNow,
        vStatusContextCheckBox,
        vCheckContextStatus,
        sStartDate,
        sEndDate,
        vUserId,
        iDateType,
        setiDateType,
        downloadReport,
        // downloadUser,
        setStartDate,
        setsEndDate,
        clickStatus,
        getUserListData,
        filterStatus,
    } = useContext(UserListContext);
    const { sEndDefault, getPastDate, sGeneBoxStart } = useContext(DateContext);

    const { sOrgIdx, roleData, setbLoading } = useContext(LoginInfoContext);

    // state

    const [timeoutID, setTimeoutID] = useState(0);
    const [bAddShow, setAddShow] = useState(false);
    const [bExportShow, setExportShow] = useState(false);

    // filter
    const getId = (event: React.FormEvent<HTMLInputElement>) => {
        const newUid = event.currentTarget.value;
        setId(newUid);
    };

    const getName = (event: React.FormEvent<HTMLInputElement>) => {
        const nuwUname = event.currentTarget.value;
        setName(nuwUname);
    };

    useEffect(() => {
        filterStatus(uid, uname);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [uid, uname, vCheckContextStatus.length]);

    // axios
    const getStartDate = (event: React.FormEvent<HTMLInputElement>) => {
        const date = event.currentTarget.value;
        setStartDate(date);
    };

    const getEndDate = (event: React.FormEvent<HTMLInputElement>) => {
        const date = event.currentTarget.value;
        setsEndDate(date);
    };

    // 時間區間
    useEffect(() => {
        if (!bQueryNow && bQueryFirst) {
            // new timeout
            setTimeoutID(
                window.setTimeout(() => {
                    getUserListData(
                        sStartDate,
                        sEndDate,
                        iDateType,
                        SystemType,
                        organize
                    );
                }, 2000)
            );
        }
        return window.clearTimeout(timeoutID); // reset
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [sStartDate, sEndDate]);

    // 經銷商
    useEffect(() => {
        clickStatus(0, true);
        setStartDate(getPastDate(2, "month"));
        setsEndDate(sEndDefault);
        setName("");
        setId("");
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [org, sOrgIdx]);

    useEffect(() => {
        setbLoading(bQueryNow);
    }, [bQueryNow, setbLoading]);

    // close add user list modal
    const closeUserAdd = () => {
        setAddShow(false);
    };

    // close export user list modal
    const closeUserExport = () => {
        setExportShow(false);
    };

    return SystemType !== vSystemType[0] ? (
        <>
            <div className="tab_content_title">受檢者清單</div>
            <div className="select_state">
                <p className="search_list_bottom">選擇狀態 /</p>
                <div>
                    {vStatusContextCheckBox[SystemType].map(
                        (vCheckBox, idx) => {
                            return (
                                <label
                                    className={vCheckBox.class_name}
                                    key={vCheckBox.text}
                                >
                                    <input
                                        type="checkbox"
                                        checked={vCheckBox.check}
                                        onChange={() =>
                                            clickStatus(idx, !vCheckBox.check)
                                        }
                                    />
                                    {vCheckBox.text}
                                </label>
                            );
                        }
                    )}
                </div>
            </div>
            <div className="search_list">
                <p className="search_list_bottom">篩選名單 /</p>
                <div className="search_list_content">
                    <div className="search_input">
                        <label>
                            <span>受檢編號</span>
                            <input type="text" value={uid} onChange={getId} />
                        </label>
                        <label>
                            <span>姓名</span>
                            <input
                                type="text"
                                value={uname}
                                onChange={getName}
                            />
                        </label>
                        <label className="date_block">
                            <div className="date_type">
                                <span
                                    className={
                                        iDateType === DataType.checkDate
                                            ? "active"
                                            : "unactive"
                                    }
                                    onClick={() =>
                                        setiDateType(DataType.checkDate)
                                    }
                                >
                                    送檢日期
                                </span>
                                <span
                                    className={
                                        iDateType === DataType.downloadDate
                                            ? "active"
                                            : "unactive"
                                    }
                                    onClick={() =>
                                        setiDateType(DataType.downloadDate)
                                    }
                                >
                                    交付報告日期
                                </span>
                            </div>
                            <input
                                type="date"
                                className="date"
                                value={sStartDate}
                                onChange={getStartDate}
                                min={sGeneBoxStart}
                                max={sEndDefault}
                            />
                            <span className="date_dash">-</span>
                            <input
                                type="date"
                                className="date"
                                value={sEndDate}
                                onChange={getEndDate}
                                min={sStartDate}
                                max={sEndDefault}
                            />
                        </label>
                    </div>
                    <div className="search_btn">
                        {roleData.Functions.excel_upload_specimen && (
                            <>
                                <button
                                    className="button_style button_yellow"
                                    onClick={() => setAddShow(true)}
                                >
                                    新增受試者清單
                                </button>
                                <span className="vertical_line text_grey">
                                    |
                                </span>
                            </>
                        )}
                        {roleData.Functions.export_worklist && (
                            // <button
                            //     className="button_style button_green button_right"
                            //     onClick={() =>
                            //         downloadUser(sStartDate, sEndDate)
                            //     }
                            // >
                            //     匯出清單
                            // </button>
                            <button
                                className="button_style button_green button_right"
                                onClick={() => setExportShow(true)}
                            >
                                匯出清單
                            </button>
                        )}
                        {roleData.projects[SystemType].download && (
                            <button
                                className="button_style button_green button_right"
                                onClick={() => downloadReport(vUserId)}
                            >
                                下載勾選檔案
                            </button>
                        )}
                    </div>
                </div>
            </div>
            {bAddShow && <UserAddModal closeUserAdd={closeUserAdd} />}
            {bExportShow && (
                <UserExportModal
                    SystemType={SystemType}
                    closeUserExport={closeUserExport}
                />
            )}
        </>
    ) : (
        <></>
    );
};

export default UserListFilter;
