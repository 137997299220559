import React, { useContext, useEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import PageNumber from "../component/PageNumber";
import DateContext from "../Context/DateContext";
import LoginInfoContext from "../Context/LoginInfoContext";
import { Provider } from "../Context/ReportListContext";
import { vListKey } from "../Context/SignListContext";
import {
    getUserListAPI,
    vSignListOutput,
    vUserState,
} from "../utils/GeneBoxAPI";
import Filter from "./Filter";
import List from "./List";

interface url {
    type: string;
}

const ReportList = () => {
    const { type: SystemType }: url = useParams();
    const location = useLocation();
    const userOrganization = location.pathname.split("/")[2];

    const { sCurrentToken, errorProcess, setbLoading } =
        useContext(LoginInfoContext);
    const { sStartDefault, sEndDefault } = useContext(DateContext);

    const [bQueryNow, setbQueryNow] = useState<boolean>(false);
    const [bQueryFirst, setQueryFirst] = useState(false);
    const [sStartDate, setsStartDate] = useState<string>(sStartDefault);
    const [sEndDate, setsEndDate] = useState<string>(sEndDefault);
    const [iTimeCode, setTimeCode] = useState<number>(0);
    const [vReportList, setvReportList] = useState<vSignListOutput[]>([]);
    const [vReportIndex, setReportIndex] = useState<number[]>([]);
    const [iNowPage, setiNowPage] = useState<number>(1);
    const [iSortNow, setSortNow] = useState<number>(1);
    const [nowSortType, setNowSortType] = useState<string>("uid");

    //filter data
    const [uid, setsUid] = useState<string>("");
    const [uname, setsName] = useState<string>("");

    const iItemInPage = 10;
    const iMaxPage = Math.ceil(vReportIndex.length / iItemInPage);
    const iStartItem = (iNowPage - 1) * iItemInPage;
    const iEndItem = iNowPage * iItemInPage - 1;

    // api
    const getReportList = (
        start_date: string = sStartDefault,
        end_date: string = sEndDefault
    ) => {
        const sState = `${vUserState.not_download},${vUserState.already_download}`;
        setbQueryNow(true);
        getUserListAPI(
            start_date,
            end_date,
            sState,
            sCurrentToken,
            SystemType,
            "AP_" + userOrganization
        )
            .then((res) => {
                setbQueryNow(false);
                setvReportList(res.user_list);
            })
            .catch((error) => {
                console.error(error);
                let error_status: number;
                if (error.response !== undefined) {
                    error_status = error.response.status;
                    errorProcess(error_status, error);
                } else {
                    error_status = 0;
                }
                errorProcess(error_status, error);
                setbLoading(false);
            });
    };

    // get vReportIndex from vReportList
    const getReportIndex = (): number[] => {
        if (vReportList.length === 0) return [];
        return Object.keys(vReportList).map((idx) => parseInt(idx));
    };

    // get init vReportIndex
    const getInitReportIdx = () => {
        const vNewReportIndex = getReportIndex();
        setReportIndex(vNewReportIndex);
    };

    // sort data
    const sortSignData = (
        sort_type: string,
        e: React.MouseEvent<HTMLTableHeaderCellElement> | null = null,
        report_index: number[] = vReportIndex,
        toggle_sort: boolean = true
    ): void => {
        let iSort = iSortNow;
        if (toggle_sort) {
            if (nowSortType !== sort_type) {
                iSort = 1;
                setNowSortType(sort_type);
            } else {
                iSort = -1 * iSort;
            }
        }

        const vNewReportIndex = report_index.sort((a: number, b: number) => {
            return (
                (vReportList[a][sort_type] > vReportList[b][sort_type]
                    ? 1
                    : -1) * iSort
            );
        });
        setReportIndex(vNewReportIndex);
        setSortNow(iSort);
    };

    // filter data
    const filterData = (filter_type: string, filter_text: string) => {
        const vReportIndex = getReportIndex();
        const vNewReportIndex = vReportIndex.filter((idx: number) => {
            return vReportList[idx][filter_type].includes(filter_text);
        });
        const toggle_sort = false;
        sortSignData(nowSortType, null, vNewReportIndex, toggle_sort);
    };

    // init get data
    useEffect(() => {
        if (sCurrentToken !== "" && !bQueryFirst) {
            getReportList();
            setQueryFirst(true);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [sCurrentToken]);

    // get vReportIndex after get data
    useEffect(() => {
        getInitReportIdx();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [vReportList]);

    // date input listeners: get data after change end_date & start_date
    useEffect(() => {
        if (!bQueryFirst) return;

        const iTime = 3000;
        const iNewTimeCode = window.setTimeout(() => {
            getReportList(sStartDate, sEndDate);
        }, iTime);
        setTimeCode(iNewTimeCode);

        return () => {
            window.clearTimeout(iTimeCode);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [sStartDate, sEndDate]);

    useEffect(() => {
        if (uid !== "") {
            filterData(vListKey[0], uid);
        }
        if (uname !== "") {
            filterData(vListKey[1], uname);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [vReportList]);

    const ProviderValue = {
        sStartDate,
        sEndDate,
        setsStartDate,
        setsEndDate,
        vReportList,
        setvReportList,
        vReportIndex,
        iStartItem,
        iEndItem,
        bQueryNow,
        bQueryFirst,
        iSortNow,
        nowSortType,
        sortSignData,
        filterData,
    };

    return (
        <>
            <Provider value={ProviderValue}>
                <div className="tab_content_title">受檢者清單</div>
                <Filter
                    uid={uid}
                    uname={uname}
                    setsUid={setsUid}
                    setsName={setsName}
                />
                <List />
                <PageNumber
                    iMaxPage={iMaxPage}
                    iNowPage={iNowPage}
                    changePage={setiNowPage}
                />
            </Provider>
        </>
    );
};

export default ReportList;
