import CheckExceptPage from "../CheckExcept/CheckExceptPage";
import Login from "../Login";
import Report from "../Report/Report";
import SignListPage from "../SignList/SignListPage";
import UploadPage from "../UploadFile/UploadPage";
import UserListPage from "../UserList/UserListPage";
import ReportList from "../ReportList";
import { role } from "../utils/GeneBoxAPI";
export interface RouteType {
    path: string;
    component: () => JSX.Element;
    exact: boolean;
    breadcrumbName: string;
    text: string;
    bTab: boolean;
    authorize: string[];
}

export const newRouteInfo = () => {
    const routeInfoData: RouteType[] = [
        {
            path: "/",
            component: Login,
            exact: true,
            breadcrumbName: "login",
            text: "Login",
            bTab: false,
            authorize: [],
        },
        {
            path: "/upload/:type",
            component: UploadPage,
            exact: true,
            breadcrumbName: "upload",
            text: "上傳檔案",
            bTab: true,
            authorize: [],
        },
        {
            path: "/except/:type",
            component: CheckExceptPage,
            exact: true,
            breadcrumbName: "except",
            text: "登錄異常名單",
            bTab: true,
            authorize: [],
        },
        {
            path: "/doctor_sign/:type",
            component: SignListPage,
            exact: true,
            breadcrumbName: "doctor_sign",
            text: "醫生簽名系統",
            bTab: true,
            authorize: [],
        },
        {
            path: "/pmc_sign/:type",
            component: SignListPage,
            exact: true,
            breadcrumbName: "pmc_sign",
            text: "中心簽名系統",
            bTab: true,
            authorize: [],
        },
        {
            path: "/report/:from/:uid",
            component: Report,
            exact: true,
            breadcrumbName: "report",
            text: "",
            bTab: false,
            authorize: [],
        },
    ];

    return routeInfoData;
};

// 從 API 獲取全身分資料後建立 Report UserList 頁面
export function addUserList(roleData: role) {
    let vDealerRouter: RouteType[] = [];

    let authorityWorklist: any[] = [];
    let authorityAppointed: any[] = [];

    const ProjectKey = Object.keys(roleData.projects);
    const vDealer = Object.keys(roleData.projects.genebox.vendor);

    for (const vendor of vDealer) {
        let authority_appointed: string[] = [];
        let authority_worklist: string[] = [];
        for (const project of ProjectKey) {
            if (!roleData.projects[project].vendor[vendor])
                continue
            if (roleData.projects[project].vendor[vendor].worklist) {
                authority_worklist.push(project);
            }
            if (roleData.projects[project].vendor[vendor].appointed) {
                authority_appointed.push(project);
            }
        }
        authorityWorklist.push(authority_worklist);
        authorityAppointed.push(authority_appointed);
    }

    for (const index in vDealer) {
        if (authorityWorklist[index].length !== 0) {
            vDealerRouter.push(
                addUserListItem(vDealer[index], true, authorityWorklist[index])
            );
        }
        if (authorityAppointed[index].length !== 0) {
            vDealerRouter.push(
                addReportList(vDealer[index], true, authorityAppointed[index])
            );
        }
    }
    return vDealerRouter;
}

// router form
function buildRouterForm(
    path: string,
    component: () => JSX.Element,
    exact: boolean,
    breadcrumbName: string,
    text: string,
    bTab: boolean,
    authorize: string[]
) {
    return {
        path,
        component,
        exact,
        breadcrumbName,
        text,
        bTab,
        authorize,
    };
}

// 建立 report_list 頁面 (特約醫生)
function addReportList(sDealName: string, bTab: boolean, author: string[]) {
    const sLDealName = sDealName.toLowerCase();

    return buildRouterForm(
        `/report_list/${sLDealName}/:type`,
        ReportList,
        true,
        `report_list/${sLDealName}`,
        "特約" + sDealName,
        bTab,
        author
    );
}

// 建立 UserList 頁面
function addUserListItem(
    sDealName: string,
    bTab: boolean = false,
    author: string[]
) {
    const sLDealName = sDealName.toLowerCase();
    return buildRouterForm(
        `/specimen/${sLDealName}/:type`,
        UserListPage,
        false,
        `specimen/${sLDealName}`,
        sDealName,
        bTab,
        author
    );
}

export function returnRouteInfo(roleData: role) {
    let vNewAllRoute: RouteType[] = newRouteInfo();
    const systemKey = Object.keys(roleData.projects);
    for (const keys of systemKey) {
        //login
        if (!vNewAllRoute[0].authorize.includes(keys))
            vNewAllRoute[0].authorize.push(keys);
        //upload
        if (
            roleData.projects[keys].upload &&
            !vNewAllRoute[1].authorize.includes(keys)
        ) {
            vNewAllRoute[1].authorize.push(keys);
        }
        //登錄異常
        if (
            roleData.projects[keys].report_exception &&
            !vNewAllRoute[2].authorize.includes(keys)
        ) {
            // except.push(keys);
            vNewAllRoute[2].authorize.push(keys);
        }
        //醫生簽名
        if (
            roleData.projects[keys].doctor_sign &&
            !vNewAllRoute[3].authorize.includes(keys)
        ) {
            // doctor.push(keys);
            vNewAllRoute[3].authorize.push(keys);
            if (!vNewAllRoute[5].authorize.includes(keys))
                //報告
                vNewAllRoute[5].authorize.push(keys);
            // report.push(keys);
        }
        //中心簽名
        if (
            roleData.projects[keys].pmc_sign &&
            !vNewAllRoute[4].authorize.includes(keys)
        ) {
            // pmc.push(keys);
            vNewAllRoute[4].authorize.push(keys);
            if (!vNewAllRoute[5].authorize.includes(keys))
                //報告
                vNewAllRoute[5].authorize.push(keys);
            // report.push(keys);
        }
    }
    return vNewAllRoute;
}
const routeInfo = newRouteInfo();
export default routeInfo;
