import { createContext } from "react";
import { vSystemType, vOrgIdInfo, role } from "../utils/GeneBoxAPI";

export const InitRoleData = {
    projects: {
        genebox: {
            Access: false,
            upload: false,
            download: false,
            doctor_sign: false, // 簽名頁面
            pmc_sign: false,
            report_exception: false,
            vendor: {
                VT: {
                    worklist: false,
                    appointed: false,
                },
                EH: {
                    worklist: false,
                    appointed: false,
                },
                TM: {
                    worklist: false,
                    appointed: false,
                },
                ES: {
                    worklist: false,
                    appointed: false,
                },
                MV: {
                    worklist: false,
                    appointed: false,
                },
            },
        },
        gutflora: {
            Access: false,
            upload: false,
            download: false,
            doctor_sign: false, // 簽名頁面
            pmc_sign: false,
            report_exception: false,
            vendor: {
                VT: {
                    worklist: false,
                    appointed: false,
                },
                EH: {
                    worklist: false,
                    appointed: false,
                },
                TM: {
                    worklist: false,
                    appointed: false,
                },
                ES: {
                    worklist: false,
                    appointed: false,
                },
            },
        },
        nips: {
            Access: false,
            upload: false,
            download: false,
            doctor_sign: false, // 簽名頁面
            pmc_sign: false,
            report_exception: false,
            vendor: {
                VT: {
                    worklist: false,
                    appointed: false,
                },
                EH: {
                    worklist: false,
                    appointed: false,
                },
                TM: {
                    worklist: false,
                    appointed: false,
                },
                ES: {
                    worklist: false,
                    appointed: false,
                },
            },
        },
    },

    Functions: {
        export_worklist: false,
        agent_limit: "",
        make_downloaded: false,
        enable_comment: false,
        excel_upload_specimen: false,
    },
};

const LoginInfoContext = createContext({
    roleData: InitRoleData as role,
    currentUser: "",
    isHidden: false,
    sCurrentToken: "",
    sOrgIdx: "",
    bAdmin: false,
    sSystem:
        vSystemType.blank as unknown as React.MutableRefObject<vSystemType>,
    setbLoading: (x: boolean) => {},
    setCurrentToken: (x: string) => {},
    loginCallback: (x: string) => {},
    logoutCallback: () => {},
    logout: () => {},
    errorProcess: (x: number, y: Object) => {},
    getNewSystem: (x: vOrgIdInfo) => {},
    getUrlDefault: (roleData: role): string => {
        return "";
    },
});

export default LoginInfoContext;
