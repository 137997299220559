import React from "react";
import { userExcel } from "../UserAddModal";
interface UserAddListInput {
    vUserExcel: userExcel[];
}

const UserAddList = (props: UserAddListInput) => {
    const { vUserExcel } = props;
    return (
        <div className="file_content dialog_upload_excel">
            {
                <table className="list_table">
                    <thead className="bg_lightgrey font_bold">
                        <tr>
                            <td>項號</td>
                            <td>姓名</td>
                            <td>受檢編號</td>
                            <td>性別</td>
                            <td>送檢日期</td>
                            <td>訂購套組</td>
                            <td>備註</td>
                        </tr>
                    </thead>
                    <tbody>
                        {vUserExcel.length === 0 ? (
                            <tr>
                                <td className="upload_hint">請先上傳檔案</td>
                            </tr>
                        ) : (
                            vUserExcel.map((user) => (
                                <tr key={user["受檢編號"]}>
                                    <td>{user["項號"]}</td>
                                    <td>{user["姓名"]}</td>
                                    <td>{user["受檢編號"]}</td>
                                    <td>{user["性別"]}</td>
                                    <td>{user["送檢日期"]}</td>
                                    <td>{user["訂購套組"]}</td>
                                    <td>{user["備註"]}</td>
                                </tr>
                            ))
                        )}
                    </tbody>
                </table>
            }
        </div>
    );
};

export default UserAddList;
