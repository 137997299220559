import React from "react";

interface PageNumberProps {
    iMaxPage: number;
    iNowPage: number;
    changePage: (page: number) => void;
}

const PageNumber = (props: PageNumberProps) => {
    const { iMaxPage, iNowPage, changePage } = props;
    const iShowPageRow = 3; //
    const iShowMaxPage = Math.min(
        Math.ceil(iNowPage / iShowPageRow) * iShowPageRow,
        iMaxPage
    );

    const clickChangePage = (iPage: number) => {
        if (iPage < 0 || iPage > iMaxPage) return;
        changePage(iPage);
    };

    return (
        <ul className="pagination">
            {/* pre page */}
            {iNowPage === 1 ? (
                ""
            ) : (
                <li
                    onClick={() => clickChangePage(iNowPage - 1)}
                    className="pagenumber"
                >
                    <img src="/img/page_prev.png" alt="Prev Page" />
                </li>
            )}
            {/* min page */}
            {iShowMaxPage - iShowPageRow === 0 ||
            iShowMaxPage < iShowPageRow ? (
                ""
            ) : (
                <>
                    <li
                        key={1}
                        onClick={() => clickChangePage(1)}
                        className="pagenumber"
                    >
                        {1}
                    </li>
                    <li className="pageDot">....</li>
                </>
            )}
            {/* show page */}
            {Array.from(Array(iMaxPage), (e, i) => {
                const iPage = i + 1;
                return iPage > iShowMaxPage ||
                    iPage <= iShowMaxPage - iShowPageRow ? (
                    false
                ) : (
                    <li
                        key={iPage}
                        className={
                            iNowPage === iPage
                                ? "active pagenumber"
                                : "pagenumber"
                        }
                        onClick={() => clickChangePage(iPage)}
                    >
                        {iPage}
                    </li>
                );
            })}
            {/* max page */}
            {iShowMaxPage === iMaxPage ? (
                ""
            ) : (
                <>
                    <li className="pageDot">....</li>
                    <li
                        key={iMaxPage}
                        className="pagenumber"
                        onClick={() => clickChangePage(iMaxPage)}
                    >
                        {iMaxPage}
                    </li>
                </>
            )}
            {/* next page */}
            {iNowPage === iMaxPage || iMaxPage === 0 ? (
                ""
            ) : (
                <li
                    onClick={() => clickChangePage(iNowPage + 1)}
                    className="pagenumber"
                >
                    <img src="/img/page_next.png" alt="Next Page" />
                </li>
            )}
        </ul>
    );
};

export default PageNumber;
