import axios from "axios";

const API_url = process.env.REACT_APP_API_URL;

// user state
export const vUserState = {
    check_all: -1,
    abnormal_pick_again: 1, //重新檢驗中
    abnormal_detect_again: 2, //晶片重新檢驗
    abnormal_other: 3,
    analysis: 4,
    unsigned_center: 5,
    unsigned_doctor: 6,
    unsigned_both: 7,
    detect: 8,
    not_download: 9,
    already_download: 10,
    disagree_doctor: 11,
    disagree_center: 12,
    disagree_both: 13,
    pdf_create: 14,
    sign_finish: 15,
    report_finish: 16,
    questionnaire_analysis: 17,
    questionnaire_finish: 18,
};

// org_type
// export const vOrgType: { [x: string]: number } = {
//     dealer: 1, // 經銷商
//     examine: 2, // 中心
//     medical: 3, // 審核報告醫生 中附醫
//     efai: 5, // 長佳內部人員
//     inspection: 6, // 檢驗部
//     doctor: 7, // 特約諮詢醫生
// };

// system_type
export enum vSystemType {
    blank = 0,
    genebox = 1, //基因寶
    gutflora = 2, //腸菌寶
    nips = 3, //NIPS
}

export enum ListType {
    sign_list = "sign_list",
    report_list = "report_list",
}

// org_id
export interface vOrgIdInfo {
    org_type: number;
    org_id: number;
    org_code: string;
    name: string;
}

export interface vOrgIdType {
    [x: string]: vOrgIdInfo;
}

export interface Authority_System {
    Access: boolean;
    upload: boolean;
    download: boolean;
    report_exception: boolean;
    doctor_sign: boolean;
    pmc_sign: boolean;
    vendor: {
        VT: Authority_dealer;
        EH: Authority_dealer;
        TM: Authority_dealer;
        ES: Authority_dealer;
        [key: string]: Authority_dealer;
    };
}

export interface Authority_dealer {
    worklist: boolean;
    appointed: boolean;
}

interface Authority_Fun {
    export_worklist: boolean;
    agent_limit: string;
    make_downloaded: boolean;
    enable_comment: boolean;
    excel_upload_specimen: boolean;
}

export interface role {
    projects: {
        genebox: Authority_System;
        gutflora: Authority_System;
        nips: Authority_System;
        [key: string]: Authority_System;
    };

    Functions: Authority_Fun;
    [key: string]: any;
}

export interface vTokenData {
    sub: string;
    org_name: string;
    org_type: number;
    is_admin: boolean;
    org_id: number;
    exp: number;
    role_data: role;
}
export const blankOrgId: vOrgIdType = {
    blank: {
        org_type: -1,
        org_id: -1,
        name: "",
        org_code: "",
    },
};
export interface vLogoutOutput {
    respCode: number;
    respDesc: string;
}

export interface ResponseCode extends vLogoutOutput {
    token: string;
}

export interface signedurlOutput extends ResponseCode {
    url: string;
    blobname: string;
}

export interface vLoginOutput extends ResponseCode {
    is_admin: boolean;
    org_id: number;
    org_type: number;
    org_name: string;
}

export interface getUserListOutput extends vSignListOutput {
    uid: string;
    uname: string;
    org_id: number;
    org_name: string;
    gender: string;
    date: string;
    state: number;
    abnormal_des: string;
    center_comment: string;
    doctor_comment: string;
    error_message: string;
    sign_time: string;
    zip_upload_time: string;
    latest_pdf_time: string;
    latest_gf_time: string;
    mod_generation_status: pdfMode[];
    [x: string]: any;
}
export interface pdfMode {
    name: string;
    id: string;
    status: boolean;
    timestamp?: string;
}
export interface UserPdfMode {
    uid: string;
    mod_list: pdfMode[];
}

export interface PostPdfMode {
    uid: string;
    mod_list: string[];
}

export interface AllPdf {
    id: string;
    name: string;
}

export interface getAllPDF extends ResponseCode {
    respDesc: string;
    respCode: number;
    default: AllPdf[];
}

export interface checkZipCrc32Output extends ResponseCode {
    data_count: number;
    error_list: string[];
}

export interface ReportListOutput {
    file_name: string;
    date: string;
}

export interface vSignListOutput {
    uid: string;
    uname: string;
    gender: string;
    date: string;
    high_risk_count: number;
    medium_risk_count: number;
    physique_count: number;
    state: number;
    [x: string]: any;
}

export interface vUploadFile extends ResponseCode {
    data_count: number;
    error_list: string[];
    respDesc: string;
    respCode: number;
}

//User comment
export interface IUserCommentInput {
    uid: string;
    abnormal?: boolean;
    comment: string;
}

//個基因列表
export interface gene_list {
    gene_name: string;
    gene_explain: string;
    high: string;
    general: string;
    low: string;
    risk: number;
    risk_class: number;
}

//氣喘、肺腺癌
export interface risk_list {
    item: string;
    relation: number;
    risk_class: number;
    risk_legend: string;
    risk_value: string;
    gene_list: gene_list[];
    clinvar_table: clinvar_list;
    [x: string]: any;
}

export interface clinvar_list {
    item_list: clinvar_list_item[];
}

export interface clinvar_list_item {
    gene_info: clinvar_item;
}
export interface clinvar_item {
    name: string;
    clinvar_class: string;
    explain: string;
    high: number;
    mid: number;
    total: number;
    [x: string]: any;
}
//
export interface vUserReportListOutput extends ResponseCode {
    uid: string;
    gender: string;
    date: string;
    item_list: risk_list[];
    [x: string]: any;
}

export interface EditInput {
    uid: string;
    uname: string;
    gender: string;
    date: string;
    description: string;
    state: number;
}

export enum DataType {
    checkDate = 1,
    downloadDate = 2,
}

// Login
export async function loginUser(idtoken: string): Promise<vLoginOutput> {
    const res = await axios({
        method: "POST",
        url: `${API_url}/user/login`,
        params: {
            id_token: idtoken,
        },
    });
    return res.data;
}

// Logout
export async function logoutUser(token: string): Promise<vLogoutOutput> {
    const res = await axios({
        headers: {
            Authorization: `Bearer ${token}`,
        },
        method: "DELETE",
        url: `${API_url}/user/logout`,
    });
    return res.data;
}

//UploadFile :gcp used
export async function UploadFilesignedurl(
    filename: string,
    systemType: string,
    token: string
): Promise<{
    url: string;
    blobname: string;
    respCode: number;
    respDesc: string;
    token: string;
}> {
    const res = await axios({
        headers: {
            "Access-Control-Allow-Origin": "*",
            Authorization: `Bearer ${token}`,
        },
        method: "POST",
        url: `${API_url}/specimen/signedurl`,
        params: {
            filename: filename,
            usr_org: systemType,
        },
    });
    return res.data;
}
export async function UploadFilesPosturl(url: string, file: FormData) {
    // const res = await axios.post(`${url}`, file);
    const res = await axios({
        headers: {
            "Access-Control-Allow-Origin": "*",
            "Content-Type": `application/octet-stream`,
        },
        method: "POST",
        url: `${API_url.replace("/api", "")}${url}`,
        data: file,
    });
    return res.data;
}

export async function UploadFilezip(
    blobname: string,
    password: string,
    systemType: string,
    token: string
): Promise<vUploadFile> {
    const res = await axios({
        headers: {
            Authorization: `Bearer ${token}`,
        },
        method: "POST",
        url: `${API_url}/specimen/uploadzip`,
        params: {
            blobname: blobname,
            password: password,
            usr_org: systemType,
        },
    });
    return res.data;
}

// UploadFile: 顯示上傳紀錄
export async function getReportListAPI(
    sStartDate: string,
    sEndDate: string,
    token: string,
    systemType: string
): Promise<{
    file_list: ReportListOutput[];
    respCode: number;
    respDesc: string;
    token: string;
}> {
    const res = await axios({
        headers: {
            Authorization: `Bearer ${token}`,
        },
        method: "GET",
        url: `${API_url}/pmc/zip`,
        params: {
            start_time: sStartDate,
            end_time: sEndDate,
            usr_org: systemType,
        },
    });
    return res.data;
}

// CheckExpectPage: 檢驗中名單
export async function getUserListAPI(
    start_time: string,
    end_time: string,
    state: string | null,
    token: string,
    systemType: string,
    supplier: string,
    date_type: number = DataType.checkDate
): Promise<{ user_list: getUserListOutput[]; token: string }> {
    const res = await axios({
        headers: {
            Authorization: `Bearer ${token}`,
        },
        method: "GET",
        url: `${API_url}/specimen`,
        params: {
            start_time: start_time,
            end_time: end_time,
            usr_org: systemType,
            supplier: supplier.toUpperCase(),
            state: state,
            date_type,
        },
    });
    return res.data;
}

//getUserExcel
export async function getUserListExcelAPI(
    start_time: string,
    end_time: string,
    state: string | null,
    token: string,
    systemType: string,
    supplier: string,
    date_type: number = DataType.checkDate
) {
    const res = await axios({
        headers: {
            Authorization: `Bearer ${token}`,
        },
        method: "GET",
        responseType: "blob",
        url: `${API_url}/specimen/export`,
        params: {
            start_time: start_time,
            end_time: end_time,
            usr_org: systemType,
            supplier: supplier.toUpperCase(),
            state: state,
            date_type,
        },
    });
    return res.data;
}

// SignListPage: 待簽名名單
export async function getSignListAPI(
    systemType: string,
    token: string
): Promise<{
    user_risk_list: vSignListOutput[];
    respCode: number;
    respDesc: string;
    token: string;
}> {
    const res = await axios({
        headers: {
            Authorization: `Bearer ${token}`,
        },
        method: "GET",
        url: `${API_url}/sign/user`,
        params: {
            usr_org: systemType,
        },
    });
    return res.data;
}

//Report : 顯示個人基因報告
export async function getUserReportListAPI(
    user_id: string,
    token: string
): Promise<vUserReportListOutput> {
    const res = await axios({
        headers: {
            Authorization: `Bearer ${token}`,
        },
        method: "GET",
        url: `${API_url}/sign/report`,
        params: {
            uid: user_id,
        },
    });
    return res.data;
}

//UserListAddModal : 新增受試者清單
export async function uploadUserAPI(
    file: FormData,
    token: string
): Promise<vUploadFile> {
    const res = await axios.post(`${API_url}/specimen/uploadexcel`, file, {
        headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${token}`,
        },
    });
    return res.data;
}

//CheckExceptPage、ReportInfo、SingList : 醫師簽核or意見輸入、異常登錄
export async function DoctorSaveCommentAPI(
    systemType: string,
    token: string,
    data: IUserCommentInput[]
): Promise<ResponseCode> {
    const res = await axios({
        headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json;",
        },
        method: "POST",
        url: `${API_url}/pmc/comment?usr_org=${systemType}`,
        data: {
            user_list: data,
        },
    });
    return res.data;
}

// 修改受檢者資料
export async function editUserAPI(
    vUserInfo: EditInput[],
    token: string
): Promise<ResponseCode> {
    const res = await axios({
        headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json;",
        },
        method: "POST",
        url: `${API_url}/spinout/modify`,
        data: {
            user_list: vUserInfo,
        },
    });
    return res.data;
}

// 下載PDF from url
export async function downloadReportFromUrlApi(vUserid: string, token: string) {
    const res = await axios({
        headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json;",
        },
        method: "GET",
        url: `${API_url}/specimen/pdfurl`,
        params: {
            uid: vUserid,
        },
    });

    return res.data;
}
//下載基因寶PDF Mode (會要更改url 20211006)
export async function downloadPDFModeReportFromUrlApi(
    data: PostPdfMode[],
    token: string
) {
    const res = await axios({
        headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json;",
        },
        method: "POST",
        url: `${API_url}/specimen/gene_pdfurl`,
        data: {
            data: data,
        },
    });

    return res.data;
}

// 下載PDF (舊的)
export async function downloadReportApi(vUserid: string[], token: string) {
    const res = await axios({
        headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json;",
        },
        method: "POST",
        responseType: "blob",
        url: `${API_url}/specimen/pdf`,
        data: {
            user_list: vUserid,
        },
    });

    return res.data;
}

// 重新產pdf
export async function redoPDFAPI(vUser: string[], token: string) {
    const res = await axios({
        headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json;",
        },
        method: "POST",
        url: `${API_url}/pdf/redo`,
        data: {
            user_list: vUser,
        },
    });
    return res.data;
}

//拿取全部pdf mode API
export async function getAllPdfMode(token: string): Promise<getAllPDF> {
    const res = await axios({
        headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json;",
        },
        method: "GET",
        url: `${API_url}/specimen/default_pdfmod`,
    });
    return res.data;
}

// 更新驗收完成日期
export async function updateAcceptanceTime({
    token,
    userId,
    acceptanceTime,
}: {
    token: string;
    userId: string;
    acceptanceTime: string;
}): Promise<any> {
    const res = await axios({
        headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json;",
            "X-API-KEY":
                "004c5d5d301cddecccf853a59f08b7ea2d02d618275af68773e6ca3155f71c53",
        },
        method: "POST",
        url: `${API_url}/app/update_acceptance_time`,
        params: {
            uid: userId,
            acp_time: acceptanceTime,
        },
    });
    return res.data;
}
