import { createContext } from "react";
import {
    getUserListOutput,
    vUserState,
    EditInput,
    vUploadFile,
    DataType,
    UserPdfMode,
} from "../utils/GeneBoxAPI";
export interface vTable {
    key_name: string;
    title: string;
    sortable: boolean;
    col_span: number;
    class_name: string;
}

export interface vUserList {
    user_name: string;
    user_number: string;
    gender: string;
    date: string;
    state: number;
    sign_des: string;
    abnormal_des: string;
    center_comment: string;
    doctor_comment: string;
    [x: string]: any;
}

export interface vStatus {
    text: string;
    class_name: string;
    state: number[];
    square_class: string;
    check: boolean;
}

export interface UserListAPIData {
    org: string;
    uid: string;
    uname: string;
    start_time: string;
    end_time: string;
    session_key: string;
}

const vUserListContextData: getUserListOutput[] = [];

const vGeneStatusContextCheckBox: vStatus[] = [
    {
        text: "全選",
        class_name: "text_black",
        square_class: "",
        check: true,
        state: [-1],
    },
    {
        text: "異常",
        class_name: "text_red",
        square_class: "bg_red",
        check: true,
        state: [vUserState.abnormal_pick_again, vUserState.abnormal_other],
    },
    {
        text: "分析中",
        class_name: "text_lightgreen",
        square_class: "bg_lightgreen",
        check: true,
        state: [vUserState.analysis],
    },
    {
        text: "待簽名",
        class_name: "text_skyblue",
        square_class: "bg_skyblue",
        check: true,
        state: [
            vUserState.unsigned_center,
            vUserState.unsigned_doctor,
            vUserState.unsigned_both,
        ],
    },
    {
        text: "檢驗中",
        class_name: "text_brown",
        square_class: "bg_brown",
        check: true,
        state: [vUserState.detect, vUserState.abnormal_detect_again],
    },
    {
        text: "待下載",
        class_name: "text_blue",
        square_class: "bg_blue",
        check: true,
        state: [vUserState.not_download],
    },
    {
        text: "已下載",
        class_name: "text_grey",
        square_class: "bg_grey",
        check: true,
        state: [vUserState.already_download],
    },
    {
        text: "不同意",
        class_name: "text_pink",
        square_class: "bg_pink",
        check: true,
        state: [
            vUserState.disagree_doctor,
            vUserState.disagree_center,
            vUserState.disagree_both,
        ],
    },
    {
        text: "PDF處理中",
        class_name: "text_orange",
        square_class: "bg_orange",
        check: true,
        state: [vUserState.pdf_create],
    },
    {
        text: "已完成簽名",
        class_name: "text_purple",
        square_class: "bg_purple",
        check: true,
        state: [vUserState.sign_finish],
    },
];

const vGutFloraStatusContextCheckBox: vStatus[] = [
    {
        text: "全選",
        class_name: "text_black",
        square_class: "",
        check: true,
        state: [-1],
    },
    {
        text: "異常",
        class_name: "text_red",
        square_class: "bg_red",
        check: true,
        state: [vUserState.abnormal_pick_again, vUserState.abnormal_other],
    },
    {
        text: "分析中",
        class_name: "text_lightgreen",
        square_class: "bg_lightgreen",
        check: true,
        state: [vUserState.analysis],
    },
    {
        text: "檢驗中",
        class_name: "text_brown",
        square_class: "bg_brown",
        check: true,
        state: [vUserState.detect, vUserState.abnormal_detect_again],
    },
    {
        text: "報告完成",
        class_name: "text_blue",
        square_class: "bg_blue",
        check: true,
        state: [vUserState.report_finish],
    },
    {
        text: "問卷分析中",
        class_name: "text_grey",
        square_class: "bg_grey",
        check: true,
        state: [vUserState.questionnaire_analysis],
    },
    {
        text: "問卷報告完成",
        class_name: "text_pink",
        square_class: "bg_pink",
        check: true,
        state: [vUserState.questionnaire_finish],
    },
];

const vNIPSStatusContextCheckBox: vStatus[] = [
    {
        text: "全選",
        class_name: "text_black",
        square_class: "",
        check: true,
        state: [-1],
    },
    {
        text: "檢驗中",
        class_name: "text_brown",
        square_class: "bg_brown",
        check: true,
        state: [vUserState.detect],
    },
    {
        text: "報告完成",
        class_name: "text_blue",
        square_class: "bg_blue",
        check: true,
        state: [vUserState.report_finish],
    },
];

export const vStatusContextCheckBox: { [unit: string]: vStatus[] } = {
    genebox: vGeneStatusContextCheckBox,
    gutflora: vGutFloraStatusContextCheckBox,
    nips: vNIPSStatusContextCheckBox,
};

const vGeneStatusContextCheckBoxForExport: vStatus[] = [
    {
        text: "全選",
        class_name: "text_black",
        square_class: "",
        check: false,
        state: [-1],
    },
    {
        text: "異常",
        class_name: "text_red",
        square_class: "bg_red",
        check: false,
        state: [vUserState.abnormal_pick_again, vUserState.abnormal_other],
    },
    {
        text: "分析中",
        class_name: "text_lightgreen",
        square_class: "bg_lightgreen",
        check: false,
        state: [vUserState.analysis],
    },
    {
        text: "待簽名",
        class_name: "text_skyblue",
        square_class: "bg_skyblue",
        check: false,
        state: [
            vUserState.unsigned_center,
            vUserState.unsigned_doctor,
            vUserState.unsigned_both,
        ],
    },
    {
        text: "檢驗中",
        class_name: "text_brown",
        square_class: "bg_brown",
        check: false,
        state: [vUserState.detect, vUserState.abnormal_detect_again],
    },
    {
        text: "待下載",
        class_name: "text_blue",
        square_class: "bg_blue",
        check: false,
        state: [vUserState.not_download],
    },
    {
        text: "已下載",
        class_name: "text_grey",
        square_class: "bg_grey",
        check: false,
        state: [vUserState.already_download],
    },
    {
        text: "不同意",
        class_name: "text_pink",
        square_class: "bg_pink",
        check: false,
        state: [
            vUserState.disagree_doctor,
            vUserState.disagree_center,
            vUserState.disagree_both,
        ],
    },
    {
        text: "PDF處理中",
        class_name: "text_orange",
        square_class: "bg_orange",
        check: false,
        state: [vUserState.pdf_create],
    },
    {
        text: "已完成簽名",
        class_name: "text_purple",
        square_class: "bg_purple",
        check: false,
        state: [vUserState.sign_finish],
    },
];

const vGutFloraStatusContextCheckBoxForExport: vStatus[] = [
    {
        text: "全選",
        class_name: "text_black",
        square_class: "",
        check: false,
        state: [-1],
    },
    {
        text: "異常",
        class_name: "text_red",
        square_class: "bg_red",
        check: false,
        state: [vUserState.abnormal_pick_again, vUserState.abnormal_other],
    },
    {
        text: "分析中",
        class_name: "text_lightgreen",
        square_class: "bg_lightgreen",
        check: false,
        state: [vUserState.analysis],
    },
    {
        text: "檢驗中",
        class_name: "text_brown",
        square_class: "bg_brown",
        check: false,
        state: [vUserState.detect, vUserState.abnormal_detect_again],
    },
    {
        text: "報告完成",
        class_name: "text_blue",
        square_class: "bg_blue",
        check: false,
        state: [vUserState.report_finish],
    },
    {
        text: "問卷分析中",
        class_name: "text_grey",
        square_class: "bg_grey",
        check: false,
        state: [vUserState.questionnaire_analysis],
    },
    {
        text: "問卷報告完成",
        class_name: "text_pink",
        square_class: "bg_pink",
        check: false,
        state: [vUserState.questionnaire_finish],
    },
];

const vNIPSStatusContextCheckBoxForExport: vStatus[] = [
    {
        text: "全選",
        class_name: "text_black",
        square_class: "",
        check: false,
        state: [-1],
    },
    {
        text: "檢驗中",
        class_name: "text_brown",
        square_class: "bg_brown",
        check: false,
        state: [vUserState.detect],
    },
    {
        text: "報告完成",
        class_name: "text_blue",
        square_class: "bg_blue",
        check: false,
        state: [vUserState.report_finish],
    },
];

export const vStatusContextCheckBoxForExport: { [unit: string]: vStatus[] } = {
    genebox: vGeneStatusContextCheckBoxForExport,
    gutflora: vGutFloraStatusContextCheckBoxForExport,
    nips: vNIPSStatusContextCheckBoxForExport,
};

const vTableColumn: vTable[] = [
    {
        key_name: "state",
        title: "狀態",
        sortable: true,
        col_span: 2,
        class_name: "width-130",
    },
    {
        key_name: "uid",
        title: "受檢編號",
        sortable: true,
        col_span: 1,
        class_name: "width-130",
    },
    {
        key_name: "uname",
        title: "姓名",
        sortable: true,
        col_span: 1,
        class_name: "width-130",
    },
    {
        key_name: "gender",
        title: "姓別",
        sortable: true,
        col_span: 1,
        class_name: "width-50",
    },
    {
        key_name: "date",
        title: "送檢日期",
        sortable: true,
        col_span: 1,
        class_name: "width-130",
    },
    // {
    //     key_name: "zip_upload_time",
    //     title: "上傳報告日期",
    //     sortable: true,
    //     col_span: 1,
    //     class_name: "width-130",
    // },
    {
        key_name: "sign_time",
        title: "交付報告日期",
        sortable: true,
        col_span: 1,
        class_name: "width-130",
    },
    {
        key_name: "acceptance_time",
        title: "驗收完成日期",
        sortable: true,
        col_span: 1,
        class_name: "width-130",
    },
    {
        key_name: "latest_pdf_time",
        title: "最新報告日期",
        sortable: true,
        col_span: 1,
        class_name: "width-130",
    },
    {
        key_name: "latest_gf_time",
        title: "最新問卷日期",
        sortable: true,
        col_span: 1,
        class_name: "width-130",
    },
    {
        key_name: "abnormal_des",
        title: "說明",
        sortable: false,
        col_span: 1,
        class_name: "abnormal_des",
    },
    {
        key_name: "checkBox",
        title: "全選",
        sortable: false,
        col_span: 1,
        class_name: "width-154",
    },
    {
        key_name: "editUSer",
        title: "修改資料",
        sortable: false,
        col_span: 1,
        class_name: "width-75 edit_user_input",
    },
    // {
    //     key_name: "redoPDF",
    //     title: "重產報告",
    //     sortable: false,
    //     col_span: 1,
    //     class_name: "width-75 redo_pdf_input",
    // },
];

const vCheckContextStatus: number[] = Object.values(vUserState);

let pdfSelect: UserPdfMode[] = [];

const UserListContext = createContext({
    vUserListContextData: vUserListContextData,
    vUserListContextShowData: vUserListContextData,
    iSortNow: 1,
    iNowPage: 1,
    nowSortType: "",
    vTableColumn,
    vStatusContextCheckBox,
    vStatusContextCheckBoxForExport,
    vUserId: [""],
    org: "",
    bQueryFirst: false,
    bQueryNow: false,
    vCheckContextStatus,
    sStartDate: "",
    sEndDate: "",
    iDateType: DataType.checkDate,
    bCheckAll: false,
    vUserPDFselect: pdfSelect,
    setiDateType: (x: number): void => {},
    setStartDate: (x: string): void => {},
    setsEndDate: (x: string): void => {},
    clickStatus: (idx: number, bStatus: boolean): void => {},
    changeSortUserListData: (sortType: string): void => {},
    changePage: (page: number): void => {},
    getUserListData: (
        startDate: string,
        endDate: string,
        iDateType: number,
        systemType: string, //usr_org
        supplier: string
    ): void => {},
    choseUser: (user_number: string): void => {},
    choseAllUser: (vUserNumber: string[], bCheck: boolean): void => {},
    filterStatus: (uid: string, uname: string): void => {},
    editUserInfo: (
        vEditInput: EditInput,
        editUser: (x: string) => void
    ): void => {},
    showMessageAlert: (res: vUploadFile): void => {},
    showErrorfileMessageAlert: (sMsg: string): void => {},
    downloadReport: (vUserId: string[]) => {},
    downloadUser: (
        start_date: string,
        end_date: string,
        state: string | null,
        date_type: number
    ) => {},
    redoPDF: (vUserId: string[]) => {},
    setCheckAll: (x: boolean) => {},
    updateAcceptanceTimeHandler: (
        e: React.FormEvent<HTMLInputElement>,
        userId: string
    ) => {},
});

export default UserListContext;
