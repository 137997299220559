import React from "react";
import UploadRecord from "./UploadRecord";
import UploadFile from "./UploadFile";

const UploadPage = () => {
    return (
        <>
            <UploadRecord />
            <div className="derection_line_gray"></div>
            <UploadFile />
        </>
    );
};

export default UploadPage;
