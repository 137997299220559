import React, { useContext } from "react";
import { useHistory, useLocation } from "react-router-dom";

import { vSignListOutput, ListType } from "../utils/GeneBoxAPI";
import { vListKey } from "../Context/SignListContext";
import ReportListContext from "../Context/ReportListContext";
import GeneList from "../component/GeneList";

const List = () => {
    const history = useHistory();
    const location = useLocation();

    const {
        vReportList,
        iStartItem,
        iEndItem,
        bQueryNow,
        iSortNow,
        nowSortType,
        sortSignData,
        vReportIndex,
    } = useContext(ReportListContext);

    const pageFrom = location.pathname.split("/")[1];

    const clickList = (vSignData: vSignListOutput, sKey: string) => {
        history.push(
            `/report/${pageFrom}/${vSignData.uid.toLocaleLowerCase()}`
        );
    };

    const GeneListProps = {
        iSortNow,
        nowSortType,
        vListData: vReportList,
        vDataIndex: vReportIndex,
        sType: ListType.report_list,
        vKey: vListKey.slice(0, vListKey.length - 1),
        iStartItem,
        iEndItem,
        bQueryNow,
        clickSortTh: sortSignData,
        clickList,
    };
    return <GeneList {...GeneListProps} />;
};

export default List;
