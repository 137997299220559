import React, { useContext } from "react";
import ExceptSelect from "./ExceptSelect";
import PageNumber from "../component/PageNumber";
import ExceptContext from "../Context/ExceptContext";

interface CheckExceptPage {
    SystemType: string;
}

const CheckExcept = (props: CheckExceptPage) => {
    const { SystemType } = props;
    const {
        vExceptShowContextData,
        vTableColumn,
        nowSortType,
        iSortNow,
        iNowPage,
        vExceptContentUser,
        bQueryNow,
        changePage,
        sortExceptData,
        addExceptUser,
    } = useContext(ExceptContext);
    const iItemInPage = 10;
    const iMaxPage = Math.ceil(vExceptShowContextData.length / iItemInPage);
    const iStartItem = (iNowPage - 1) * iItemInPage;
    const iEndItem = iNowPage * iItemInPage - 1;
    const vExceptUser = vExceptContentUser.map((vUser) => {
        return vUser.uid;
    });

    const getSortClass = (idx: number) => {
        const sSortClass = iSortNow === 1 ? " order" : " order_reverse";
        return nowSortType === vTableColumn[idx].key_name ? sSortClass : "";
    };

    const changeExceptPage = (page: number) => {
        addExceptUser("", "-1", "");
        changePage(page);
    };

    return (
        <>
            <div className="table_container">
                <table className="list_table" cellPadding="5">
                    <thead>
                        <tr className="bg_blue_green">
                            {vTableColumn.map((vSort, idx) =>
                                vSort.sortable ? (
                                    <th
                                        key={idx}
                                        colSpan={vSort.col_span}
                                        onClick={(event) =>
                                            sortExceptData(
                                                vSort.key_name,
                                                event
                                            )
                                        }
                                        className={
                                            "sort" +
                                            getSortClass(idx) +
                                            vSort.class_name
                                        }
                                    >
                                        {vSort.title}
                                        <span></span>
                                    </th>
                                ) : (
                                    <th
                                        key={idx}
                                        colSpan={vSort.col_span}
                                        className={vSort.class_name}
                                    >
                                        {vSort.title}
                                    </th>
                                )
                            )}
                        </tr>
                    </thead>
                    <tbody>
                        {vExceptShowContextData.length === 0 ? (
                            <tr>
                                {!bQueryNow ? (
                                    <td colSpan={8}>尚無受檢者名單</td>
                                ) : (
                                    <td colSpan={8}>Loading...</td>
                                )}
                            </tr>
                        ) : (
                            vExceptShowContextData.map((vPerson, idx) => {
                                const bExcept =
                                    vExceptUser.indexOf(vPerson.uid) === -1;
                                return idx < iStartItem || idx > iEndItem ? (
                                    false
                                ) : (
                                    <tr key={vPerson.uid}>
                                        <td
                                            className={`table_color ${
                                                bExcept ? "bg_brown" : "bg_red"
                                            }`}
                                        ></td>
                                        <td
                                            className={`font_bold ${
                                                bExcept
                                                    ? "text_brown"
                                                    : "text_red"
                                            }`}
                                        >
                                            檢驗中
                                        </td>
                                        <td>{vPerson.uid}</td>
                                        <td>
                                            {vPerson.uid
                                                .split("")
                                                .slice(0, 2)
                                                .join("")}
                                        </td>
                                        <td>{vPerson.uname}</td>
                                        <td>{vPerson.gender}</td>
                                        <td>{vPerson.date}</td>
                                        <td className="align_left">
                                            <ExceptSelect
                                                vPerson={vPerson}
                                                SystemType={SystemType}
                                            />
                                        </td>
                                    </tr>
                                );
                            })
                        )}
                    </tbody>
                </table>
            </div>
            <PageNumber
                iMaxPage={iMaxPage}
                iNowPage={iNowPage}
                changePage={changeExceptPage}
            />
        </>
    );
};

export default CheckExcept;
