import React, { useContext, useEffect, useState } from "react";
import { useHistory, useLocation, useParams } from "react-router-dom";
import SignListContext, {
    vListKey,
    vTableColumn,
} from "../Context/SignListContext";
import MessageContext, { vMsgProps } from "../Context/MessageContext";
import PageNumber from "../component/PageNumber";
import Alert from "../component/Message";
import {
    IUserCommentInput,
    DoctorSaveCommentAPI,
    vSignListOutput,
    ListType,
} from "../utils/GeneBoxAPI";
import LoginInfoContext from "../Context/LoginInfoContext";
import GeneList from "../component/GeneList";

interface url {
    type: string;
}

const SignList = () => {
    const history = useHistory();
    const location = useLocation();
    const { type: SystemType }: url = useParams();

    const pageFrom = location.pathname.split("/")[1];

    const {
        vSignContextData,
        iSortNow,
        nowSortType,
        iNowPage,
        bQueryNow,
        sortSignData,
        changePage,
        getSignListData,
    } = useContext(SignListContext);
    const { bShowAlert, showAlert } = useContext(MessageContext);

    const [alertProps, setAlertContent] = useState<vMsgProps>({
        title: "",
        sub_title: "",
        content: <></>,
        iMsg: 0,
        bBtnType: true,
        submitFun: () => {},
    });
    const { title, sub_title, content, iMsg, bBtnType, submitFun } = alertProps;

    const [UserCommentInput, setUserCommentInput] =
        useState<IUserCommentInput[]>();
    const { sCurrentToken, setCurrentToken, errorProcess } =
        useContext(LoginInfoContext);
    const [vCheckedItem, setCheckedItem] = useState<string[]>([]);
    const [bCheckAll, setbCheckAll] = useState(false);
    const iItemInPage = 10;
    const iMaxPage = Math.ceil(vSignContextData.length / iItemInPage);
    const iStartItem = (iNowPage - 1) * iItemInPage;
    const iEndItem = iNowPage * iItemInPage - 1;

    // click sort th
    const clickSortTh = (
        key_name: string,
        event: React.MouseEvent<HTMLTableHeaderCellElement>
    ) => {
        sortSignData(key_name, event);
        setCheckedItem([]);
        setbCheckAll(false);
    };

    // add select
    const addCheckedItem = (uid: string) => {
        let vNewCheckedItem = vCheckedItem.slice(0);
        const iMaxItem =
            vSignContextData.length > iItemInPage
                ? iItemInPage
                : vSignContextData.length;
        if (vCheckedItem.indexOf(uid) > -1) {
            vNewCheckedItem = vNewCheckedItem.filter((sItem) => sItem !== uid);
        } else {
            vNewCheckedItem.push(uid);
        }
        setCheckedItem(vNewCheckedItem);
        setbCheckAll(vNewCheckedItem.length === iMaxItem);
    };

    // add all
    const addAllCheckedItem = (vChecked: boolean) => {
        setbCheckAll(vChecked);
        if (vChecked) {
            const vSowUserNumber = vSignContextData
                .map((vSign, idx) => {
                    if (idx < iStartItem || idx > iEndItem) return "";
                    return vSign.uid;
                })
                .filter((vUserNumber) => {
                    return vUserNumber !== "";
                });
            setCheckedItem(vSowUserNumber);
        } else {
            setCheckedItem([]);
        }
    };

    // change page
    const changeSignPage = (page: number) => {
        changePage(page);
        setCheckedItem([]);
        setbCheckAll(false);
    };

    // click sign btn
    const clickSignReport = () => {
        if (vCheckedItem.length === 0) {
            // show alert
            showAlert(true);
            setAlertContent({
                ...alertProps,
                title: "請勾選欲簽核名單",
                sub_title: `名單不能為空`,
                content: <></>,
                iMsg: 2,
            });
            return;
        }
        // axios 送出簽核
        let data: React.SetStateAction<IUserCommentInput[]> = [];

        for (const i in vCheckedItem) {
            data.push({
                uid: vCheckedItem[i],
                comment: "",
            });
        }
        setUserCommentInput(data);
        data = [];
    };

    const checkStatus = (uid: string) => {
        return vCheckedItem.length > 0 && vCheckedItem.indexOf(uid) > -1;
    };

    const clickReport = (uid: string) => {
        history.push(`/report/${pageFrom}/${uid.toLocaleLowerCase()}`);
    };

    const clickList = (vSignData: vSignListOutput, sKey: string) => {
        switch (sKey) {
            case vTableColumn[6].key_name:
                addCheckedItem(vSignData.uid);
                break;
            default:
                clickReport(vSignData.uid);
                break;
        }
    };

    useEffect(() => {
        if (UserCommentInput !== undefined) {
            DoctorSaveCommentAPI(SystemType, sCurrentToken, UserCommentInput)
                .then((res) => {
                    if (res.respCode === 201 || res.respCode === 200) {
                        setCurrentToken(res.token);
                        showAlert(true);
                        setAlertContent({
                            ...alertProps,
                            title: "簽核成功",
                            sub_title: "",
                            content: <></>,
                            iMsg: 0,
                        });
                        setCheckedItem([]);
                        getSignListData();
                    } else {
                        showAlert(true);
                        setAlertContent({
                            ...alertProps,
                            title: "簽核失敗",
                            sub_title: "",
                            content: <></>,
                            iMsg: 2,
                        });
                        setCheckedItem([]);
                        getSignListData();
                    }
                    setbCheckAll(false);
                })
                .catch((error) => {
                    console.error(error);
                    let error_status: number;
                    if (error.response !== undefined) {
                        error_status = error.response.status;
                        errorProcess(error_status, error);
                    } else {
                        error_status = 0;
                    }
                    errorProcess(error_status, error);
                });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [UserCommentInput]);

    const GeneListProps = {
        iSortNow,
        nowSortType,
        vListData: vSignContextData,
        vDataIndex: Object.keys(vSignContextData).map((idx) => parseInt(idx)),
        sType: ListType.sign_list,
        vKey: vListKey,
        iStartItem,
        iEndItem,
        bQueryNow,
        clickSortTh,
        clickList,
        bCheckAll,
        addAllCheckedItem,
        checkStatus,
    };

    return (
        <div className="sign_container">
            <button
                className="button_style button_yellow"
                onClick={clickSignReport}
            >
                批次簽核
            </button>

            <div className="table_container">
                <GeneList {...GeneListProps} />
            </div>
            <PageNumber
                iMaxPage={iMaxPage}
                iNowPage={iNowPage}
                changePage={changeSignPage}
            />
            {bShowAlert && (
                <Alert
                    title={title}
                    sub_title={sub_title}
                    content={content}
                    iMsg={iMsg}
                    bBtnType={bBtnType}
                    submitFun={submitFun}
                />
            )}
        </div>
    );
};

export default SignList;
