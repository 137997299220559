import React, { useContext, useEffect } from "react";

import DateContext from "../Context/DateContext";
import ReportListContext from "../Context/ReportListContext";
import { vListKey } from "../Context/SignListContext";

interface props {
    uid: string;
    uname: string;
    setsUid: React.Dispatch<React.SetStateAction<string>>;
    setsName: React.Dispatch<React.SetStateAction<string>>;
}

const Filter = ({ uid, uname, setsUid, setsName }: props) => {
    const { sEndDefault, sGeneBoxStart } = useContext(DateContext);
    const { sStartDate, setsStartDate, sEndDate, setsEndDate, filterData } =
        useContext(ReportListContext);

    const getId = (event: React.FormEvent<HTMLInputElement>) => {
        const sNewId = event.currentTarget.value;
        // check input
        setsUid(sNewId);
    };

    const getName = (event: React.FormEvent<HTMLInputElement>) => {
        const sNewName = event.currentTarget.value;
        // check input
        setsName(sNewName);
    };

    const getDate = (
        event: React.FormEvent<HTMLInputElement>,
        changeDate: (x: string) => void
    ) => {
        const sNewDate = event.currentTarget.value;
        // check date
        changeDate(sNewDate);
    };

    useEffect(() => {
        filterData(vListKey[0], uid);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [uid]);

    useEffect(() => {
        filterData(vListKey[1], uname);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [uname]);

    return (
        <div className="search_list">
            <p className="search_list_bottom">篩選名單 /</p>
            <div className="search_list_content">
                <div className="search_input">
                    <label>
                        <span>受檢編號</span>
                        <input type="text" value={uid} onChange={getId} />
                    </label>
                    <label>
                        <span>姓名</span>
                        <input type="text" value={uname} onChange={getName} />
                    </label>
                    <label className="date_block">
                        <span>送檢日期</span>
                        <input
                            type="date"
                            className="date"
                            value={sStartDate}
                            onChange={(event) => getDate(event, setsStartDate)}
                            min={sGeneBoxStart}
                            max={sEndDefault}
                        />
                        <span className="date_dash">-</span>
                        <input
                            type="date"
                            className="date"
                            value={sEndDate}
                            onChange={(event) => getDate(event, setsEndDate)}
                            min={sStartDate}
                            max={sEndDefault}
                        />
                    </label>
                </div>
            </div>
        </div>
    );
};

export default Filter;
