import React, { useState, useContext, useEffect } from "react";
import {
    vSignListOutput,
    getSignListAPI,
    vUserState,
    // vOrgType,
} from "../utils/GeneBoxAPI";
import SignList from "./SignList";
import SignListContext, { vTableColumn } from "../Context/SignListContext";
import LoginInfoContext from "../Context/LoginInfoContext";
import { useHistory, useParams } from "react-router-dom";

interface url {
    type: string;
}

const SignListPage = () => {
    const history = useHistory();
    const pathName = history.location.pathname;
    const { type: SystemType }: url = useParams();

    // context
    const { Provider } = SignListContext;
    const vSignListContext = useContext(SignListContext);
    const { vSignContextData } = vSignListContext;
    const { sCurrentToken, setCurrentToken, errorProcess } =
        useContext(LoginInfoContext);

    // state
    const [vSignData, setvSignData] =
        useState<vSignListOutput[]>(vSignContextData);
    const [nowSortType, setNowSortType] = useState<string>(
        vTableColumn[3].key_name
    );
    const [iSortNow, setSortNow] = useState<number>(1);
    const [iNowPage, setNowPage] = useState(1);
    const [bQueryFirst, setQueryFirst] = useState<boolean>(false);
    const [bQueryNow, setQueryNow] = useState<boolean>(false);

    const getSignListData = () => {
        setQueryNow(true);
        getSignListAPI(SystemType, sCurrentToken)
            .then((res) => {
                if (pathName.includes("pmc_sign")) {
                    //Center
                    setvSignData(
                        res.user_risk_list.filter(
                            (item) =>
                                item.state === vUserState.unsigned_center ||
                                item.state === vUserState.unsigned_both
                        )
                    );
                } else if (pathName.includes("doctor_sign")) {
                    //Doctor
                    setvSignData(
                        res.user_risk_list.filter(
                            (item) =>
                                item.state === vUserState.unsigned_doctor ||
                                item.state === vUserState.unsigned_both
                        )
                    );
                }

                setCurrentToken(res.token);
                setQueryNow(false);
            })
            .catch((error) => {
                console.error(error);
                let error_status: number;
                if (error.response !== undefined) {
                    error_status = error.response.status;
                    errorProcess(error_status, error);
                } else {
                    error_status = 0;
                }
                errorProcess(error_status, error);
            });
    };

    useEffect(() => {
        if (sCurrentToken !== "" && !bQueryFirst) {
            getSignListData();
            setQueryFirst(true);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [sCurrentToken]);

    const changePage = (page: number) => {
        setNowPage(page);
    };

    // revice data
    const reviceSignList = (id: string, bCheck: boolean) => {
        const vId = vSignData.map((vSign) => vSign.id);
        const vNewData: vSignListOutput[] = vSignData.slice(0);
        const iIdx = vId.indexOf(id);

        vNewData[iIdx].sign = bCheck;
        setvSignData(vNewData);
    };

    // sort table data
    const sortSignData = (
        sortType: string,
        event: React.MouseEvent<HTMLTableHeaderCellElement>
    ): void => {
        let iSort = iSortNow;
        if (nowSortType !== sortType) {
            iSort = 1;
            setNowSortType(sortType);
        } else {
            iSort = -1 * iSort;
        }
        setvSignData(
            vSignData
                .sort((a: vSignListOutput, b: vSignListOutput) => {
                    return (a[sortType] > b[sortType] ? 1 : -1) * iSort;
                })
                .slice(0)
        );
        setSortNow(iSort);
    };

    // new context
    const vNewSignListContext = {
        ...vSignListContext,
        vSignContextData: vSignData,
        iSortNow,
        nowSortType,
        iNowPage,
        bQueryNow,
        sortSignData,
        reviceSignList,
        changePage,
        getSignListData,
    };

    return (
        <Provider value={vNewSignListContext}>
            <div>
                <div className="tab_content_title">待簽名名單</div>
            </div>
            <SignList />
        </Provider>
    );
};

export default SignListPage;
