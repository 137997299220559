import { createContext } from "react";
import { vSignListOutput } from "../utils/GeneBoxAPI";

export interface vTrContent {
    [x: string]: string | boolean | number;
    key_name: string;
    title: string;
    sortable: boolean;
    row_span: number;
    col_span: number;
    sign_list: boolean;
    report_list: boolean;
}

export const vTableColumn: vTrContent[] = [
    {
        key_name: "uid",
        title: "受檢編號",
        sortable: true,
        row_span: 2,
        col_span: 1,
        sign_list: true,
        report_list: true,
    },
    {
        key_name: "uname",
        title: "姓名",
        sortable: true,
        row_span: 2,
        col_span: 1,
        sign_list: true,
        report_list: true,
    },
    {
        key_name: "gender",
        title: "姓別",
        sortable: true,
        row_span: 2,
        col_span: 1,
        sign_list: true,
        report_list: true,
    },
    {
        key_name: "date",
        title: "送檢日期",
        sortable: true,
        row_span: 2,
        col_span: 1,
        sign_list: true,
        report_list: true,
    },
    {
        key_name: "disease_risk",
        title: "疾病風險",
        sortable: false,
        row_span: 1,
        col_span: 2,
        sign_list: true,
        report_list: true,
    },
    {
        key_name: "physique",
        title: "個人體質",
        sortable: false,
        row_span: 1,
        col_span: 1,
        sign_list: true,
        report_list: true,
    },
    {
        key_name: "check_all",
        title: "全選",
        sortable: false,
        row_span: 2,
        col_span: 1,
        sign_list: true,
        report_list: false,
    },
];

export const vTableColumn2: vTrContent[] = [
    {
        key_name: "high_risk_count",
        title: "高風險數量",
        sortable: true,
        row_span: 1,
        col_span: 1,
        sign_list: true,
        report_list: true,
    },
    {
        key_name: "medium_risk_count",
        title: "低風險數量",
        sortable: true,
        row_span: 1,
        col_span: 1,
        sign_list: true,
        report_list: true,
    },
    {
        key_name: "physique_count",
        title: "稍弱的基因數量",
        sortable: true,
        row_span: 1,
        col_span: 1,
        sign_list: true,
        report_list: true,
    },
];

export const vListKey: string[] = [
    vTableColumn[0].key_name,
    vTableColumn[1].key_name,
    vTableColumn[2].key_name,
    vTableColumn[3].key_name,
    vTableColumn2[0].key_name,
    vTableColumn2[1].key_name,
    vTableColumn2[2].key_name,
    vTableColumn[6].key_name,
];

const vSignContextData: vSignListOutput[] = [];

const SignListContext = createContext({
    vSignContextData,
    iSortNow: 1,
    nowSortType: "",
    iNowPage: 1,
    bQueryNow: false,
    sortSignData: (
        sortType: string,
        event: React.MouseEvent<HTMLTableHeaderCellElement>
    ): void => {},
    reviceSignList: (user_number: string, bCheck: boolean): void => {},
    changePage: (page: number): void => {},
    getSignListData: () => {},
});

export default SignListContext;
