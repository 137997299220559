import React, { useState, useContext } from "react";
import Modal from "../component/Modal";
import UserListContext from "../Context/UserListContext";
import DateContext from "../Context/DateContext";
import { DataType, vSystemType } from "../utils/GeneBoxAPI";

interface UserExportInput {
    SystemType: string;
    closeUserExport: () => void;
}

const UserExportModal = (props: UserExportInput) => {
    //props
    const { SystemType, closeUserExport } = props;

    // context
    const {
        downloadUser,
        vStatusContextCheckBoxForExport,
        vCheckContextStatus,
    } = useContext(UserListContext);
    const { sEndDefault, sGeneBoxStart, getPastDate } = useContext(DateContext);

    // state
    const [sStartDate, setStartDate] = useState(getPastDate(2, "month"));
    const [sEndDate, setsEndDate] = useState(sEndDefault);
    const [iDateType, setiDateType] = useState(DataType.checkDate);
    const [vStatusCheckBox, setStatusCheckBox] = useState(
        vStatusContextCheckBoxForExport
    );
    const [vCheckStatus, setCheckStatus] =
        useState<number[]>(vCheckContextStatus);

    const getStartDate = (event: React.FormEvent<HTMLInputElement>) => {
        const date = event.currentTarget.value;
        setStartDate(date);
    };

    const getEndDate = (event: React.FormEvent<HTMLInputElement>) => {
        const date = event.currentTarget.value;
        setsEndDate(date);
    };

    // click status check box
    const clickStatus = (idx: number, bStatus: boolean) => {
        // check box status
        if (SystemType === vSystemType[0]) return;
        let vNewCheckBoxes = vStatusCheckBox[SystemType].slice(0);
        if (vNewCheckBoxes[idx].state[0] === -1) {
            vNewCheckBoxes = vNewCheckBoxes.map((vNewCheckBox) => {
                vNewCheckBox.check = bStatus;
                return vNewCheckBox;
            });
        } else {
            vNewCheckBoxes[idx].check = bStatus;
            // 檢查是否要勾全選
            if (bStatus) {
                let bCheck = true;
                for (const vNewCheckBox of vNewCheckBoxes) {
                    if (!vNewCheckBox.check && vNewCheckBox.state[0] !== -1) {
                        bCheck = vNewCheckBox.check;
                        break;
                    }
                }
                vNewCheckBoxes[0].check = bCheck; // 全選
            } else {
                vNewCheckBoxes[0].check = false; // 全選
            }
        }
        vStatusCheckBox[SystemType] = vNewCheckBoxes;
        getvCheckStatus();
        setStatusCheckBox(vStatusCheckBox);
    };

    // get check state number
    const getvCheckStatus = () => {
        let vResult: number[] = [];
        let vFilterStatus = vStatusCheckBox[SystemType].filter(
            (vStatus) => vStatus.check
        );
        for (const vStatus of vFilterStatus) {
            vResult = vResult.concat(vStatus.state);
        }
        setCheckStatus(vResult);
    };

    const modalProps = {
        Md_Modal_title: <>匯出受檢者清單</>,
        Mdtitle: "",
        Mdcontent: (
            <div className="search_input">
                <div className="select_state">
                    <p className="search_list_bottom">選擇狀態 /</p>
                    <div>
                        {vStatusContextCheckBoxForExport[SystemType].map(
                            (vCheckBox, idx) => {
                                return (
                                    <label
                                        className={vCheckBox.class_name}
                                        key={vCheckBox.text}
                                    >
                                        <input
                                            type="checkbox"
                                            checked={vCheckBox.check}
                                            onChange={() =>
                                                clickStatus(
                                                    idx,
                                                    !vCheckBox.check
                                                )
                                            }
                                        />
                                        {vCheckBox.text}
                                    </label>
                                );
                            }
                        )}
                    </div>
                </div>

                <div style={{ marginBottom: 10, width: "100%" }}>
                    選擇匯出時間區間 /
                </div>

                <div className="date_type">
                    <span
                        className={
                            iDateType === DataType.checkDate
                                ? "active"
                                : "unactive"
                        }
                        onClick={() => setiDateType(DataType.checkDate)}
                    >
                        送檢日期
                    </span>
                    <span
                        className={
                            iDateType === DataType.downloadDate
                                ? "active"
                                : "unactive"
                        }
                        onClick={() => setiDateType(DataType.downloadDate)}
                    >
                        交付報告日期
                    </span>
                </div>

                <input
                    type="date"
                    className="date"
                    value={sStartDate}
                    onChange={getStartDate}
                    min={sGeneBoxStart}
                    max={sEndDefault}
                    style={{ width: 160, height: 24, padding: "0 8px" }}
                />
                <span className="date_dash">-</span>
                <input
                    type="date"
                    className="date"
                    value={sEndDate}
                    onChange={getEndDate}
                    min={sStartDate}
                    max={sEndDefault}
                    style={{ width: 160, height: 24, padding: "0 8px" }}
                />
            </div>
        ),
        MdBtnType: 1,
        Mdtitle_bgcolor: "bg_blue_green",
        MdsubmitFun: () => {
            const state = vCheckStatus.includes(-1)
                ? null
                : vCheckStatus.join(",");

            closeUserExport();
            downloadUser(sStartDate, sEndDate, state, iDateType);
            clickStatus(0, false);
        },
        MdcloseFun: () => {
            closeUserExport();
            clickStatus(0, false);
        },
    };

    return (
        <>
            <Modal {...modalProps} />
        </>
    );
};

export default UserExportModal;
