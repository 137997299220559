import React, { useState, useEffect, useRef } from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import MessageContext from "./Context/MessageContext";
import LoginInfoContext, { InitRoleData } from "./Context/LoginInfoContext";
import {
    loginUser,
    logoutUser,
    vTokenData,
    vSystemType,
    role,
} from "./utils/GeneBoxAPI";

import Head from "./Head/Head";
import Logout from "./Head/Logout";
import Tab from "./component/Tab";
import jwtDecode from "jwt-decode";
import SystemType from "./Head/SystemType";
import Loading from "./component/Loading";
import routeInfo, {
    addUserList,
    newRouteInfo,
    returnRouteInfo,
    RouteType,
} from "./router";
import Login from "./Login";
import Redirect from "./component/Redirect";
import { deepEqualObject } from "./utils/DataProcessor";

const App = () => {
    //login
    const bAuth = useRef(false);
    const currentSession = useRef("");

    const [bShowAlert, setShowAlert] = useState(false);
    const [loginState, setLoginState] = useState(false); // 是否登入
    const [currentUser, setCurrentUser] = useState(""); // 使用者名字???
    const [sCurrentToken, setCurrentToken] = useState(""); // 系統 token
    const [bLoading, setbLoading] = useState(false);
    const [sOrgIdx, setsOrgIdx] = useState(""); // 使用者名字???
    const [bAdmin, setbAdmin] = useState(true); // 是否為系統管理員
    const [roleData, setroleData] = useState<role>(InitRoleData);

    // key : vSystemType note: 加上權限
    const [vSystemInfo, setSystemInfo] = useState<{
        [x: string]: { type: vSystemType; text: string; authorize: boolean };
    }>({
        genebox: {
            type: vSystemType.genebox,
            text: "基因寶",
            authorize: false,
        },
        gutflora: {
            type: vSystemType.gutflora,
            text: "腸菌寶",
            authorize: false,
        },
        nips: {
            type: vSystemType.nips,
            text: "NIPS",
            authorize: false,
        },
    });

    const click_logout = useRef<HTMLButtonElement>(null);

    const [bloginshowFail, setbloginshowFail] = useState(false);
    const [bshowFail, setbshowFail] = useState(false);
    const [allRouteInfo, setallRouteInfo] = useState<RouteType[]>(
        newRouteInfo()
    );

    const sSystem = useRef(vSystemType.blank);

    // *** localStorage start *** //
    const saveRefreshToken = (refresh_token: string) => {
        window.localStorage.setItem("currentSession", refresh_token);
    };
    const removeRefreshToken = () => {
        window.localStorage.removeItem("currentSession");
    };
    // *** localStorage end *** //

    const login = (x: string) => {
        currentSession.current = x;
        loginUser(x)
            .then((res) => {
                const userToken: vTokenData = jwtDecode(res.token);

                setbAdmin(userToken.is_admin);
                setCurrentUser(userToken.org_name);
                setLoginState(true);
                bAuth.current = true;
                setCurrentToken(res.token);
                setbLoading(false);
                setsOrgIdx(userToken.org_name);
                setroleData(userToken.role_data);
                saveRefreshToken(currentSession.current);
            })
            .catch((error) => {
                setbLoading(false);
                setbloginshowFail(true);
                console.error(error);
                logoutCallback();
            });
    };

    // login
    const loginCallback = async (x: string) => {
        //20210916
        setbLoading(true);
        await login(x);
    };
    // logout
    const logoutCallback = () => {
        bAuth.current = false;
        currentSession.current = "";
        setLoginState(false);
        setCurrentUser("");
        setCurrentToken("");
        sSystem.current = vSystemType.blank;
        removeRefreshToken();
        setallRouteInfo(routeInfo); //還原最初的router
        setbLoading(false);
    };

    const logout = () => {
        logoutCallback();
        logoutUser(sCurrentToken) // undo: res
            .then((res) => {})
            .catch((error) => console.error(error));
    };

    // 處理 axios catch
    const errorProcess = (errorStatus: number, errorMes: Object) => {
        if (errorStatus === 401) {
            if (click_logout.current !== null) {
                click_logout.current.click();
            }
        } else {
            setbshowFail(true);
            console.error(errorMes);
        }
    };

    const getUrlDefault = (roleData: role) => {
        if (sSystem.current === 0) return "";
        if (!bAuth.current) return "";
        let url = "";
        const vendorKeys = Object.keys(roleData.projects.genebox.vendor);
        for (const key of vendorKeys) {
            if (!roleData.projects[vSystemType[sSystem.current]].vendor[key])
                continue;
            //vt 特約 true 且 project的特約 也為true 才顯示
            if (
                roleData.projects[vSystemType[sSystem.current]].vendor[key]
                    .worklist
            ) {
                if (url === "")
                    url = `/specimen/${key.toLowerCase()}/${
                        vSystemType[sSystem.current]
                    }`;
            } else if (
                roleData.projects[vSystemType[sSystem.current]].vendor[key]
                    .appointed
            ) {
                if (url === "")
                    url = `/report_list/${key.toLowerCase()}/${
                        vSystemType[sSystem.current]
                    }`;
            }
        }
        if (url === "") {
            if (roleData.projects[vSystemType[sSystem.current]].upload) {
                url = `/upload/${vSystemType[sSystem.current]}`;
            } else if (
                roleData.projects[vSystemType[sSystem.current]].report_exception
            ) {
                url = `/except/${vSystemType[sSystem.current]}`;
            } else if (
                roleData.projects[vSystemType[sSystem.current]].pmc_sign
            ) {
                url = `pmc_sign/${vSystemType[sSystem.current]}`;
            } else if (
                roleData.projects[vSystemType[sSystem.current]].doctor_sign
            ) {
                url = `doctor_sign/${vSystemType[sSystem.current]}`;
            } else {
                //接對應不到 則導回login
                logoutCallback();
            }
        }

        return url;
    };

    useEffect(() => {
        const currentSessionLocal =
            window.localStorage.getItem("currentSession");
        if (currentSession.current === "" && currentSessionLocal === null) {
            logoutCallback();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentSession]);

    const getNewSystem = () => {
        //改寫拿取可以的系統 也就是authorize 為 true的
        const vAuthorizeType = Object.keys(roleData.projects).filter((item) => {
            return roleData.projects[item].Access === true;
        });
        if (vAuthorizeType.length === 0) return;

        let sNewSystem = vSystemInfo[vAuthorizeType[0]].type;
        if (vAuthorizeType.includes(vSystemType[1])) {
            sNewSystem = vSystemInfo[vSystemType[1]].type;
        } else if (vAuthorizeType.includes(vSystemType[2])) {
            sNewSystem = vSystemInfo[vSystemType[2]].type;
        } else if (vAuthorizeType.includes(vSystemType[3])) {
            sNewSystem = vSystemInfo[vSystemType[3]].type;
        }

        sSystem.current = sNewSystem;
    };

    const showAlert = (bAlert: boolean) => {
        setShowAlert(bAlert);
    };

    const Message = {
        bShowAlert,
        showAlert,
    };

    const vLoginProvider = {
        roleData: roleData,
        isHidden: loginState,
        currentUser,
        sCurrentToken,
        sOrgIdx,
        bAdmin,
        sSystem,
        setbLoading,
        setCurrentToken,
        loginCallback,
        logoutCallback,
        logout,
        errorProcess,
        getNewSystem,
        getUrlDefault,
    };

    // login fail
    const loginFailMes = () => {
        return (
            <>
                <div className="message_page">
                    <div className="dialog">
                        <div id="dialog_title" className="bg_red"></div>
                        <div className="dialog_content">
                            <img src={`/img/cross_red.png`} alt="" />
                            <div className="text_title">登入失敗</div>
                            <div className="text_subtitle"></div>
                            <input
                                type="button"
                                value="確認"
                                className="button_style button_yellow"
                                onClick={() => setbloginshowFail(false)}
                            />
                        </div>
                    </div>
                </div>
            </>
        );
    };
    const FailMes = () => {
        return (
            <>
                <div className="message_page">
                    <div className="dialog">
                        <div id="dialog_title" className="bg_red"></div>
                        <div className="dialog_content">
                            <img src={`/img/cross_red.png`} alt="" />
                            <div className="text_title">載入失敗</div>
                            <div className="text_subtitle"></div>
                            <input
                                type="button"
                                value="確認"
                                className="button_style button_yellow"
                                onClick={() => setbshowFail(false)}
                            />
                        </div>
                    </div>
                </div>
            </>
        );
    };

    const getTabListLength = (routeInfo: RouteType[]) => {
        const vTabLi = routeInfo.filter((route) => {
            return (
                route.bTab &&
                route.authorize.includes(vSystemType[sSystem.current])
            );
        });
        return vTabLi.length;
    };

    useEffect(() => {
        const currentSessionLocal =
            window.localStorage.getItem("currentSession");
        if (currentSessionLocal !== null) {
            if (currentSessionLocal !== "") loginCallback(currentSessionLocal);
        }
        getNewSystem();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (roleData === undefined) return;
        if (deepEqualObject(roleData, InitRoleData)) {
            return;
        }
        const InitRoute = returnRouteInfo(roleData);
        const vDealerRouter = addUserList(roleData);
        const tempRouterInfo = InitRoute.concat(vDealerRouter);

        setallRouteInfo((prev) => {
            let temp = prev || [];
            temp = tempRouterInfo;
            console.log("temp", temp);
            return temp;
        });
        setSystemInfo((prev) => {
            let newSys = Object.assign({}, prev);
            newSys.genebox.authorize = roleData.projects.genebox.Access;
            newSys.gutflora.authorize = roleData.projects.gutflora.Access;
            newSys.nips.authorize = roleData.projects.nips.Access;
            return newSys;
        });
        getNewSystem();
        getUrlDefault(roleData);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [roleData]);

    return (
        <LoginInfoContext.Provider value={vLoginProvider}>
            <MessageContext.Provider value={Message}>
                <Router>
                    <Switch>
                        {allRouteInfo.map((route, i) => {
                            if (!bAuth.current || roleData === undefined) {
                                return (
                                    <Route
                                        key={i}
                                        render={() => (
                                            <>
                                                <Head subject="">
                                                    <Logout
                                                        click_logout={
                                                            click_logout
                                                        }
                                                    />
                                                </Head>
                                                <Login />
                                            </>
                                        )}
                                    ></Route>
                                );
                            }
                            const { path, exact, bTab } = route;
                            let routeComponents: JSX.Element = <></>;
                            let TabLength = getTabListLength(allRouteInfo);
                            if (!bTab || TabLength === 1) {
                                routeComponents = <route.component />;
                            } else {
                                routeComponents = (
                                    <>
                                        <Tab allRouteInfo={allRouteInfo} />
                                        <div className="tab_content_1">
                                            <route.component />
                                        </div>
                                    </>
                                );
                            }
                            return (
                                <Route
                                    key={i}
                                    path={path}
                                    exact={exact}
                                    render={() => (
                                        <>
                                            <Head subject="">
                                                <>
                                                    <SystemType
                                                        SystemInfo={vSystemInfo}
                                                    />
                                                    <Logout
                                                        click_logout={
                                                            click_logout
                                                        }
                                                    />
                                                </>
                                            </Head>
                                            <div className="container tab_container">
                                                {routeComponents}
                                            </div>
                                        </>
                                    )}
                                />
                            );
                        })}

                        {/* 404 */}
                        <Route path="*">
                            <Head subject="">
                                <>
                                    <Logout click_logout={click_logout} />
                                </>
                            </Head>
                            <Redirect />
                        </Route>
                    </Switch>
                </Router>
                {bloginshowFail && loginFailMes()}
                {bshowFail && FailMes()}
                {bLoading && <Loading />}
            </MessageContext.Provider>
        </LoginInfoContext.Provider>
    );
};

export default App;
