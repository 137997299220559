import React, { useState, useRef, useContext, useEffect } from "react";
import Alert from "../component/Message";
import MessageContext, { vMsgProps } from "../Context/MessageContext";
import {
    UploadFilesPosturl,
    UploadFilezip,
    UploadFilesignedurl,
    vUploadFile,
    vSystemType,
} from "../utils/GeneBoxAPI";
import LoginInfoContext from "../Context/LoginInfoContext";
import { useParams } from "react-router-dom";
interface url {
    type: string;
}

const UploadFile = () => {
    //url
    const { type: SystemType }: url = useParams();

    useEffect(() => {
        resetFile();
    }, [SystemType]);

    const [dragging, setDrag] = useState(false);
    const { bShowAlert, showAlert } = useContext(MessageContext);
    const [alertProps, setAlertContent] = useState<vMsgProps>({
        title: "",
        sub_title: "",
        content: <></>,
        iMsg: 0,
        bBtnType: true,
        submitFun: () => {},
    });
    const { title, sub_title, content, iMsg, bBtnType, submitFun } = alertProps;

    //GCP USE
    let uploadurl: string = "";
    let blobname: string = "";

    const [fileName, setFileName] = useState("");
    const [file, setFile] = useState<Blob | null>(null);
    const fileEl = useRef<HTMLInputElement>(null);
    const resetInput = useRef<HTMLFormElement>(null);
    const { sCurrentToken, setCurrentToken, setbLoading, errorProcess } =
        useContext(LoginInfoContext);

    // 取消預設事件
    const overrideEventDefaults = (
        event: Event | React.DragEvent<HTMLDivElement>
    ): void => {
        event.preventDefault();
        event.stopPropagation();
    };

    const dragenterListener = (
        event: React.DragEvent<HTMLInputElement>
    ): void => {
        overrideEventDefaults(event);
        if (event.dataTransfer.items && event.dataTransfer.items[0]) {
            setDrag(true);
        }
    };

    const dropFile = (event: any): void => {
        overrideEventDefaults(event);
        const file = event.dataTransfer.files;
        setDrag(false);
        const bCheckResult = checkFile(file[0].name);
        if (bCheckResult && file) {
            setFileName(file[0].name);
            setFile(file[0]);
        }
    };

    const clickFileEl = () => {
        if (fileEl.current !== null) {
            fileEl.current.click();
        }
    };

    const choseFile = (event: any) => {
        const file = event.target.files;
        const bCheckResult = file && file[0] ? checkFile(file[0].name) : false;
        if (bCheckResult && file) {
            setFileName(file[0].name);
            setFile(file[0]);
        }
    };

    const resetFile = () => {
        setFile(null);
        setFileName("");
        //reset file input
        (document.getElementById("up_progress") as HTMLInputElement).value = "";
    };

    useEffect(() => {
        //stop bubbling
        let passwordEvent = document.getElementById("password");
        if (passwordEvent !== null) {
            passwordEvent.addEventListener(
                "click",
                function (event) {
                    event.stopPropagation();
                },
                false
            );
        }
    }, [file]);

    // click upload file
    const uploadFile_check = () => {
        let password = getpassword();
        if (fileName === "" || file === null) {
            // show alert
            showAlert(true);
            setAlertContent({
                ...alertProps,
                title: "請先選擇檔案",
                sub_title: "檔案不能為空",
                content: <></>,
                iMsg: 2,
            });
            return false;
        } else if (password === "" && SystemType === vSystemType[1]) {
            // show alert
            showAlert(true);
            setAlertContent({
                ...alertProps,
                title: "請輸入密碼",
                sub_title: "",
                content: <></>,
                iMsg: 2,
            });
            return false;
        } else {
            //gcp upload file0
            setbLoading(true);
            //get url and blobName
            let filename = "";
            if (SystemType === vSystemType[3]) {
                filename = fileName.split(".pdf")[0];
            } else if (SystemType === vSystemType[2]) {
                filename = fileName.split(".xlsx")[0];
            } else {
                filename = fileName.split(".zip")[0];
            }
            UploadFilesignedurl(filename, SystemType, sCurrentToken)
                .then((res) => {
                    uploadurl = res.url;
                    blobname = res.blobname;
                    setCurrentToken(res.token);
                    //can't find ID
                    if (res.respCode === 412) {
                        showAlert(true);
                        setAlertContent({
                            ...alertProps,
                            title: "上傳失敗",
                            sub_title: "",
                            content: <div>{res.respDesc.split("$")[0]}</div>,
                            iMsg: 1,
                        });
                        setbLoading(false);
                    } else {
                        uploadFile();
                    }
                })
                .catch((error) => {
                    showAlert(true);
                    setAlertContent({
                        ...alertProps,
                        title: "上傳失敗",
                        sub_title: "",
                        content: <></>,
                        iMsg: 1,
                    });
                    resetFile();
                    setbLoading(false);
                    let error_status: number;
                    if (error.response !== undefined) {
                        error_status = error.response.status;
                        errorProcess(error_status, error);
                    } else {
                        error_status = 0;
                    }
                    errorProcess(error_status, error);
                });
            if (resetInput.current !== null) {
                resetInput.current.reset();
            }
        }
    };

    // 含有未登錄名單
    const notLogList = (list: string[]) => {
        const content = list.map((item) => <span>{item}</span>);
        return (
            <>
                <div className="text_subtitle">
                    <span>內有</span>
                    <span className="text_red">{list.length}筆</span>
                    <span>資料未登錄，請先完成登錄再上傳檔案</span>
                </div>
                <div className="derection_line_gray"></div>
                <div className="text_content">
                    <div>
                        <span>未登錄名單</span>
                    </div>
                    <div className="dialog_content_item ">{content}</div>
                </div>
            </>
        );
    };

    // 409 : 全部受檢者重複
    const duplicatefile = (list: string[]) => {
        const content = list.map((item) => <span>{item}</span>);
        return (
            <>
                <div className="text_subtitle">
                    <span>內有</span>
                    <span className="text_red">{list.length}筆</span>
                    <span>資料重複</span>
                </div>
                <div className="derection_line_gray"></div>
                <div className="text_content">
                    <div>
                        <span>重複名單</span>
                    </div>
                    <div className="dialog_content_item ">{content}</div>
                </div>
            </>
        );
    };

    // 400 : 資料欄位不符合或是有缺失
    const formatError4xx = (respDesc: string) => {
        return <div className="text_subtitle">{respDesc}</div>;
    };

    // 500 : 少檔案 "missing files" & 欄位錯誤 "column name error"
    const formatError5xx = (respDesc: string) => {
        let mes_content = "";
        if (respDesc === "missing files") {
            mes_content = "zip檔案內檔案不全，請確認後再上傳";
        } else if (respDesc === "column name error") {
            mes_content = "Excel 內欄位名稱錯誤，請確認後再上傳";
        }
        return <div className="text_subtitle">{mes_content}</div>;
    };

    const uploadFile = () => {
        if (fileName === "" || file === null) {
            return false;
        } else {
            var formData = new FormData();
            formData.append("file", file);
            //try not use formData
            UploadFilesPosturl(uploadurl, formData)
                .then(() => {
                    if (SystemType === vSystemType[3]) {
                        showResponse({
                            token: sCurrentToken,
                            data_count: 1,
                            error_list: [],
                            respDesc: "OK",
                            respCode: 200,
                        });
                        if (resetInput.current !== null) {
                            resetInput.current.reset();
                        }
                    } else {
                        postuploadzip();
                    }
                })
                .catch(() => {
                    resetFile();
                    showAlert(true);
                    setAlertContent({
                        ...alertProps,
                        title: "上傳失敗",
                        sub_title: "",
                        content: <></>,
                        iMsg: 1,
                    });
                    setbLoading(false);
                });
        }
    };

    const postuploadzip = () => {
        let password = getpassword();
        UploadFilezip(blobname, password, SystemType, sCurrentToken)
            .then((res) => {
                showResponse(res);
                if (resetInput.current !== null) {
                    resetInput.current.reset();
                }
            })
            .catch((error) => {
                console.error(error);
                showAlert(true);
                setAlertContent({
                    ...alertProps,
                    title: "上傳失敗",
                    sub_title: "",
                    content: <></>,
                    iMsg: 1,
                });
                resetFile();
                setbLoading(false);
            });
    };

    // 上傳zip or pdf  api 回傳訊息
    const showResponse = (res: vUploadFile) => {
        setCurrentToken(res.token);
        const { error_list, respCode, data_count, respDesc } = res;
        if ((respCode === 200 || respCode === 204) && error_list.length === 0) {
            // 只要有一筆上傳成功
            resetFile();
            showAlert(true);
            setAlertContent({
                ...alertProps,
                title: "上傳成功",
                sub_title: `成功上傳${data_count}筆資料，資料處理中，請耐心等候`,
                content: <></>,
                iMsg: 0,
            });
        } else {
            let mes_content = <></>;
            if (respCode === 500) {
                // 少檔案 "missing files" & 欄位錯誤 "column name error"
                mes_content = formatError5xx(respDesc);
            } else if (respCode === 400) {
                mes_content = formatError4xx(respDesc);
            } else if (respCode === 409) {
                // 全都是未登錄名單 "duplicate file"
                mes_content = duplicatefile(error_list);
            } else if (respCode === 200) {
                // 部分未登錄名單
                mes_content = notLogList(error_list);
            }

            showAlert(true);
            setAlertContent({
                ...alertProps,
                title: "上傳失敗",
                sub_title: "",
                content: mes_content,
                iMsg: 1,
            });
            resetFile();
        }
        setbLoading(false);
    };

    const getpassword = () => {
        if (SystemType === vSystemType[1]) {
            return (document.getElementById("password") as HTMLInputElement)
                .value;
        }
        return "";
    };

    // 檢查檔案格式
    const checkFile = (name: string) => {
        if (
            name.indexOf(
                SystemType === vSystemType[3]
                    ? ".pdf"
                    : SystemType === vSystemType[2]
                    ? ".xlsx"
                    : ".zip"
            ) === -1
        ) {
            showAlert(true);
            setAlertContent({
                ...alertProps,
                title: "錯誤檔案格式",
                sub_title: `請上傳${
                    SystemType === vSystemType[3]
                        ? ".pdf"
                        : SystemType === vSystemType[2]
                        ? ".xlsx"
                        : ".zip"
                }檔案`,
                content: <></>,
                iMsg: 1,
            });
            return false;
        }
        return true;
    };

    return (
        <>
            <div className="upload_file">
                <div className="tab_content_title">上傳檔案</div>
                <div
                    id="dropdiv"
                    className={dragging ? "drag_over" : ""}
                    onDragEnter={dragenterListener}
                    onDrop={dropFile}
                    onDrag={overrideEventDefaults}
                    onDragStart={overrideEventDefaults}
                    onDragEnd={overrideEventDefaults}
                    onDragOver={overrideEventDefaults}
                    onDragLeave={overrideEventDefaults}
                    onClick={clickFileEl}
                >
                    <img src="/img/upload_gray.png" alt="upload" />
                    {!fileName ? (
                        <p>點擊或拖曳要上傳的檔案</p>
                    ) : SystemType === vSystemType[3] ||
                      SystemType === vSystemType[2] ? (
                        <div>
                            <p className="file_name">{fileName}</p>
                        </div>
                    ) : (
                        <>
                            <div className="margin_password">
                                <div>請輸入密碼:</div>
                                <div>
                                    <input
                                        type="password"
                                        id="password"
                                        name="password"
                                        className="password"
                                    ></input>
                                </div>
                                <div>
                                    <p className="file_name">{fileName}</p>
                                </div>
                            </div>
                        </>
                    )}
                    <form className="noShow" ref={resetInput}>
                        <input
                            id="up_progress"
                            className="noShow"
                            type="file"
                            ref={fileEl}
                            onChange={choseFile}
                        ></input>
                    </form>
                </div>
                <div>
                    <button
                        className="button_style button_yellow"
                        onClick={uploadFile_check}
                    >
                        上傳
                    </button>
                </div>
            </div>
            {bShowAlert && (
                <Alert
                    title={title}
                    sub_title={sub_title}
                    content={content}
                    iMsg={iMsg}
                    bBtnType={bBtnType}
                    submitFun={submitFun}
                />
            )}
        </>
    );
};

export default UploadFile;
