import { createContext } from "react";
import { vUserReportListOutput, risk_list } from "../utils/GeneBoxAPI";

export interface vTable {
    key_name: string;
    title: string;
    sortable: boolean;
    col_span: number;
}

const vUserListContextData: vUserReportListOutput = {
    respCode: 0,
    respDesc: "",
    token: "",
    uid: "",
    gender: "",
    date: "",
    item_list: [],
};

const vTableColumn: vTable[] = [
    {
        key_name: "inspect_item",
        title: "檢測項目",
        sortable: false,
        col_span: 1,
    },
];

//for sorting data
const vUserRiskItem: risk_list[] = [
    {
        item: "",
        relation: 0,
        risk_value: "",
        risk_class: 0,
        gene_list: [],
        risk_legend: "",
        clinvar_table: {
            item_list: [],
        },
    },
];

const ReportContext = createContext({
    bQuery: false,
    iSortNow: 1,
    iNowPage: 1,
    nowSortType: "",
    vTableColumn,
    vUserListContextData,
    vUserRiskItem,
    sortReportListData: (sortType: string): void => {},
});

export default ReportContext;
