import React, { useState, useEffect, useContext } from "react";
import XLSX from "xlsx";
import { useParams, useLocation } from "react-router-dom";
import { uploadUserAPI, DataType } from "../utils/GeneBoxAPI";
import { vModalgProps } from "../Context/ModalContext";
import LoginInfoContext from "../Context/LoginInfoContext";
import DateContext from "../Context/DateContext";
import UserListContext from "../Context/UserListContext";

import Modal from "../component/Modal";
import UserAddUpload from "./UserAddModal/UserAddUpload";
import UserAddList from "./UserAddModal/UserAddList";

interface UserAddInput {
    closeUserAdd: () => void;
}

export interface userExcel {
    項號: string;
    姓名: string;
    受檢編號: string;
    性別: string;
    送檢日期: string;
    訂購套組: string;
    備註?: string;
}
interface url {
    type: string;
}
const UserAddModal = (props: UserAddInput) => {
    const { type: SystemType }: url = useParams();
    const location = useLocation();
    const userOrganization = location.pathname.split("/")[2];
    //context
    const { sCurrentToken, setCurrentToken, errorProcess, setbLoading } =
        useContext(LoginInfoContext);
    const {
        sStartDate,
        sEndDate,
        showMessageAlert,
        showErrorfileMessageAlert,
        getUserListData,
    } = useContext(UserListContext);
    const { getPastDate } = useContext(DateContext);

    //props
    const { closeUserAdd } = props;

    //state
    const [vUserExcel, setUserExcel] = useState<userExcel[]>([]);
    const [MdalertProps, setMdAlertContent] = useState<vModalgProps>({
        Md_Modal_title: <></>,
        Mdtitle: "",
        Mdcontent: <></>,
        Mdtitle_bgcolor: "",
        MdBtnType: 0,
        MdsubmitFun: () => {},
        MdcloseFun: () => {},
    });
    const [sWaringText, setWarningText] = useState("");
    const [file, setFile] = useState<Blob | null>(null);

    const getExcelData = (data: userExcel[]) => {
        setUserExcel(data);
    };

    const ADDsetFile = (file: Blob | null) => {
        setFile(file);
    };

    const setWarning = (Warning: string) => {
        setWarningText(Warning);
    };

    // 檢查檔案格式
    const checkFileFormate = (name: string) => {
        if (name.indexOf(".xls") === -1 && name.indexOf(".xlsx") === -1) {
            setWarningText("檔案格式錯誤, 請選擇excel檔案");
            setUserExcel([]);
            return false;
        }
        return true;
    };

    // 檢查檔案內容
    const checkFileContent = (vUserData: userExcel[]) => {
        const iMinDate = new Date("2020/10/01").getTime();
        const iMaxDate = new Date(getPastDate(-7, "date")).getTime();
        const vColumn = [
            "項號",
            "姓名",
            "受檢編號",
            "性別",
            "送檢日期",
            "訂購套組",
        ];
        const vGender = ["女", "男"];
        const vExcelUserId: string[] = [];
        let vResult = {
            bResult: true,
            error_type: 0,
            error_row: "",
            error_text: "",
            column: "",
        };

        for (const iUser in vUserData) {
            // 檢查 欄位是否為空
            const vUser = vUserData[iUser];
            const vKey = Object.keys(vUser);
            vResult.error_row = (parseInt(iUser) + 2).toString();
            vResult.error_type = 1;

            for (const sColumn of vColumn) {
                if (vKey.indexOf(sColumn) === -1) {
                    vResult = {
                        ...vResult,
                        bResult: false,
                        column: sColumn,
                    };
                    break;
                }
            }

            // 格式檢查: 項號 姓名 受檢編號 性別 日期
            vResult.error_type = 2;
            if (parseInt(vUser["項號"]) !== parseInt(iUser) + 1) {
                vResult = {
                    ...vResult,
                    bResult: false,
                    column: "項號",
                    error_text: "需從1開始編號且不可跳號",
                };
            }
            // 2020.11.10 不檢查受檢編號長度
            // if (vUser["受檢編號"].length > 8 || vUser["受檢編號"].length < 8) {
            //     vResult = {
            //         ...vResult,
            //         bResult: false,
            //         column: "受檢編號",
            //         error_text: "不符格式",
            //     };
            //     break;
            // }

            if (vGender.indexOf(vUser["性別"]) === -1) {
                vResult = {
                    ...vResult,
                    bResult: false,
                    column: "性別",
                    error_text: "僅能填入[男/女]",
                };
                break;
            }

            // UNIX time
            vUser["送檢日期"] = ExcelDateToJSDate(
                parseInt(vUser["送檢日期"])
            ).toString();
            const iExcelDate = new Date(vUser["送檢日期"]).getTime();
            if (iMinDate > iExcelDate || iMaxDate < iExcelDate) {
                vResult = {
                    ...vResult,
                    bResult: false,
                    column: "送檢日期",
                    error_text: "格式或時間區間錯誤",
                };
                break;
            }

            // check: Uid should be unique
            if (vExcelUserId.indexOf(vUser["受檢編號"]) > -1) {
                vResult = {
                    ...vResult,
                    bResult: false,
                    column: "受檢編號",
                    error_text: `${vUser["受檢編號"]}重複`,
                };
                break;
            }
            vExcelUserId.push(vUser["受檢編號"]);
        }
        return vResult;
    };

    // read excel
    const readExcel = () => {
        return new Promise((resolve, reject) => {
            var fileReader = new FileReader();
            fileReader.onload = function (ev) {
                if (!(ev && ev.target)) return;
                const data = ev.target.result;
                const workbook = XLSX.read(data, { type: "binary" });
                const vSheetName = Object.keys(workbook.Sheets);
                const sSheetNameFirst = vSheetName[0];
                const reg = /^[0-9]+.?[0-9]*$/;
                let vUserData: userExcel[] = [];
                if (workbook.Sheets.hasOwnProperty(sSheetNameFirst)) {
                    // get excel data
                    vUserData = XLSX.utils.sheet_to_json(
                        workbook.Sheets[sSheetNameFirst],
                        {
                            range: 1,
                        }
                    );

                    // 篩去非受檢清單內容
                    vUserData = vUserData.filter((item: userExcel) => {
                        return reg.test(item["項號"]);
                    });
                }
                // 檢查內容
                const vCheckContent = checkFileContent(vUserData);
                if (vCheckContent.bResult) {
                    getExcelData(vUserData);
                    setWarningText("");
                } else {
                    if (vCheckContent.error_type === 1) {
                        setWarningText(
                            `第${vCheckContent.error_row}列缺少[${vCheckContent.column}]欄位`
                        );
                    } else if (vCheckContent.error_type === 2) {
                        setWarningText(
                            `第${vCheckContent.error_row}列[${vCheckContent.column}]欄位${vCheckContent.error_text}`
                        );
                    }
                    setUserExcel([]);
                }
            };

            //Use binary to open file
            if (file) {
                return fileReader.readAsBinaryString(file);
            }
        });
    };

    //excel date UNIX time ( 1900/01/01 )
    const ExcelDateToJSDate = (serial: number) => {
        const utc_days = Math.floor(serial - 25569);
        const utc_value = utc_days * 86400;
        const date_info = new Date(utc_value * 1000);
        const fractional_day = serial - Math.floor(serial) + 0.0000001;
        let total_seconds = Math.floor(86400 * fractional_day);
        const seconds = total_seconds % 60;
        total_seconds -= seconds;
        return new Date(
            date_info.getFullYear(),
            date_info.getMonth(),
            date_info.getDate()
        ).toLocaleDateString();
    };

    useEffect(() => {
        readExcel();
    }, [file]); // eslint-disable-line react-hooks/exhaustive-deps
    //axios post file
    const upload_user_file = (file: Blob | null) => {
        if (file != null) {
            closeUserAdd();
            setbLoading(true);
            var formData = new FormData();
            formData.append("file", file);
            uploadUserAPI(formData, sCurrentToken)
                .then((res) => {
                    setCurrentToken(res.token);
                    showMessageAlert(res);
                    setbLoading(false);
                })
                .catch((error) => {
                    getUserListData(
                        sStartDate,
                        sEndDate,
                        DataType.checkDate,
                        SystemType,
                        userOrganization
                    );
                    setbLoading(false);
                    let error_status: number;
                    if (error.response !== undefined) {
                        error_status = error.response.status;
                        errorProcess(error_status, error);
                    } else {
                        error_status = 0;
                    }
                    errorProcess(error_status, error);
                });
        } else {
            showErrorfileMessageAlert("請選擇上傳檔案");
        }
    };

    //show modal
    const getModalProps = () => {
        let Md_Modal_title = <div>新增受試者</div>;
        let Mdtitle = "";
        let Mdcontent = (
            <div className="excel_upload">
                <UserAddUpload
                    getExcelData={getExcelData}
                    ADDsetFile={ADDsetFile}
                    file={file}
                    sWaringText={sWaringText}
                    checkFileFormate={checkFileFormate}
                    setWarningText={setWarning}
                    readExcel={readExcel}
                />
                <UserAddList vUserExcel={vUserExcel} />
            </div>
        );
        let Mdtitle_bgcolor = "bg_blue_green";
        let MdBtnType = 1;
        let MdsubmitFun = () => {
            //check file correct
            if (sWaringText === "") {
                upload_user_file(file);
            } else {
                showErrorfileMessageAlert("該檔案有誤,請選擇其他檔案");
            }
        };
        let MdcloseFun = closeUserAdd;

        setMdAlertContent({
            Md_Modal_title,
            Mdtitle,
            Mdcontent,
            Mdtitle_bgcolor,
            MdBtnType,
            MdsubmitFun,
            MdcloseFun,
        });
    };
    //re-render props
    useEffect(getModalProps, [vUserExcel, sWaringText]);

    return <Modal {...MdalertProps} />;
};

export default UserAddModal;
