import React from "react";
import { vSignListOutput } from "../utils/GeneBoxAPI";
import {
    vTrContent,
    vTableColumn,
    vTableColumn2,
} from "../Context/SignListContext";

interface GeneListProps {
    iSortNow: number;
    nowSortType: string;
    vListData: vSignListOutput[];
    vDataIndex: number[];
    sType: string;
    vKey: string[];
    iStartItem: number;
    iEndItem: number;
    bQueryNow: boolean;
    clickSortTh: (
        x: string,
        e: React.MouseEvent<HTMLTableHeaderCellElement>
    ) => void;
    clickList: (x: vSignListOutput, sKey: string) => void;
    bCheckAll?: boolean;
    addAllCheckedItem?: (x: boolean) => void;
    checkStatus?: (x: string) => boolean;
}

const GeneList = ({
    iSortNow,
    nowSortType,
    vListData,
    vDataIndex,
    sType,
    vKey,
    iStartItem,
    iEndItem,
    bQueryNow,
    clickSortTh,
    clickList,
    bCheckAll = false,
    addAllCheckedItem = (x: boolean) => {},
    checkStatus = (x: string) => false,
}: GeneListProps) => {
    // change sort class
    const getSortClass = (key_name: string) => {
        const sSortClass = iSortNow === 1 ? " order" : " order_reverse";
        return nowSortType === key_name ? sSortClass : "";
    };

    // show table title
    const showTableTitle = (vTableTitle: vTrContent[]) => {
        return (
            <tr className="bg_blue_green">
                {vTableTitle.map((vThContent: vTrContent) => {
                    if (!vThContent[sType]) return false;
                    const bCheckAllBtn = vThContent.key_name !== "check_all";
                    return vThContent.sortable ? (
                        <th
                            key={vThContent.key_name}
                            colSpan={vThContent.col_span}
                            rowSpan={vThContent.row_span}
                            onClick={(event) =>
                                clickSortTh(vThContent.key_name, event)
                            }
                            className={
                                "sort" + getSortClass(vThContent.key_name)
                            }
                        >
                            {vThContent.title}
                            <span></span>
                        </th>
                    ) : (
                        <th
                            key={vThContent.key_name}
                            colSpan={vThContent.col_span}
                            rowSpan={vThContent.row_span}
                            className={bCheckAllBtn ? "" : "check_all"}
                            onClick={() =>
                                bCheckAllBtn
                                    ? ""
                                    : addAllCheckedItem(!bCheckAll)
                            }
                        >
                            {bCheckAllBtn ? (
                                vThContent.title
                            ) : (
                                <>
                                    <input
                                        type="checkbox"
                                        checked={bCheckAll}
                                        onChange={() =>
                                            addAllCheckedItem(!bCheckAll)
                                        }
                                    />
                                    {vThContent.title}
                                </>
                            )}
                        </th>
                    );
                })}
            </tr>
        );
    };

    // show tr content
    const showTrContent = (vSignData: vSignListOutput, sKey: string) => {
        let result = <></>;
        switch (sKey) {
            // date
            case vTableColumn[3].key_name:
                result = <>{vSignData[sKey].split(" ")[0]}</>;
                break;
            // check_all
            case vTableColumn[6].key_name:
                result = (
                    <input
                        type="checkbox"
                        checked={checkStatus(vSignData.uid)}
                        onChange={() => clickList(vSignData, sKey)}
                    />
                );
                break;

            default:
                result = <>{vSignData[sKey]}</>;
                break;
        }
        return result;
    };

    return (
        <div className="table_container">
            <table className="list_table sign_table" cellPadding={5}>
                <thead>
                    {showTableTitle(vTableColumn)}
                    {showTableTitle(vTableColumn2)}
                </thead>
                <tbody>
                    {vListData.length === 0 || vDataIndex.length === 0 ? (
                        <tr>
                            {!bQueryNow ? (
                                <td colSpan={vKey.length}>尚無受檢者名單</td>
                            ) : (
                                <td colSpan={vKey.length}>Loading...</td>
                            )}
                        </tr>
                    ) : (
                        vDataIndex.map((iData, idx) => {
                            const vData = vListData[iData];
                            return idx < iStartItem || idx > iEndItem ? (
                                false
                            ) : (
                                <tr key={vData.uid}>
                                    {vKey.map((sKey) => {
                                        return (
                                            <td
                                                onClick={() =>
                                                    clickList(vData, sKey)
                                                }
                                                key={sKey}
                                            >
                                                {showTrContent(vData, sKey)}
                                            </td>
                                        );
                                    })}
                                </tr>
                            );
                        })
                    )}
                </tbody>
            </table>
        </div>
    );
};

export default GeneList;
