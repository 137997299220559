import React, { useContext, useEffect, useState } from "react";
import { useRef } from "react";
import { useHistory, useParams } from "react-router-dom";

import LoginInfoContext from "../Context/LoginInfoContext";
import { vSystemType } from "../utils/GeneBoxAPI";

interface props {
    SystemInfo: {
        [x: string]: {
            type: vSystemType;
            text: string;
            authorize: boolean;
        };
    };
}

const SystemType = ({ SystemInfo }: props) => {
    const history = useHistory();
    const { type }: { type: string } = useParams();
    const { sSystem, getUrlDefault, roleData } = useContext(LoginInfoContext);
    const [sSelectSystem, setSelectSystem] = useState(sSystem.current);
    const sPreSelectSystem = useRef(sSelectSystem);

    const changeSystem = (e: React.ChangeEvent<HTMLSelectElement>) => {
        const sNewSystem: vSystemType = SystemInfo[e.currentTarget.value].type;
        sSystem.current = sNewSystem;
        setSelectSystem(sNewSystem);
    };

    const changeUrl = () => {
        let url = getUrlDefault(roleData);
        history.push(url);
        sPreSelectSystem.current = sSelectSystem;
    };

    useEffect(() => {
        if (sSelectSystem === sPreSelectSystem.current) return;
        changeUrl();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [sSelectSystem]);

    return (
        <div className="system_type">
            <select
                className="select-primary"
                onChange={(e) => changeSystem(e)}
                value={type}
            >
                {Object.keys(SystemInfo).map((idx: string) => {
                    const { text, authorize } = SystemInfo[idx];

                    if (!authorize) return false;

                    return (
                        <option value={idx} key={idx}>
                            {text}
                        </option>
                    );
                })}
            </select>
        </div>
    );
};

export default SystemType;
