//gene_risk
//1: "低風險"
//2: "中風險"
//3: "高風險"
//theme_risk
//1: "低風險"
//2: "一般風險"
//3: "中風險"
//4: "高風險"
//constitution_risk
//1: "低"
//2: "一般"
//3: "中"
//4: "高"

import React, { useContext, useEffect, useState } from "react";
import ReportContext from "../Context/ReportContext";
import ModalAlert from "../component/Modal";
import { vModalgProps } from "../Context/ModalContext";
import { clinvar_list_item, risk_list } from "../utils/GeneBoxAPI";

interface ReportPageProps {
    tabPagename: string;
    maincolor: string;
}

const ReportList = (props: ReportPageProps) => {
    const { maincolor, tabPagename } = props;

    //modal
    const [MdalertProps, setMdAlertContent] = useState<vModalgProps>({
        Md_Modal_title: <></>,
        Mdtitle: "",
        Mdcontent: <></>,
        Mdtitle_bgcolor: "",
        MdBtnType: 0,
        MdsubmitFun: () => {},
        MdcloseFun: () => {},
    });
    const { Md_Modal_title, Mdtitle, Mdcontent, MdBtnType, Mdtitle_bgcolor } =
        MdalertProps;

    const [bMdShowAlert_list, setMdshowAlert_list] = useState(false);
    const [bGeneTableType, setbGeneTableType] = useState<boolean>(false);
    const [bSwitchAble, setbSwitchAble] = useState<boolean>(false);
    const [now_idx, setnow_idx] = useState<number>(-1);
    // let now_idx: number;
    let {
        bQuery,
        vUserRiskItem,
        vTableColumn,
        iSortNow,
        nowSortType,
        sortReportListData,
    } = useContext(ReportContext);

    // change sort class
    const getSortClass = (key_name: string) => {
        const sSortClass = iSortNow === 1 ? " order" : " order_reverse";
        return nowSortType === key_name ? sSortClass : "";
    };

    const handleTable = (value: boolean, idx: number) => {
        //false = gene ,true = clinvar
        setbGeneTableType(value);
        // return_MdAlertContent(idx);
    };

    const return_MdAlertContent = (now_idx: number) => {
        setswitchFn(vUserRiskItem[now_idx]);

        if (
            bGeneTableType === false &&
            (vUserRiskItem[now_idx].gene_list === null ||
                vUserRiskItem[now_idx].gene_list.length === 0)
        ) {
            return;
        }
        if (
            bGeneTableType === true &&
            vUserRiskItem[now_idx].clinvar_table === null
        ) {
            return;
        }
        const set_titile = () => {
            return (
                <>
                    <div>
                        <img
                            className="dialog_title_img"
                            alt=""
                            src="/img/arrow_left_square.png"
                            onClick={() => pre_item(now_idx)}
                        />
                        <img
                            className="dialog_title_img"
                            alt=""
                            src="/img/arrow_right_square.png"
                            onClick={() => next_item(now_idx)}
                        />
                    </div>
                    <div>{vUserRiskItem[now_idx].item}</div>
                    <div>
                        <img
                            alt=""
                            className="dialog_title_img"
                            src="/img/cross_white.png"
                            onClick={close_modal}
                        />
                    </div>
                </>
            );
        };
        const set_gene_list = () => {
            // check data gene and clinVar
            return (
                <>
                    <label className="switch">
                        <input
                            type="checkbox"
                            id="require"
                            checked={bGeneTableType}
                            onChange={(e) =>
                                handleTable(e.target.checked, now_idx)
                            }
                            disabled={bSwitchAble}
                        />
                        <div
                            className={`slider ${
                                bSwitchAble && bGeneTableType === false
                                    ? "genelist"
                                    : bSwitchAble && bGeneTableType === true
                                    ? "clinvar"
                                    : ""
                            }`}
                        >
                            <span>Gene</span>
                            <span>ClinVar</span>
                        </div>
                    </label>
                    {bGeneTableType ? (
                        <table
                            className="dialog_list_table gene_table_style"
                            cellPadding="5"
                        >
                            <thead className="bg_lightgrey">
                                <tr className="text_black">
                                    <th>基因名稱</th>
                                    <th>檢測點位數</th>
                                    <th>風險/帶因型(數量)</th>
                                    <th>基因說明</th>
                                    <th>判定</th>
                                </tr>
                            </thead>
                            <tbody>
                                {vUserRiskItem[
                                    now_idx
                                ].clinvar_table.item_list.map(
                                    (
                                        clinvaritem: clinvar_list_item,
                                        idx: number
                                    ) => {
                                        return (
                                            <tr
                                                key={`${clinvaritem.gene_info.name}_${idx}`}
                                            >
                                                <td>
                                                    {clinvaritem.gene_info.name}
                                                </td>
                                                <td>
                                                    {
                                                        clinvaritem.gene_info
                                                            .total
                                                    }
                                                </td>

                                                <td>{`${clinvaritem.gene_info.high}/${clinvaritem.gene_info.mid}`}</td>
                                                <td>
                                                    {
                                                        clinvaritem.gene_info
                                                            .explain
                                                    }
                                                </td>
                                                <td>
                                                    {
                                                        clinvaritem.gene_info
                                                            .clinvar_class
                                                    }
                                                </td>
                                            </tr>
                                        );
                                    }
                                )}
                            </tbody>
                        </table>
                    ) : (
                        <table
                            className="dialog_list_table gene_table_style"
                            cellPadding="5"
                        >
                            <thead className="bg_lightgrey">
                                <tr className="text_black">
                                    <th>基因名稱</th>
                                    <th>基因說明</th>
                                    <th>突變型</th>
                                    <th>帶因型</th>
                                    <th>一般型</th>
                                    <th>風險值</th>
                                </tr>
                            </thead>
                            <tbody>
                                {vUserRiskItem[now_idx].gene_list.map(
                                    (geneitem: any, idx: number) => {
                                        return (
                                            <tr
                                                key={`${geneitem.gene_name}_${idx}`}
                                            >
                                                <td>{geneitem.gene_name}</td>
                                                <td>{geneitem.gene_explain}</td>
                                                <td
                                                    className={
                                                        geneitem.risk_class ===
                                                        3
                                                            ? "bg_coralred text_white"
                                                            : ""
                                                    }
                                                >
                                                    {geneitem.high}
                                                </td>
                                                <td
                                                    className={
                                                        geneitem.risk_class ===
                                                        2
                                                            ? "bg_skyblue_1 text_white"
                                                            : ""
                                                    }
                                                >
                                                    {geneitem.general}
                                                </td>
                                                <td
                                                    className={
                                                        geneitem.risk_class ===
                                                        1
                                                            ? "bg_green text_white"
                                                            : ""
                                                    }
                                                >
                                                    {geneitem.low}
                                                </td>
                                                <td>{geneitem.risk}</td>
                                            </tr>
                                        );
                                    }
                                )}
                            </tbody>
                        </table>
                    )}
                </>
            );
        };

        setMdAlertContent({
            ...MdalertProps,
            Md_Modal_title: set_titile(),
            Mdcontent: set_gene_list(),
            Mdtitle_bgcolor: "bg_blue_green",
            MdBtnType: 2,
        });
    };

    const pre_item = (now_idx: number) => {
        setnow_idx(now_idx === 0 ? vUserRiskItem.length - 1 : now_idx - 1);
    };

    const next_item = (now_idx: number) => {
        setnow_idx(now_idx === vUserRiskItem.length - 1 ? 0 : now_idx + 1);
    };

    const setswitchFn = (vItem: risk_list) => {
        if (
            vItem.clinvar_table !== null &&
            (vItem.gene_list === null || vItem.gene_list.length === 0)
        ) {
            // only  clinvar
            setbGeneTableType(true);
            setbSwitchAble(true);
        } else if (vItem.clinvar_table === null && vItem.gene_list !== null) {
            // only gene
            setbGeneTableType(false);
            setbSwitchAble(true);
        }
    };

    const showgene_list = (item: risk_list) => {
        vUserRiskItem.map((vItem: risk_list, idx: number) => {
            if (vItem.item === item.item) {
                setMdshowAlert_list(true);
                setnow_idx(idx);
                return_MdAlertContent(idx);
            }
            return true;
        });
        return false;
    };

    useEffect(() => {
        if (now_idx !== -1) {
            setbGeneTableType(false);
            setbSwitchAble(false);
            return_MdAlertContent(now_idx);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [now_idx]);

    useEffect(() => {
        if (now_idx !== -1) {
            return_MdAlertContent(now_idx);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [bGeneTableType, bSwitchAble]);

    const close_modal = () => {
        setMdshowAlert_list(false);
    };

    return (
        <>
            <div className="table_container">
                <table className="list_table">
                    <thead>
                        <tr className={"bg_" + maincolor}>
                            {vTableColumn.map((vThContent) =>
                                vThContent.sortable ? (
                                    <th
                                        key={vThContent.key_name}
                                        colSpan={vThContent.col_span}
                                        onClick={() =>
                                            sortReportListData(
                                                vThContent.key_name
                                            )
                                        }
                                        className={
                                            "sort" +
                                            getSortClass(vThContent.key_name)
                                        }
                                    >
                                        {vThContent.title}
                                    </th>
                                ) : (
                                    <th
                                        key={vThContent.key_name}
                                        colSpan={vThContent.col_span}
                                    >
                                        {vThContent.title}
                                    </th>
                                )
                            )}
                            {/* 只有體質分析需要風險值 */}
                            {tabPagename === "physique" && (
                                <th key={"risk_value"} colSpan={1}>
                                    風險值
                                </th>
                            )}
                        </tr>
                    </thead>
                    <tbody>
                        {vUserRiskItem.length === 0 ? (
                            bQuery ? (
                                <tr>
                                    <td colSpan={8}>Loading...</td>
                                </tr>
                            ) : (
                                <tr>
                                    <td colSpan={8}>該風險等級無資料</td>
                                </tr>
                            )
                        ) : (
                            vUserRiskItem.map((vItem: risk_list) => {
                                return (
                                    <tr key={vItem.item}>
                                        <td
                                            className="inspect_item"
                                            onClick={() => showgene_list(vItem)}
                                        >
                                            {vItem.item}
                                        </td>
                                        {vItem.relation === 2 && (
                                            <td>{vItem.risk_legend}</td>
                                        )}
                                    </tr>
                                );
                            })
                        )}
                    </tbody>
                </table>
            </div>
            {bMdShowAlert_list && (
                <ModalAlert
                    Md_Modal_title={Md_Modal_title}
                    Mdtitle={Mdtitle}
                    Mdcontent={Mdcontent}
                    Mdtitle_bgcolor={Mdtitle_bgcolor}
                    MdBtnType={MdBtnType}
                    MdsubmitFun={() => {}}
                    MdcloseFun={close_modal}
                />
            )}
        </>
    );
};

export default ReportList;
