import { createContext } from "react";
import { getUserListOutput, vUserState } from "../utils/GeneBoxAPI";

export interface ExceptUser {
    uid: string;
    abnormal?: boolean;
    comment: string;
}
const vExceptContextData: getUserListOutput[] = [];

const vExceptUser: ExceptUser[] = [];

export interface exceptStatus {
    status: number;
    text: string;
}

export const vExceptOption: { [x: string]: exceptStatus[] } = {
    genebox: [
        {
            status: 0,
            text: "無異常",
        },
        {
            status: vUserState.abnormal_pick_again,
            text: "請重新採檢",
        },
        {
            status: vUserState.abnormal_detect_again,
            text: "重新晶片檢測中",
        },
        {
            status: vUserState.abnormal_other,
            text: "其他",
        },
    ],
    gutflora: [
        {
            status: 0,
            text: "無異常",
        },
        {
            status: vUserState.abnormal_pick_again,
            text: "請重新採檢",
        },
    ],
};

const ExceptContext = createContext({
    vExceptContextData,
    vExceptShowContextData: vExceptContextData,
    vTableColumn: [
        {
            key_name: "state",
            title: "狀態",
            sortable: false,
            col_span: 2,
            class_name: "",
        },
        {
            key_name: "uid",
            title: "受檢編號",
            sortable: true,
            col_span: 1,
            class_name: "",
        },
        {
            key_name: "org_name",
            title: "送檢單位",
            sortable: true,
            col_span: 1,
            class_name: "",
        },
        {
            key_name: "uname",
            title: "姓名",
            sortable: true,
            col_span: 1,
            class_name: "",
        },
        {
            key_name: "gender",
            title: "姓別",
            sortable: true,
            col_span: 1,
            class_name: "",
        },
        {
            key_name: "date",
            title: "送檢日期",
            sortable: true,
            col_span: 1,
            class_name: "",
        },
        {
            key_name: "abnormal",
            title: "異常選項",
            sortable: false,
            col_span: 1,
            class_name: "except_description",
        },
    ],
    nowSortType: "",
    iSortNow: 1,
    iNowPage: 1,
    bQueryNow: false,
    bQueryFirst: false,
    vExceptContentUser: vExceptUser,
    addExceptUser: (
        id: string,
        state: string,
        comment: string,
        abnormal?: boolean
    ): void => {},
    sortExceptData: (
        sortType: string,
        event: React.MouseEvent<HTMLTableHeaderCellElement>
    ): void => {},
    changePage: (page: number): void => {},
    filterData: (id: string, org_name: string, uname: string): void => {},
    getExceptData: (startDate: string, endDate: string): void => {},
    postUserComment: (): void => {},
    setbQueryFirst: (x: boolean): void => {},
});
export default ExceptContext;
