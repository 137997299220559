import React, { useState, useContext } from "react";
import ExceptContext, {
    exceptStatus,
    vExceptOption,
} from "../Context/ExceptContext";
import LoginInfoContext from "../Context/LoginInfoContext";
import { getUserListOutput, vUserState } from "../utils/GeneBoxAPI";

interface ExpeptReviceSelect {
    vPerson: getUserListOutput;
    SystemType: string;
}

const ExceptSelect = (props: ExpeptReviceSelect) => {
    const { vPerson, SystemType } = props;
    const [iStatus, setStatus] = useState(
        vPerson.error_message !== "" ? vUserState.abnormal_other : vPerson.state
    );
    const [bExcept, setExcept] = useState(false);

    const [sDescription, setDescription] = useState(
        vPerson.error_message !== "" ? vPerson.error_message : ""
    );

    const { roleData } = useContext(LoginInfoContext);
    const { addExceptUser } = useContext(ExceptContext);

    const getIndexOfStatus = (statusArray: exceptStatus[], status: number) => {
        return statusArray.findIndex((object) => object.status === status);
    };

    // 修改受檢者狀態
    const changeStatus = (event: React.FormEvent<HTMLSelectElement>) => {
        const iNewStatus = event.currentTarget.value;
        setStatus(parseInt(iNewStatus));
        addExceptUser(
            vPerson.uid,
            iNewStatus,
            parseInt(iNewStatus) === vUserState.abnormal_other
                ? ""
                : vExceptOption[SystemType][
                      getIndexOfStatus(
                          vExceptOption[SystemType],
                          parseInt(iNewStatus)
                      )
                  ].status.toString(),
            parseInt(iNewStatus) === vUserState.abnormal_other
                ? false
                : undefined
        );
    };

    // 加註說明
    const keyDescription = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
        const sNewDescription = event.currentTarget.value;
        setDescription(sNewDescription);
        addExceptUser(
            vPerson.uid,
            iStatus.toString(),
            sNewDescription,
            bExcept
        );
    };

    const changeAbnormal = (event: any) => {
        addExceptUser(
            vPerson.uid,
            iStatus.toString(),
            sDescription,
            event.target.checked
        );
        setExcept(event.target.checked);
    };

    const checkOtherPermission = (Option: exceptStatus) => {
        /* roleData.Functions.enable_comment false 時，不可以有"其他"選項 */
        if (
            Option.status === vUserState.abnormal_other &&
            (Object.keys(roleData.Functions).indexOf("enable_comment") === -1 ||
                roleData.Functions.enable_comment === false)
        ) {
            return false;
        }
        return true;
    };

    return (
        <>
            {vExceptOption[SystemType] !== undefined && (
                <select onChange={changeStatus} value={iStatus}>
                    {vExceptOption[SystemType].map(
                        (Option, idx) =>
                            checkOtherPermission(Option) && (
                                <option
                                    value={Option.status}
                                    key={Option.status}
                                >
                                    {Option.text}
                                </option>
                            )
                    )}
                </select>
            )}

            {iStatus === 3 && (
                <div className="except_abnormal_other">
                    <textarea
                        rows={5}
                        onChange={(e) => keyDescription(e)}
                        defaultValue={
                            vPerson.error_message === ""
                                ? sDescription
                                : vPerson.error_message
                        }
                        placeholder="撰寫說明(必填)"
                    />
                    <p>
                        <input
                            type="checkbox"
                            id={`change_except_` + vPerson.uid}
                            onChange={(e) => changeAbnormal(e)}
                        />
                        <label htmlFor={`change_except_` + vPerson.uid}>
                            更改為異常
                        </label>
                    </p>
                </div>
            )}
        </>
    );
};

export default ExceptSelect;
