import React, { useEffect, useState, useContext } from "react";
import FormControl from "@mui/material/FormControl";
import Checkbox from "@mui/material/Checkbox";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import { pdfMode, UserPdfMode } from "../utils/GeneBoxAPI";
import UserListContext from "../Context/UserListContext";

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

interface props {
    UID: string;
    allPDFFile: pdfMode[];
    // vUserPDFselect: UserPdfMode[];
    setUserPDFselect: React.Dispatch<React.SetStateAction<UserPdfMode[]>>;
}

export default function MultipleSelectCheckMarks({
    UID,
    allPDFFile,
    setUserPDFselect,
}: props) {
    //PDF id is string[]
    const [textFieldShowPDF, setTextFieldShowPDF] = useState<pdfMode[]>([]);
    const [UserIndex, setUserIndex] = useState<number>(-1);

    const selectValidPDF = allPDFFile.filter((item: pdfMode) => item.status);

    // context
    const { vUserPDFselect } = useContext(UserListContext);

    const onTagsChange = (event: any, values: any) => {
        if (UserIndex === -1) return;
        if (values.length === 0) {
            setUserPDFselect((prev) => {
                let temp: UserPdfMode[] = JSON.parse(JSON.stringify(prev));
                temp[UserIndex].mod_list = [];
                return temp;
            });
            setTextFieldShowPDF([]);
            return;
        }
        let allPDF = allPDFFile.filter(
            (item: pdfMode) => item.id !== "-1" && item.status
        );
        if (values[values.length - 1].name === "全選") {
            //選取數量等於項目選項===取消全選
            if (
                vUserPDFselect[UserIndex].mod_list.length ===
                selectValidPDF.length - 1
            ) {
                setUserPDFselect((prev) => {
                    let temp: UserPdfMode[] = JSON.parse(JSON.stringify(prev));
                    temp[UserIndex].mod_list = [];
                    return temp;
                });
                setTextFieldShowPDF([]);
                return;
            }
            setUserPDFselect((prev) => {
                let temp: UserPdfMode[] = JSON.parse(JSON.stringify(prev));
                temp[UserIndex].mod_list = allPDF;
                return temp;
            });
            setTextFieldShowPDF([
                {
                    name: "全選",
                    id: "-1",
                    status: true,
                },
            ]);
            return;
        }
        if (values.length === selectValidPDF.length - 1) {
            setUserPDFselect((prev) => {
                let temp: UserPdfMode[] = JSON.parse(JSON.stringify(prev));
                temp[UserIndex].mod_list = allPDF;
                return temp;
            });
            setTextFieldShowPDF([
                {
                    name: "全選",
                    id: "-1",
                    status: true,
                },
            ]);
            return;
        }
        setUserPDFselect((prev) => {
            let temp: UserPdfMode[] = JSON.parse(JSON.stringify(prev));
            temp[UserIndex].mod_list = values;
            return temp;
        });
        setTextFieldShowPDF(values);
    };

    //選取後不可再選
    const getOptionDisabled = (option: pdfMode) => {
        if (!option.status) return !option.status;
        if (option.id === "-1") return false;
        return vUserPDFselect[UserIndex].mod_list.find(
            (item) => item.id === option.id
        )
            ? true
            : false;
    };

    const returnChecked = (option: pdfMode) => {
        if (option.id === "-1") {
            //全選
            return (
                vUserPDFselect[UserIndex].mod_list.length ===
                selectValidPDF.length - 1
            );
        }
        return (
            vUserPDFselect[UserIndex].mod_list.find(
                (item) => item.id === option.id
            ) !== undefined
        );
    };

    useEffect(() => {
        let findUser: UserPdfMode[] = vUserPDFselect.filter(
            (item) => item.uid === UID
        );
        setUserIndex(vUserPDFselect.findIndex((item) => item.uid === UID));
        if (findUser.length === 0) return;
        //selectValid can't only has "全選"
        if (
            findUser[0].mod_list.length === selectValidPDF.length - 1 &&
            selectValidPDF.length !== 1
        ) {
            setTextFieldShowPDF([
                {
                    name: "全選",
                    id: "-1",
                    status: true,
                },
            ]);
        } else {
            setTextFieldShowPDF(findUser[0].mod_list);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [vUserPDFselect]);

    return (
        <div>
            <FormControl sx={{ m: 0, width: 300 }}>
                <Autocomplete
                    multiple
                    //設定input value on textField
                    value={textFieldShowPDF}
                    //所有選項
                    options={allPDFFile}
                    //下拉選單name
                    getOptionLabel={(option) => option.name}
                    //選取後設定
                    getOptionDisabled={(option) => getOptionDisabled(option)}
                    //fix warning about not found option
                    isOptionEqualToValue={(option, value) =>
                        option.id === value.id
                    }
                    //選取function
                    onChange={onTagsChange}
                    disableCloseOnSelect
                    //渲染下拉選單樣式
                    renderOption={(props, option) => (
                        <li {...props} style={{ display: "flex" }}>
                            <div style={{ marginRight: 8 }}>
                                <Checkbox
                                    icon={icon}
                                    checkedIcon={checkedIcon}
                                    checked={returnChecked(option)}
                                />
                            </div>

                            <div>
                                <div>
                                    {option.status ? "" : "分析中-"}
                                    {option.name}
                                </div>

                                {option.timestamp && (
                                    <div
                                        style={{ color: "grey", fontSize: 14 }}
                                    >
                                        {option.timestamp}
                                    </div>
                                )}
                            </div>
                        </li>
                    )}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            variant="standard"
                            label="PDF模組"
                            placeholder=""
                            margin="none"
                            fullWidth
                        />
                    )}
                />
            </FormControl>
        </div>
    );
}
