import React from "react";

interface IProps {
    subject: string;
    children: JSX.Element;
}

function Head(props: IProps) {
    return (
        <nav className="bacground_blue d-flex justify-content-between nav_icon">
            <img src="/img/logo_color.svg" alt="" />
            <div>{props.children}</div>
        </nav>
    );
}

export default Head;
